import { useEffect, useReducer, useState } from 'react'

const LOAD = 'LOAD'
const INSERT = 'INSERT'
const UPDATE = 'UPDATE'
const REMOVE = 'REMOVE'
const REMOVE_ID = 'REMOVE_ID'

export const useItemState = items => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case LOAD:
        return [...action.payload]
      case INSERT:
        state.push(action.payload)
        return [...state]
      case UPDATE:
        const insertIndex = state.findIndex(i => i.id === action.payload.id)
        state[insertIndex] = action.payload
        return [...state]
      case REMOVE:
        return state.filter(i => i.id !== action.payload.id)
      case REMOVE_ID:
        return state.filter(i => i.id !== action.payload)
      default:
        return state
    }
  }, [])

  useEffect(
    () => {
      load(items)
    },
    [items]
  )

  const load = items => dispatch({ type: LOAD, payload: items })
  const insert = item => dispatch({ type: INSERT, payload: item })
  const update = item => dispatch({ type: UPDATE, payload: item })
  const remove = item => dispatch({ type: REMOVE, payload: item })
  const removeID = id => dispatch({ type: REMOVE_ID, payload: id })

  const [listeners, updateListeners] = useState([])

  useEffect(
    () => {
      listeners.forEach(listener => listener(state))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const subscribe = listener => {
    updateListeners([...listeners, listener])
  }

  const unsubscribe = listener => {
    let hold = listeners.filter(i => i !== listener)
    updateListeners([...hold])
  }

  return { state, load, insert, update, remove, removeID, subscribe, unsubscribe }
}
