import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAssetFileGet } from '../../../api/state'
import { CacheContext } from '../../../context/cache.context'
import { useGetByDataType } from '../hooks/hook-asset'

export const ThumbnailImage = ({ nodeID, assets }) => {
  const [assetFileGetActions] = useAssetFileGet()
  const [image, updateImage] = useState(undefined)

  const cacheContext = useContext(CacheContext)

  let nodeAssets = useMemo(() => assets.filter(i => i.nodeID === nodeID), [nodeID, assets])

  const thumbnail = useGetByDataType('thumbnail', nodeAssets)

  useEffect(
    () => {
      thumbnail && loadThumbnail(thumbnail.key)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thumbnail]
  )

  const loadThumbnail = async key => {
    let image = cacheContext.get(thumbnail.key)

    if (image) {
      updateImage(image)
    }

    try {
      let blob = await assetFileGetActions.blob(key)
      if (blob.size) {
        let image = await cacheContext.getBase64(blob)
        updateImage(image)
        cacheContext.set(key, image)
      }
    } catch (error) {
      console.log(`failed to get assets: ${key}`)
    }
  }

  return (
    <React.Fragment>
      {image
        ? <img className="course-image" src={image} alt="img" />
        : <img className="course-image" src="/content_placeholder.png" alt="img" />}
    </React.Fragment>
  )
}
