import React, { useMemo } from 'react'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormTemplate } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'

import { useDataContext } from '../../data-context'
import { IconDelete } from '../../../../../../shared/icon/icon'

export const NodeTemplateDelete = ({ grandParentURL, nodeID }) => {
  const history = useHistory()

  const apiFormTemplate = useApiFormTemplate()

  const handleMatch = async () => await apiFormTemplate.get(nodeID)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])

  const dataContext = useDataContext()

  const handleSubmit = async () => {
    const node = await apiFormTemplate.delete(nodeID)
    dataContext.nodeRemove(node)
    history.replace(grandParentURL)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete template" onMatch={handleMatch}>
        <Card title={title}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete template" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
