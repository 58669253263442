import React, { useMemo } from 'react'
import { FormCheckbox } from '../../../../../../shared/form/form-checkbox'
import { Card } from '../../../../../../shared/card/card'

export const IdentifierDetails = ({ identifier }) => {
  return (
    <React.Fragment>
      {identifier &&
        <Card>
          <table className="table table-borderless">
            <tbody>
              <tr className="d-flex">
                <td className="col-4 col-sm-2">Details:</td>
                <td className="col-8 col-sm-10">
                  {identifier.description}
                </td>
              </tr>
              <tr className="d-flex">
                <td className="col-4 col-sm-2">Scope:</td>
                <td className="col-8 col-sm-10">
                  {identifier.typeDisplay}
                </td>
              </tr>
            </tbody>
          </table>
        </Card>}
    </React.Fragment>
  )
}

export const RoleAccessDetails = ({ identifier }) => {
  return (
    <React.Fragment>
      <Card>
        <FormCheckbox label="Create" name="accessCreate" disabled={!identifier || !identifier.scopeAllow.create} />
        <FormCheckbox label="Read" name="accessRead" disabled={!identifier || !identifier.scopeAllow.read} />
        <FormCheckbox label="Update" name="accessUpdate" disabled={!identifier || !identifier.scopeAllow.update} />
        <FormCheckbox label="Delete" name="accessDelete" disabled={!identifier || !identifier.scopeAllow.delete} />
        <FormCheckbox label="Purge" name="accessPurge" disabled={!identifier || !identifier.scopeAllow.purge} />
      </Card>
    </React.Fragment>
  )
}

export const RoleAccessOption = ({ identifier }) => {
  let show = useMemo(
    () => {
      return identifier && identifier.scopeOption
    },
    [identifier]
  )

  return (
    <React.Fragment>
      <Card title="options">
        <FormCheckbox
          label="See all organization content"
          name="scopeOption.orgAllContent"
          displayHide={!show || !identifier.scopeOption.orgAllContent}
        />
        <FormCheckbox
          label="Access to public content"
          name="scopeOption.accessPublic"
          displayHide={!show || !identifier.scopeOption.accessPublic}
        />
      </Card>
    </React.Fragment>
  )
}
