import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmitBack } from '../../../../shared/form/form'
import { FormHiddenNumber } from '../../../../shared/form/form-hidden-number'
import { GridContext } from '../grid/grid-context'
import { NodePathButton } from '../../shared/node-path-button'
import { pathCourse } from '../../shared/node-path'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useLoad } from '../../../../shared/hooks/load'
import { FormSelectCategory } from '../../../../shared/form/form-control/form-select-category'
import { useApiListNodeQuery } from '../../../../api-new/state/content'
import { useApiFormNodeCourse } from '../../../../api-new/state/content'
import { Card } from '../../../../shared/card/card'
import { IconMove } from '../../../../shared/icon/icon'

export const CourseMove = () => {
  const { nodeID } = useParamsInt()

  const authContext = useContext(AuthContext)
  const gridContext = useContext(GridContext)

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormNodeCourseMove = useApiFormNodeCourse(`/move`)
  const apiListNodeQuery = useApiListNodeQuery()

  const [copiedPath, updateCopiedPath] = useState(undefined)

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormNodeCourse.form.name, [apiFormNodeCourse.form])

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  const handleSubmit = async data => {
    let movedNode = await apiFormNodeCourseMove.put(data.nodeID, data)
    await gridContext.actions.loadContents()

    let nodes = await apiListNodeQuery.get(`datatype:category,course`)
    let copiedPath = pathCourse(nodes, movedNode.id)
    updateCopiedPath(copiedPath)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <Card title={title}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

            {categoryOrganizationID &&
              <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />}

            <FormSubmitBack text="move course" />
            <NodePathButton path={copiedPath} text="go to course" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
