import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmit } from '../../../../../../shared/form/form'
import { useAppForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { IconEdit, IconList } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AppEdit = () => {
  const { appID } = useParamsInt()

  const [appFormActions, appFormState] = useAppForm()

  const handlePath = async () => await appFormActions.get(appID)

  const handleSubmit = async data => {
    await appFormActions.put(data)
    await appFormActions.get(appID)
  }

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconList} text="stores" to={`/admin/app/edit/${appID}/store`} show={roleApp.accessCreate} pin />
      <MenuDelete to={`/admin/app/edit/${appID}/delete`} show={roleApp.accessDelete} />
      <MenuActivityLog to="/admin/app/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={appFormState.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={appFormState.form.name} HeaderMenu={HeaderMenu}>
          <Form state={appFormState.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" />
            <FormSubmit text="save app" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
