import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { Form, FormEmail, FormText, FormSubmit, FormPasswordValidate } from '../../shared/form/form'
import { useApiFormRegisterUser } from '../../api-new/state/auth'
import { Card } from '../../shared/card/card'

export const ViewRegister = () => {
  const history = useHistory()

  const apiFormRegisterUser = useApiFormRegisterUser()

  const handleMatch = () => apiFormRegisterUser.init()

  const handleSubmit = async data => {
    await apiFormRegisterUser.post(data)
    history.push('/auth/email')
  }

  return (
    <React.Fragment>
      <BreadcrumbView text="register" onMatch={handleMatch}>
        <Card title="register">
          <Form onSubmit={handleSubmit}>
            <FormEmail label="email" name="email" required />
            <FormPasswordValidate label="password" name="password" required />
            <FormText label="first name" name="firstName" required />
            <FormText label="last name" name="lastName" required />
            <FormText label="company name" name="companyName" />
            <FormSubmit text="register" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
