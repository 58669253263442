import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconList } from '../../../../shared/icon/icon'
import { Menu, MenuRestore, MenuPurge } from '../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { ROLE_ORG } from '../../../../role/role.const'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../shared/table-builder/table-builder'
import { useApiListTemplate } from '../../../../api-new/state/content'

export const TemplateRecycle = () => {
  const { url } = useRouteMatch()

  const apiListTemplate = useApiListTemplate()

  const { accessDelete, accessPurge } = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const handleRestore = async id => {
    await apiListTemplate.put(`${id}/restore`)
    await apiListTemplate.get('deleted-template')
  }

  const handleMatch = () => apiListTemplate.get('deleted-template')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListTemplate}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
