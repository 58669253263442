import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, ColumnYesNo, ColumnLink, ColumnMenu, ColumnFormat } from '../../../../../shared/table-builder/table-builder'
import { useApiListAccessAudit } from '../../../../../api-new/state/content'
import { useApiListAccess } from '../../../../../api-new/state/content'
import { IconCheck, IconList, IconTimes } from '../../../../../shared/icon/icon'

export const RoleAccessListRole = () => {
  const { role } = useParams()

  const apiListAccess = useApiListAccess()
  const apiListAccessAudit = useApiListAccessAudit()

  const handeReady = async () => {
    await apiListAccess.get()
    await apiListAccessAudit.get()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role access" to={'/root/role-access/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={role}>
        <CardFullHeightScrollY title={`role ${role}`} HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListAccess} onReady={handeReady}>
            <ColumnFormat
              header="Audit"
              selector={row =>
                apiListAccessAudit.items.some(i => i.roleAccessID === row.id) ? <IconTimes className="text-danger" /> : <IconCheck />}
            />

            <ColumnLink field="identifier" name="scope" to={row => `/root/role-access/list/identifier/${row.identifier}`} sortable filter />

            <ColumnYesNo field="accessCreate" name="create" />
            <ColumnYesNo field="accessRead" name="read" />
            <ColumnYesNo field="accessUpdate" name="update" />
            <ColumnYesNo field="accessDelete" name="delete" />
            <ColumnYesNo field="accessPurge" name="purge" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role-access/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role-access/list/delete/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
