import React, { useContext, useEffect } from 'react'
import { useNodeList, useNodeForm } from './state'
import TableFilter from '../../shared/legacy/table-filter'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { NodeAdd } from './node-add'
import { NodeEdit } from './node-edit'
import { NodeListUsers } from './node-edit/list-user'
import { NodeListGroup } from './node-edit/list-group'
import { AuthContext } from '../../context/auth.context'
import { IconButtonLinkEdit } from '../../shared/icon/icon-button-link'
import { IconButtonLinkEnter } from '../../shared/icon/icon-button-link'
import { IconButtonLinkUsers } from '../../shared/icon/icon-button-link'
import { IconButtonLinkGroups } from '../../shared/icon/icon-button-link'
import { IconButtonClickDelete } from '../../shared/icon/icon-button-click'
import { ROLE_ORG, ROLE_SYS } from '../../role/role.const'
import { RoleAccessDisplayOrg, RoleAccessDisplaySys } from '../../role/components/role-access-display'

export const RootNode = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const [nodeListActions, nodeListState] = useNodeList()

  useEffect(() => {
    nodeListActions.get('roots')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleAdd() {
    history.push(`${url}/add`)
  }

  const handleUpdate = async () => nodeListActions.get()

  return (
    <React.Fragment>
      <NodeContent title="root" parentUrl={url} state={nodeListState} actions={nodeListActions} onAdd={handleAdd} onUpdate={handleUpdate} />

      <Route path={`${url}/list/:nodeID`}>
        <NodeLeaf />
      </Route>
    </React.Fragment>
  )
}

export const NodeLeaf = () => {
  const history = useHistory()
  const { nodeID } = useParams()
  const { url } = useRouteMatch()

  const [nodeFormActions, nodeFormState] = useNodeForm()
  const [nodeListActions, nodeListState] = useNodeList()

  useEffect(
    () => {
      nodeFormActions.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useEffect(
    () => {
      nodeListActions.get(`${nodeID}/children`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  function handleAdd() {
    history.push(`${url}/add/${nodeID}`)
  }

  const handleUpdate = () => nodeListActions.get(`${nodeID}/children`)

  return (
    <React.Fragment>
      <NodeContent
        title={nodeFormState.form.name}
        parentUrl={url}
        state={nodeListState}
        actions={nodeListActions}
        onAdd={handleAdd}
        onUpdate={handleUpdate}
      />

      <Route path={`${url}/list/:nodeID`}>
        <NodeLeaf />
      </Route>
    </React.Fragment>
  )
}

const NodeContent = ({ title, parentUrl, state, actions, onAdd, onUpdate }) => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const handleDelete = async value => {
    await actions.delete(value)
  }
  const handleRecycle = () => history.push('/node/root/recycle')

  let links = item =>
    <React.Fragment>
      <RoleAccessDisplaySys type={ROLE_SYS.NODE} accessUpdate>
        <IconButtonLinkEdit primary to={`${url}/edit/${item.id}`} small />
      </RoleAccessDisplaySys>

      <IconButtonLinkEnter to={`${url}/list/${item.id}`} small />

      <RoleAccessDisplayOrg type={ROLE_ORG.NODE_USER} accessRead>
        <IconButtonLinkUsers to={`${url}/edit/${item.id}/user`} small />
      </RoleAccessDisplayOrg>

      <RoleAccessDisplayOrg type={ROLE_ORG.NODE_GROUP} accessRead>
        <IconButtonLinkGroups to={`${url}/edit/${item.id}/group`} small />
      </RoleAccessDisplayOrg>

      <RoleAccessDisplaySys type={ROLE_SYS.NODE} accessDelete>
        <IconButtonClickDelete small onClick={() => handleDelete(item.id)} />
      </RoleAccessDisplaySys>
    </React.Fragment>

  let columns = [
    { name: 'Name', selector: 'name', grow: 1, sortable: true },
    { name: '', right: true, sortable: false, cell: row => links(row) }
  ]

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={title}>
        <TableFilter
          title={title}
          columns={columns}
          onAdd={onAdd}
          onRecycle={authContext.state.isSysAdmin ? handleRecycle : null}
          data={state.items}
          loading={state.submitting}
        />
      </BreadcrumbView>

      <Route path={`${url}/add/:nodeID?`}>
        <NodeAdd parentUrl={parentUrl} />
      </Route>

      <Route path={`${url}/edit/:nodeID`}>
        <NodeEdit parentUrl={parentUrl} onUpdate={onUpdate} />
      </Route>

      <Route path={`${url}/edit/:nodeID/user`}>
        <NodeListUsers />
      </Route>

      <Route path={`${url}/edit/:nodeID/group`}>
        <NodeListGroup />
      </Route>
    </React.Fragment>
  )
}
