import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmitBack, FormHiddenNumber } from '../../../../../../shared/form/form'
import { useAppStoreForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AppStoreAdd = () => {
  const history = useHistory()

  const { appID } = useParamsInt()

  const [appStoreFormActions] = useAppStoreForm()

  const { accessUpdate } = useRoleAccessSystem(ROLE_SYS.APP)

  const handleSubmit = async data => {
    let appStore = await appStoreFormActions.post(data)
    if (accessUpdate) history.push(`/admin/app/edit/${appID}/store/edit/${appStore.id}`)
    else history.push(`/admin/app/edit/${appID}/store`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="add">
        <CardFullHeightScrollY title="add app store">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="appID" value={appID} />
            <FormText label="name" name="name" required autoFocus />
            <FormSubmitBack text="add app store" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
