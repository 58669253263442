import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useNodeUserList } from '../../../../../../api/state'
import { TableHierarchyNode } from '../../../../../../shared/legacy/table/table-hierarchy-node'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { IconContent } from '../../../../../../shared/icon/icon'

export const UserContentList = () => {
  const { userID } = useParamsInt()

  const [nodeUserListActions, nodeUserListState] = useNodeUserList()

  const handleMatch = async () => await nodeUserListActions.get(`${userID}/user-list`)

  const handleAdd = async nodeID => {
    await nodeUserListActions.post({ nodeID, userID })
    await nodeUserListActions.get(`${userID}/user-list`)
  }

  const handleDelete = async nodeID => {
    await nodeUserListActions.delete(`${nodeID}/${userID}`)
    await nodeUserListActions.get(`${userID}/user-list`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconContent} text="contents" onMatch={handleMatch}>
        <TableHierarchyNode state={nodeUserListState} roleScope={ROLE_ORG.NODE_USER} onAdd={handleAdd} onDelete={handleDelete} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
