import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconMove } from '../../../../../../shared/icon/icon'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmitBack } from '../../../../../../shared/form/form'
import { FormSelectCategory } from '../../../../../../shared/form/form-control/form-select-category'
import { useDataContext } from '../../data-context'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { buildCoursePath } from '../../common/shared/node-path'

export const NodeCourseMove = ({ node }) => {
  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const history = useHistory()

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormNodeCourseMove = useApiFormNodeCourse(`/move`)

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormNodeCourse.form.name, [apiFormNodeCourse.form])

  const handleMatch = async () => await apiFormNodeCourse.get(node.id)

  const handleSubmit = async data => {
    let node = await apiFormNodeCourseMove.put(data.nodeID, data)

    const moveToPath = nodes => {
      dataContext.unsubscribe(moveToPath)
      let path = buildCoursePath(nodes, node.id)
      history.replace(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeUpdate(node)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <Card title={title}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={node.id} required shouldDirty />
            {categoryOrganizationID &&
              <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />}
            <FormSubmitBack text="move course" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
