import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAuthLogin, IconList, IconOrganization } from '../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { LoginDetails } from './login-edit/login-details'
import { LoginPassword } from './login-edit/login-password'
import { TileBody, TileColumn11112 } from '../../../../../shared/tile/tile-column-21111'
import { useApiFormLogin } from '../../../../../api-new/state/content'
import { ScrollY } from '../../../../../shared/component/scroll'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const LoginEdit = () => {
  const history = useHistory()

  const { loginID } = useParamsInt()

  const { url } = useRouteMatch()

  const apiFormLogin = useApiFormLogin()

  const handlePath = async () => await apiFormLogin.get(loginID)

  const handleSubmitEdit = async data => await apiFormLogin.put(data.id, data)

  const handleSubmitPassword = async data => {
    await apiFormLogin.put(`${data.id}/password`, { loginID: data.id, password: data.password })
    await apiFormLogin.get(loginID)
  }

  const handleDelete = async () => history.push(`/admin/login/list/edit/${loginID}/delete`)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconOrganization} text="organizations" to={`${url}/organizations`} show />
      <MenuItem Icon={IconAuthLogin} text="login auths" to={`${url}/auths`} show />
      <MenuDelete onClick={handleDelete} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={apiFormLogin.form.email} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormLogin.form.email} HeaderMenu={HeaderMenu}>
          <ScrollY>
            <TileBody>
              <TileColumn11112>
                <LoginDetails state={apiFormLogin} onSubmit={handleSubmitEdit} />
              </TileColumn11112>
              <TileColumn11112>
                <LoginPassword state={apiFormLogin} onSubmit={handleSubmitPassword} />
              </TileColumn11112>
            </TileBody>
          </ScrollY>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
