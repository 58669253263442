import React, { useContext, useMemo, useRef, useState } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { DisplaySysAdmin, useDisplaySysAdmin } from '../../../../../../role/role.component'
import { FormSelectOrgaization } from '../../../../../../shared/form/form-control/form-select-organization'
import { AssetCopyProgress, useAssetCopy } from '../../../../../../shared/component/asset-copy'
import { IconCopy, IconFile } from '../../../../../../shared/icon/icon'
import { buildNodeLocation, buildTemplatePath } from '../../common/shared/node-path'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useMetadataType } from '../../common/hooks/hooks'
import { useApiFormTemplate } from '../../../../../../api-new/state/content'
import { AuthContext } from '../../../../../../context/auth.context'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuItem } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization, useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_ORG, ROLE_SYS } from '../../../../../../role/role.const'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useDataContext } from '../../data-context'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { Modal } from '../../../../../../shared/component/modal'

export const NodeTemplateCopy = ({ parentURL, nodeID }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const refModal = useRef()

  const apiFormTemplate = useApiFormTemplate()
  const apiFormTemplateCopy = useApiFormTemplate('/copy')
  const apiFormTemplateCopyOrg = useApiFormTemplate('/copy-org')

  const [organizationID, updateOrganizationID] = useState(undefined)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])
  const isCaffeine = useMetadataType(apiFormTemplate.form, 'CourseAssemblyName', 'caffeine')

  useLoad(() => authContext.state.jwtClaims.organizationID && updateOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const handleMatch = async () => await apiFormTemplate.get(nodeID)

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState()

  const onCopyComplete = nodeID => {
    if (nodeID) {
      updateCopiedNodeID(nodeID)
      const destLocation = buildNodeLocation(dataContext.nodes, nodeID)
      updateDestLocation(destLocation)
    }

    refModal.current.show()
  }

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let node = authContext.state.isSysAdmin ? await apiFormTemplateCopyOrg.post(data) : await apiFormTemplateCopy.post(data)

    if (authContext.state.jwtClaims.organizationID === node.organizationID) {
      await init(node.id)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })

      dataContext.nodeInsert(node)
    } else {
      await init(null)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })
    }
  }

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNode = useRoleAccessSystem(ROLE_SYS.NODE)

  const displaySysAdmin = useDisplaySysAdmin()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="new course" to={`${parentURL}/generate`} disabled={!isCaffeine} show={roleContent.accessCreate} pin={false} />
      <MenuEdit to={`${parentURL}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuEdit text="sys edit" to={`${parentURL}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={roleNode.accessRead} />
    </Menu>

  const handleConfirm = () => {
    refModal.current.hide()

    if (copiedNodeID) {
      let path = buildTemplatePath(dataContext.nodes, copiedNodeID)
      history.push(path)
    } else {
      history.push(`${parentURL}/edit`)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconCopy} text="copy template" onMatch={handleMatch}>
        <Modal ref={refModal} backdropStatic title="copy complete" confirmText="ok" onConfirm={handleConfirm}>
          <p>
            {apiFormTemplate.form.name} has successfully been copied {destLocation && `to ${destLocation}`}
          </p>
        </Modal>

        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="templateID" value={nodeID} required />

                  <DisplaySysAdmin>
                    <FormSelectOrgaization lable="organization" name="organizationID" organizationID={organizationID} required />
                  </DisplaySysAdmin>

                  <FormText label="name" name="name" defaultValue={apiFormTemplate.form.name} shouldDirty required />

                  <FormSubmitBack text="copy template" />
                </Form>

                <AssetCopyProgress content={content} />
              </React.Fragment>
            : <div>Action not allowed for template</div>}
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
