import { useEffect, useMemo, useReducer, useState } from 'react'

export const useApiWebsocket = (server, endpoint, onOpen, onMessage, onClose, onError) => {
  const funcs = useMemo(
    () => {
      let ws

      const init = async messageValue => {
        return new Promise((resolve, reject) => {
          ws = new WebSocket(`${server}/${endpoint}`)

          ws.onopen = () => {
            onOpen && onOpen()
            resolve()
          }

          ws.onmessage = async msg => {
            let event = JSON.parse(msg.data)
            onMessage && onMessage(event, messageValue)
          }

          ws.onclose = () => {
            onClose && onClose()
          }

          ws.onerror = error => {
            onError && onError(error)
            reject(error)
          }
        })
      }

      const send = data => {
        if (ws.readyState === ws.OPEN) {
          let json = JSON.stringify(data)
          ws.send(json)
        }
      }

      const close = () => {
        if (ws) {
          ws.close()
        }
      }

      return [init, send, close]
    },
    [server, endpoint, onOpen, onMessage, onClose, onError]
  )

  return funcs
}

///////////////////////////////////

const initialState = { submitting: false, complete: false, error: false }

///////////////////////////////////

export const useApiWebsocket2 = (server, endpoint) => {
  const [state, dispatch] = useReducer((_, payload) => {
    return payload
  }, initialState)

  const action = (onOpen, onMessage, onClose, onError) => {
    let ws = null

    const init = async initMessage => {
      return new Promise((resolve, reject) => {
        ws = new WebSocket(`${server}/${endpoint}`)

        ws.onopen = () => {
          dispatch({ submitting: true, complete: false, error: false })
          onOpen && onOpen()
          resolve()
        }

        ws.onmessage = msg => {
          let event = JSON.parse(msg.data)
          onMessage && onMessage(event, initMessage)
        }

        ws.onclose = () => {
          dispatch({ submitting: false, complete: true, error: false })
          onClose && onClose()
        }

        ws.onerror = error => {
          dispatch({ submitting: false, complete: false, error: true })
          onError && onError(error)
          reject(error)
        }
      })
    }

    const send = data => {
      return new Promise((resolve, reject) => {
        if (ws.readyState === ws.OPEN) {
          let json = JSON.stringify(data)
          ws.send(json)
          resolve()
        } else {
          reject('ws not open')
        }
      })
    }

    const close = () => {
      if (ws) {
        ws.close()
      }
    }

    return [init, send, close]
  }

  const cleanup = () => {
    dispatch(initialState)
  }

  return [action, state, cleanup]
}

////////////////////////////////////////////////////////

// export const WebSocket3 = url => {
//   const [message, dispatch] = useReducer((state, payload) => {
//     try {
//       // debugger
//       const value = JSON.parse(payload)
//       return value
//       // const key = Date.now()
//       // const newState = {...state}
//       // newState[key] = value
//       // return newState
//     } catch {
//       debugger
//       return {}
//     }
//   }, {})

//   // useEffect(() => {
//   //   let hold = message
//   //   debugger
//   // }, [message])

//   // const [message, dispatch] = useState({})

//   const init = () => {
//     // debugger
//     const ws = new WebSocket(url)

//     ws.onopen = () => {
//       debugger
//     }

//     ws.onmessage = message => {
//       // debugger
//       dispatch(message.data)
//     }

//     ws.onclose = () => {
//       debugger
//     }

//     ws.onerror = error => {
//       let hold = error
//       debugger
//     }
//   }

//   return [init, message]
// }
