import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormText } from '../../../../shared/form/form'
import { FormHiddenNumber } from '../../../../shared/form/form-hidden-number'
import { GridContext } from '../grid/grid-context'
import { NodePathButton } from '../../shared/node-path-button'
import { pathTemplate } from '../../shared/node-path'
import { useMetadataType } from '../../hooks/hooks-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { DisplaySysAdmin } from '../../../../role/role.component'
import { useApiFormTemplate } from '../../../../api-new/state/content'
import { useApiListNodeQuery } from '../../../../api-new/state/content'
import { useApiFormNodeCourse } from '../../../../api-new/state/content'
import { FormSelectOrgaization } from '../../../../shared/form/form-control/form-select-organization'
import { useLoad } from '../../../../shared/hooks/load'
import { useAssetCopy, AssetCopyProgress } from '../../../../shared/component/asset-copy'
import { Card } from '../../../../shared/card/card'
import { IconTemplate } from '../../../../shared/icon/icon'

export const CourseTemplate = () => {
  const { nodeID } = useParamsInt()

  const authContext = useContext(AuthContext)
  const gridContext = useContext(GridContext)

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormTemplateCourse = useApiFormTemplate('/course')
  const apiFormTemplateCourseOrg = useApiFormTemplate('/course-org')
  const apiListNodeQuery = useApiListNodeQuery()

  const [organizationID, updateOrganizationID] = useState(undefined)
  const [copiedPath, updateCopiedPath] = useState(undefined)

  const title = useMemo(() => apiFormNodeCourse.form.name, [apiFormNodeCourse.form])
  const isCaffeine = useMetadataType(apiFormNodeCourse.form, 'CourseAssemblyName', 'caffeine')

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  useLoad(
    () => {
      authContext.state.jwtClaims.organizationID && updateOrganizationID(authContext.state.jwtClaims.organizationID)
    },
    [authContext.state.jwtClaims.organizationID]
  )

  const onCopyComplete = copiedPath => updateCopiedPath(copiedPath)

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let newTemplate = authContext.state.isSysAdmin ? await apiFormTemplateCourseOrg.post(data) : await apiFormTemplateCourse.post(data)

    await gridContext.actions.loadContents()
    await gridContext.actions.loadThumbnails()

    if (authContext.state.jwtClaims.organizationID === newTemplate.organizationID) {
      let nodes = await apiListNodeQuery.get(`datatype:category,course`)
      let copiedPath = pathTemplate(nodes, newTemplate.id)
      await init(copiedPath)
    } else {
      await init(null)
    }

    send({
      srcNodeID: nodeID,
      destNodeID: newTemplate.id
    })
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text="create template" onMatch={handleMatch}>
        <Card title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

                  <DisplaySysAdmin>
                    <FormSelectOrgaization lable="organization" name="organizationID" organizationID={organizationID} required />
                  </DisplaySysAdmin>

                  <FormText label="name" name="name" defaultValue={apiFormNodeCourse.form.name} required shouldDirty />

                  <FormSubmitBack text="create template" />
                </Form>
                <AssetCopyProgress content={content} />
                <NodePathButton path={copiedPath} text="go to template" />
              </React.Fragment>
            : <div>Action not allowed for course</div>}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
