import React, { useMemo, useState } from 'react'
import { Table } from './table'
import { TableInputText } from './table-input-text'
import { Card } from '../../card/card'

export const LoadingSpinner = ({ children, loading }) => {
  const parentStyle = {
    display: 'block',
    position: 'relative',
    backgroundColor: 'lightgray',
    width: '100%',
    height: '100%',
    // border: 'solid black',
    pointerEvents: 'none',
    zIndex: '1000'
  }

  const spinnerStyle = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2000' }

  return (
    <React.Fragment>
      {loading
        ? <div style={parentStyle}>
            <div style={spinnerStyle}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden" />
              </div>
            </div>
            {children}
          </div>
        : <div>
            {children}
          </div>}
    </React.Fragment>
  )
}

export const TableFilter = ({ title, TitleComponent, HeaderItem, columns, data, loading, onRowClicked }) => {
  const [filter, updateFilter] = useState('')

  const handleChange = filter => updateFilter(filter)
  const handleClear = () => updateFilter('')

  const filteredData = useMemo(
    () => {
      if (!filter) return data
      const lowerFilter = filter.toLocaleLowerCase()
      return data.filter(item => Object.values(item).join().toLocaleLowerCase().includes(lowerFilter))
    },
    [filter, data]
  )

  return (
    <Card title={title} HeaderComponent={TitleComponent} HeaderItem={HeaderItem}>
      <LoadingSpinner loading={loading}>
        <TableInputText placeholder="filter" value={filter} onChange={handleChange} onClear={handleClear} />
        <Table columns={columns} data={filteredData} disabled={loading} onRowClicked={onRowClicked} />
      </LoadingSpinner>
    </Card>
  )
}
