const isDataType = (node, type) => {
  try {
    if (!node.metaData) {
      return false
    }

    let metadata = JSON.parse(node.metaData.toLowerCase())

    if (!metadata.datatype) {
      return false
    }

    return metadata.datatype === type
  } catch (e) {
    return false
  }
}

export const isDatatypeCategory = node => isDataType(node, 'category')
export const isDatatypeCourse = node => isDataType(node, 'course')
export const isDatatypeStreamingVideo = node => isDataType(node, 'streaming_video')
export const isDatatypeCourseTemplate = node => isDataType(node, 'course_template')

export const getDatatype = node => {
  if (!node.metaData) {
    return ''
  }

  try {
    return JSON.parse(node.metaData.toLowerCase()).datatype
  } catch (e) {
    return ''
  }
}
