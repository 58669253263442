import React from 'react'
import { Route } from 'react-router-dom'
import { OrganizationList } from './content/organization-list'
import { OrganizationAdd } from './content/organization-add'
import { OrganizationEdit } from './content/organization-edit'
import { OrganizationRecycle } from './content/organization-recycle'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { useApiFormOrganization } from '../../../../api-new/state/content'
import { LogEventSys } from '../../../../shared/page/log-event/log-event-sys'

export const LayoutOrganization = () => {
  return (
    <React.Fragment>
      <Route path="/admin/organization/list" component={OrganizationList} />
      <Route path="/admin/organization/list/add" component={OrganizationAdd} />
      <Route path="/admin/organization/list/edit/:organizationID" component={OrganizationEdit} />
      <Route path="/admin/organization/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormOrganization} titleParam="name" successPath="/admin/organization/list" />
      </Route>
      <Route path="/admin/organization/list/recycle" component={OrganizationRecycle} />
      <Route path="/admin/organization/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormOrganization} titleParam="name" successPath="/admin/organization/list/recycle" />
      </Route>

      <Route path="/admin/organization/list/activity-all">
        <LogEventSys subject="organization" />
      </Route>
      <Route path="/admin/organization/list/activity-id/:id">
        <LogEventSys subject="organization" />
      </Route>
    </React.Fragment>
  )
}
