import React from 'react'
import { CardHeader } from './components/card-header'

export const Card = ({ children, height, title, subtitle, HeaderMenu, HeaderComponent }) => {
  return (
    <div className="card my-2" style={{ height: height }}>
      <div className="card-body d-flex flex-column">
        <CardHeader title={title} subtitle={subtitle} HeaderComponent={HeaderComponent} HeaderMenu={HeaderMenu} />
        <div className="card-text h-100">
          {children}
        </div>
      </div>
    </div>
  )
}
