import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Form, FormText, FormSubmitCancel } from '../../../../../shared/form/form'
import { useOrganizationForm } from '../../../../../api/state'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { ROLE_SYS } from '../../../../../role/role.const'
import { Card } from '../../../../../shared/card/card'

export function OrganizationAdd() {
  const history = useHistory()

  const [organizationActions, organizationState] = useOrganizationForm()

  const { accessUpdate } = useRoleAccessSystem(ROLE_SYS.ORGANIZATION)

  const handleMatch = () => organizationActions.init()

  const handleSubmit = async data => {
    try {
      let organization = await organizationActions.post(data)
      if (accessUpdate) history.push(`/admin/organization/list/edit/${organization.id}`)
      else history.push(`/admin/organization/list`)
    } catch {}
  }

  const handleCancel = () => history.push('/admin/organization/list')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onMatch={handleMatch}>
        <Card title="add organization">
          <Form submitting={organizationState.submitting} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required autoFocus />
            <FormSubmitCancel text="add organization" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
