import React, { useMemo } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../form/form'
import { useParamsInt } from '../hooks/location'
import { Card } from '../card/card'
import { IconDelete } from '../icon/icon'

export const Purge = ({ useApiForm, titleParam, successPath, onSuccess }) => {
  const apiForm = useApiForm()

  const params = useParams()
  const history = useHistory()

  const { purgeID } = useParamsInt()

  const title = useMemo(() => `purge ${apiForm.form[titleParam] || ''}`, [apiForm.form, titleParam])

  const handleMatch = async () => await apiForm.get(`${purgeID}/deleted`)

  const handleSubmit = async () => {
    await apiForm.delete(`${purgeID}/purge`)

    if (onSuccess) {
      onSuccess(apiForm.form)
    } else {
      let path = generatePath(successPath, params)
      history.push(path)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="purge" onMatch={handleMatch}>
        <Card title={title}>
          <div className="py-2">This is a destructive action</div>
          <Form state={apiForm.form} onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="purge" subtext="Type purge to confirm" confirmValue="purge" name="test" required autoFocus />
            <FormSubmitBack text="purge" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
