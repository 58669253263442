import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormRole, useApiListRoleScope } from '../../../../../api-new/state/content'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

export const RoleEdit = () => {
  const { roleID } = useParamsInt()

  const apiFormRole = useApiFormRole()

  const apiListRoleScope = useApiListRoleScope()

  const handlePath = async () => {
    await apiFormRole.get(roleID)
    await apiListRoleScope.get('type')
  }

  const handleSubmit = async data => await apiFormRole.put(data.id, data)

  const title = useMemo(() => `${apiFormRole.form.role}`, [apiFormRole.form])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title="edit role">
          <Form state={apiFormRole.form} onSubmit={handleSubmit}>
            <FormText label="role" name="role" autoFocus />
            <FormSelectObj label="Scope" name="scopeType" state={apiListRoleScope} itemName="typeDisplay" itemValue="type" required />
            <FormTextArea label="description" name="description" />
            <FormCheckbox label="Auto Owner" name="isAutoOwner" />
            <FormCheckbox label="Auto User" name="isAutoUser" />
            <FormCheckbox label="Read Only" name="isReadOnly" />
            <FormSubmitBack text="update role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
