import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useRouteMatch } from 'react-router-dom'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { ROLE_ORG } from '../../../../role/role.const'
import { Menu, MenuAdd, MenuDelete } from '../../../../shared/menu/menu'
import { useApiListNodeGroup } from '../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../shared/table-builder/table-builder'
import { IconGroup } from '../../../../shared/icon/icon'

export const ContentListGroup = () => {
  const { nodeID } = useParamsInt()

  const { url } = useRouteMatch()

  const apiListNodeGroup = useApiListNodeGroup()

  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)

  const handlePath = async () => {
    await apiListNodeGroup.get(`${nodeID}/node`)
  }

  const handleDelete = async item => {
    await apiListNodeGroup.delete(`${nodeID}/${item.groupID}`)
    await apiListNodeGroup.get(`${nodeID}/node`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`${url}/add`} show={roleNodeGroup.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconGroup} text="groups" onPath={handlePath}>
        <CardFullHeightScrollY title="groups" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListNodeGroup}>
            <Column field="groupName" header="Group" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleNodeGroup.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
