import React, { useMemo } from 'react'
import { useHistory } from '../../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useNodeCategoryForm } from '../../../../../../../api/state'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../../../shared/icon/icon'
import { Card } from '../../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../../shared/form/form'
import { useDataContext } from '../../../data-context'

export const NodeCategoryAdd = ({ parentURL, node }) => {
  const history = useHistory()

  const [nodeCategoryActions] = useNodeCategoryForm()

  const dataContext = useDataContext()

  const parentID = useMemo(() => (node ? node.id : null), [node])

  const handleSubmit = async data => {
    const node = await nodeCategoryActions.post(data)
    dataContext.nodeInsert(node)
    history.replace(`${parentURL}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="category add">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="parentID" value={parentID} />
            <FormText label="name" name="name" autoFocus />
            <FormSubmitBack text="create category" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
