import React, { useEffect } from 'react'
import { useAssetList } from '../state'
import { FormatSize, FormatDate } from '../../../shared/legacy/format'
import { useRouteMatch } from 'react-router'
import { ButtonPrimary, ButtonPrimaryLink } from '../../../shared/component/button'
import { Card } from '../../../shared/card/card'
import { IconAdd } from '../../../shared/icon/icon'
import { IconEdit } from '../../../shared/icon/icon'
import { IconButtonClickDelete } from '../../../shared/icon/icon-button-click'

export const AssetList = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const [assetListActions, assetListState] = useAssetList()

  useEffect(
    () => {
      assetListActions.get(`${nodeID}/node`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const handleDelete = async id => {
    await assetListActions.delete(id)
    await assetListActions.get(`${nodeID}/node`)
  }

  const HeaderMenu = () => {
    return (
      <React.Fragment>
        <ButtonPrimaryLink Icon={IconAdd} text="add" to={`${url}/asset/add/${nodeID}`} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Card title="Assets" HeaderMenu={HeaderMenu}>
        <table className="table table-borderless table-responsive">
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Created</th>
              <th>Updated</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {assetListState.items.map((item, i) =>
              <tr key={i}>
                <td>
                  {item.name}
                </td>
                <td>
                  <FormatSize value={item.length} />
                </td>
                <td>
                  <FormatDate date={item.createdAt} />
                </td>
                <td>
                  <FormatDate date={item.updatedAt} />
                </td>
                <td className="text-nowrap">
                  <ButtonPrimary Icon={IconEdit} text="edit" small to={`${url}/asset/edit/${item.id}`} />
                  <IconButtonClickDelete small onClick={handleDelete} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
    </React.Fragment>
  )
}
