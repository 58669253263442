import React, { useMemo, useState } from 'react'
import { LoadingSpinner } from './table-filter'
import { TableHierarchy } from './table-hierarchy'
import { TableInputText } from './table-input-text'
import { Menu, MenuAdd, MenuDelete } from '../../menu/menu'
import { useRoleAccessOrganization } from '../../../role/role.hook'
import { ButtonPrimaryToggle } from '../../component/button'
import { IconCheck, IconCategory, IconCourse, IconStreamingVideo } from '../../icon/icon'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'

export const TableHierarchyNode = ({ state, roleScope, onAdd, onDelete }) => {
  const [search, updateSearch] = useState('')
  const [selected, updateSelected] = useState(false)

  const role = useRoleAccessOrganization(roleScope)

  const handleChange = filter => updateSearch(filter.toLowerCase())
  const handleClear = () => updateSearch('')

  const handleAdd = nodeID => onAdd && onAdd(nodeID)
  const handleDelete = nodeID => onDelete && onDelete(nodeID)

  const columns = [
    { name: '', selector: 'isVisible', style: { width: 50 }, row: item => item.isVisible && <IconCheck /> },
    {
      name: '',
      selector: '',
      style: { width: 0 },
      row: item =>
        <span>
          {item.dataType === 'category' && <IconCategory />}
          {item.dataType === 'course' && <IconCourse />}
          {item.dataType === 'streaming_video' && <IconStreamingVideo />}
        </span>
    },
    { name: 'name', selector: 'nodeName', level: 'level' },
    { name: 'path', selector: 'path', hide: !search && !selected },
    {
      name: '',
      selector: '',
      row: item =>
        <span className="float-right">
          {item.hasLink
            ? <Menu small>
                <MenuDelete onClick={() => handleDelete(item.nodeID)} show={role.accessDelete} />
              </Menu>
            : <Menu small>
                <MenuAdd text="add" onClick={() => handleAdd(item.nodeID)} show={role.accessCreate} />
              </Menu>}
        </span>
    }
  ]

  const data = useMemo(
    () => {
      return state.items
        .filter(item => (selected ? item.hasLink : true))
        .filter(
          item =>
            search.length && item.nodeName
              ? search.split(' ').filter(value => item.nodeName.toLowerCase().includes(value)).length > 0
              : true
        )
    },
    [state, selected, search]
  )

  const Toggle = () => <ButtonPrimaryToggle textTrue="show all" textFalse="show selected" value={selected} onChange={updateSelected} />

  return (
    <CardFullHeightScrollY title="category/course" HeaderComponent={Toggle}>
      <TableInputText placeholder="filter" value={search} onChange={handleChange} onClear={handleClear} />
      <LoadingSpinner loading={state.submitting}>
        <TableHierarchy columns={columns} data={data} showAsList={search || selected} />
      </LoadingSpinner>
    </CardFullHeightScrollY>
  )
}
