import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconTemplate } from '../../../../../shared/icon/icon'
import { Route, useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Menu, MenuActivityLog, MenuRecycle } from '../../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { TemplateGridItem } from './template-grid-item'
import { NodeTemplateDetails } from './pages/node-template-details'
import { TemplateFilterList } from './template-filter-list'
import { FilterInput, useFilterList } from '../common/filter/filter-input'
import { useDataContext } from '../data-context'
import { useRoleAccessOrganization } from '../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../role/role.const'
import { NodeRecycle } from '../common/recycle/node-recycle'
import { LogEventOrgSubjectID } from '../../../../../shared/page/log-event/log-event-org'
import { isDatatypeCourseTemplate } from '../common/shared/metadata'
import { Purge } from '../../../../../shared/page/page-purge'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'

export const TemplateGrid = () => {
  return (
    <React.Fragment>
      <div className="container-fluid p-0 h-100">
        <GridBranch />
      </div>
    </React.Fragment>
  )
}

export const GridBranch = ({}) => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.template.find(i => i.id === parentID), [dataContext.template, parentID])

  const children = useMemo(() => dataContext.template.filter(i => i.parentID === (parentID || null)), [dataContext.template, parentID])

  const courseTemplates = useMemo(() => children.filter(item => isDatatypeCourseTemplate(item)), [children])

  const title = useMemo(
    () => {
      if (parentID) {
        return itemCurrent ? itemCurrent.name : ''
      } else {
        return 'TEMPLATES'
      }
    },
    [parentID, itemCurrent]
  )

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to={`${url}/recycle`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show />
    </Menu>

  const [filter, updateFilter, showFilter, selectFilter] = useFilterList()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text={title}>
        <CardFullHeight title={title} HeaderMenu={HeaderMenu}>
          <div className="d-flex flex-column h-100">
            <div className="m-2">
              <FilterInput value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill" style={{ minHeight: 0 }}>
              <div className="overflow-scroll h-100">
                {showFilter
                  ? <div>
                      <TemplateFilterList nodes={dataContext.template} filter={filter} onSelect={selectFilter} />
                    </div>
                  : <div className="container-fluid">
                      <div className="row g-2">
                        {courseTemplates
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <TemplateGridItem key={i} node={node} />)}
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </CardFullHeight>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <GridBranch grandParentURL={url} />
      </Route>

      <Route path={`${url}/template/:nodeID`}>
        <NodeTemplateDetails parentURL={url} />
      </Route>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={parentID} />
      </Route>

      <Route path={`${url}/recycle`}>
        <NodeRecycle nodeID={parentID} types={['course_template']} />
      </Route>

      <Route path={`${url}/recycle/:purgeID/purge`}>
        <Purge useApiForm={useApiFormNode} titleParam="name" successPath={`${url}/recycle`} />
      </Route>
    </React.Fragment>
  )
}
