import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconUsers } from '../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../role/role.const'
import {
  Menu,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnYesNo, ColumnDate } from '../../../../../shared/table-builder/table-builder'
import { useApiListLogin } from '../../../../../api-new/state/content'

export function LoginList() {
  const apiListLogin = useApiListLogin()

  const { accessCreate, accessUpdate, accessDelete } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const handleReady = async () => await apiListLogin.get()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to="/admin/login/list/add" show={accessCreate} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuRecycle to="/admin/login/list/recycle" show={accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="LOGINS">
        <CardFullHeightScrollY title="login" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListLogin} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />

            <ColumnDate field="createdAt" sortable filter />
            <ColumnDate field="updatedAt" sortable filter />

            <ColumnYesNo field="isSysAdmin" header="Sys Admin" sortable filter />
            <ColumnYesNo field="isSysRoot" header="Sys Root" sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/login/list/edit/${row.id}`} show={accessUpdate} />
                  <MenuDelete to={`/admin/login/list/edit/${row.id}/delete`} show={accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
