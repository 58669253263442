import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { IdentifierDetails, RoleAccessDetails, RoleAccessOption } from './common/components'
import { useIdentifier } from './common/hook'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormRoleAccess, useApiListRole, useApiListRoleAccessAudit, useApiListRoleScope } from '../../../../../api-new/state/content'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

const RoleAccessAudit = ({ roleAccessAuditState }) => {
  const show = useMemo(() => roleAccessAuditState.items.length !== 0, [roleAccessAuditState.items])

  return (
    <React.Fragment>
      {show &&
        <div className="py-2">
          {roleAccessAuditState.items.map((item, i) =>
            <div key={i} className="pb-1 text-danger">
              {item.message}
            </div>
          )}
        </div>}
    </React.Fragment>
  )
}

export const RoleAccessEdit = () => {
  const { roleAccessID } = useParamsInt()

  const apiFormRoleAccess = useApiFormRoleAccess()
  const apiListRole = useApiListRole()
  const apiListRoleScope = useApiListRoleScope()
  const apiListRoleAccessAudit = useApiListRoleAccessAudit()

  const handlePath = async () => {
    await apiListRole.get()
    await apiFormRoleAccess.get(roleAccessID)
    await apiListRoleAccessAudit.get(roleAccessID)
  }

  const handleRole = async id => {
    let role = apiListRole.items.find(i => i.id === id)
    if (role) {
      await apiListRoleScope.get(role.scopeType)
    }
  }

  const handleSubmit = async data => {
    await apiFormRoleAccess.put(data)
  }

  const [identifier, updateIdentifier] = useIdentifier(apiListRoleScope.items)

  const title = useMemo(() => `${apiFormRoleAccess.form.role} - ${apiFormRoleAccess.form.identifier}`, [apiFormRoleAccess.form])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title="edit role permission">
          <RoleAccessAudit roleAccessAuditState={apiListRoleAccessAudit} />

          <Form state={apiFormRoleAccess.form} onSubmit={handleSubmit}>
            <FormSelectObj
              label="Role"
              name="roleID"
              state={apiListRole}
              itemName="role"
              itemValue="id"
              valueAsNumber={true}
              required
              onChange={handleRole}
            />

            <FormSelectObj
              label="Endpoint"
              name="identifier"
              state={apiListRoleScope}
              itemName="display"
              itemValue="identifier"
              required
              onChange={updateIdentifier}
            />

            <IdentifierDetails identifier={identifier} />
            <RoleAccessDetails identifier={identifier} />
            <RoleAccessOption identifier={identifier} />

            <FormSubmitBack text="update role access" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
