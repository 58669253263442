import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { ROLE_ORG } from '../../../../role/role.const'
import { useParamsInt } from '../../../../shared/hooks/location'
import { IconAdd } from '../../../../shared/icon/icon'
import { Menu, MenuAdd } from '../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { useApiListNodeUser } from '../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../shared/table-builder/table-builder'
import { useApiListUserLogin } from '../../../../api-new/state/content'

export const ContentListUserAdd = () => {
  const { nodeID } = useParamsInt()

  const apiListUserLogin = useApiListUserLogin()
  const apiListNodeUser = useApiListNodeUser()

  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)

  const handleReady = async () => {
    await apiListUserLogin.get()
    await apiListNodeUser.get(`${nodeID}/node`)
  }

  const handleAdd = async item => {
    await apiListNodeUser.post({ nodeID: nodeID, userID: item.id })
    await apiListNodeUser.get(`${nodeID}/node`)
  }

  const initialFilter = items => {
    return items.filter(userLogin => !apiListNodeUser.items.some(nodeUser => nodeUser.userID === userLogin.id))
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <CardFullHeightScrollY title="groups">
          <TableBuilder apiList={apiListUserLogin} initialFilterFn={initialFilter} onReady={handleReady}>
            <Column field="email" header="Email" />
            <Column field="firstName" header="First Name" />
            <Column field="lastName" header="Last Name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd onClick={() => handleAdd(row)} show={roleNodeUser.accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
