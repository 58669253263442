import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormText } from '../../../../shared/form/form'
import { FormHiddenNumber } from '../../../../shared/form/form-hidden-number'
import { GridContext } from '../grid/grid-context'
import { NodePathButton } from '../../shared/node-path-button'
import { pathCourse } from '../../shared/node-path'
import { useMetadataType } from '../../hooks/hooks-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { DisplaySysAdmin } from '../../../../role/role.component'
import { FormSelectCategory } from '../../../../shared/form/form-control/form-select-category'
import { FormSelectOrgaization } from '../../../../shared/form/form-control/form-select-organization'
import { useApiListNodeQuery } from '../../../../api-new/state/content'
import { useApiFormNodeCourse } from '../../../../api-new/state/content'
import { useLoad } from '../../../../shared/hooks/load'
import { useAssetCopy, AssetCopyProgress } from '../../../../shared/component/asset-copy'
import { Card } from '../../../../shared/card/card'
import { IconCopy } from '../../../../shared/icon/icon'

export const CourseCopy = () => {
  const { nodeID } = useParamsInt()

  const authContext = useContext(AuthContext)
  const gridContext = useContext(GridContext)

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormNodeCourseCopyOrg = useApiFormNodeCourse('/copy-org')
  const apiFormNodeCourseCopy = useApiFormNodeCourse('/copy')
  const apiListNodeQuery = useApiListNodeQuery()

  const [organizationID, updateOrganizationID] = useState()
  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  const [copiedPath, updateCopiedPath] = useState(undefined)

  const title = useMemo(() => `copy ${apiFormNodeCourse.form.name}`, [apiFormNodeCourse.form.name])
  const isCaffeine = useMetadataType(apiFormNodeCourse.form, 'CourseAssemblyName', 'caffeine')

  useLoad(
    () => {
      authContext.state.jwtClaims.organizationID && updateOrganizationID(authContext.state.jwtClaims.organizationID)
      authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID)
    },
    [authContext.state.jwtClaims.organizationID]
  )

  const handleMatch = () => apiFormNodeCourse.get(nodeID)

  const handleOrganization = organizationID => updateCategoryOrganizationID(organizationID)

  const onCopyComplete = copiedPath => updateCopiedPath(copiedPath)

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let newCourse = authContext.state.isSysAdmin ? await apiFormNodeCourseCopyOrg.post(data) : await apiFormNodeCourseCopy.post(data)

    await gridContext.actions.loadContents()
    await gridContext.actions.loadThumbnails()

    if (authContext.state.jwtClaims.organizationID === newCourse.organizationID) {
      let nodes = await apiListNodeQuery.get(`datatype:category,course`)
      let copiedPath = pathCourse(nodes, newCourse.id)
      await init(copiedPath)
    } else {
      await init(null)
    }

    send({
      srcNodeID: nodeID,
      destNodeID: newCourse.id
    })
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconCopy} text="copy course" onMatch={handleMatch}>
        <Card title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

                  <DisplaySysAdmin>
                    <FormSelectOrgaization
                      lable="organization"
                      name="organizationID"
                      organizationID={organizationID}
                      required
                      onChange={handleOrganization}
                    />
                  </DisplaySysAdmin>

                  {categoryOrganizationID &&
                    <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />}

                  <FormText label="name" name="name" defaultValue={apiFormNodeCourse.form.name} required />

                  <FormSubmitBack text="copy course" />
                </Form>

                <AssetCopyProgress content={content} />

                <NodePathButton path={copiedPath} text="go to course" />
              </React.Fragment>
            : <div>Action not allowed for course</div>}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
