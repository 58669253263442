export const pathCourse = (nodes, nodeID) => {
  let items = []

  const build = (nodes, nodeID, items) => {
    let node = nodes.find(i => i.id === nodeID)

    if (node) {
      items.splice(0, 0, node)

      if (node.parentID) {
        build(nodes, node.parentID, items)
      }
    }
  }

  build(nodes, nodeID, items)

  let redirect = `/content`

  items.forEachLast((item, last) => {
    if (last) {
      redirect += `/course-details/${item.id}/edit/${item.id}`
    } else {
      redirect += `/node/${item.id}`
    }
  })

  return redirect
}

export const pathTemplate = (nodes, nodeID) => {
  let items = []

  const build = (nodes, nodeID, items) => {
    let node = nodes.find(i => i.id === nodeID)

    if (node) {
      items.splice(0, 0, node)

      if (node.parentID) {
        build(nodes, node.parentID, items)
      }
    }
  }

  build(nodes, nodeID, items)

  let redirect = '/template'

  items.forEachLast((item, last) => {
    if (last) {
      redirect += `/edit/${item.id}`
    } else {
      redirect += `/node/${item.id}`
    }
  })

  return redirect
}
