import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconCopy, IconEdit, IconFile } from '../../../../../../shared/icon/icon'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { ThumbnailImageEdit } from '../../../../../../view/node-new/shared/thumbnail-image-edit'
import { useGetByDataType, useMetadataType } from '../../common/hooks/hooks'
import { Menu, MenuActivityLog, MenuAdd, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { useApiFormTemplate, useApiListAsset } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useRoleAccessOrganization, useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_ORG, ROLE_SYS } from '../../../../../../role/role.const'
import { useDataContext } from '../../data-context'
import { useDisplaySysAdmin } from '../../../../../../role/role.component'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const NodeTemplateEdit = ({ parentURL, nodeID }) => {
  const apiFormTemplate = useApiFormTemplate()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormTemplate.get(nodeID)
      apiListAsset.get(`${nodeID}/node`)
    },
    [nodeID]
  )

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNode = useRoleAccessSystem(ROLE_SYS.NODE)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])
  const isCaffeine = useMetadataType(apiFormTemplate.form, 'CourseAssemblyName', 'caffeine')

  const handleSubmit = async data => {
    let node = await apiFormTemplate.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  // const template = useFindNode(templateID, gridContext.templates)

  const displaySysAdmin = useDisplaySysAdmin()

  const HeaderMenu = () =>
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuEdit text="sys edit" to={`${parentURL}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={roleNode.accessRead} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuAdd text="new course" to={`${parentURL}/generate`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${parentURL}/copy`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${parentURL}/activity`} show={true} />
    </Menu>

  const thumbnail = useGetByDataType('thumbnail', apiListAsset.items)

  const handleImageChange = () => {} //gridContext.actions.loadThumbnails()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormTemplate.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" required />
              <FormSubmit text="update" />
            </Form>
          </Card>
          <ThumbnailImageEdit nodeID={nodeID} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
