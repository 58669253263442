import React, { useEffect, useMemo } from 'react'
import { useAssetForm } from '../state'
import { useParams } from 'react-router'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { Form, FormNumber, FormSubmit, FormText } from '../../../shared/form/form'
import { StorageFileChunk } from '../../../shared/legacy/storage/storage-file-chunk'
import { FormMetadata } from '../../../shared/form/form-control/form-metadata'
import { Card } from '../../../shared/card/card'
import { IconEdit } from '../../../shared/icon/icon'

export const Progress = ({ current, length }) => {
  const width = useMemo(() => `${current / length * 100}%`, [current, length])

  return (
    <React.Fragment>
      <div className="progress">
        <div className="progress-bar" role="progressbar" style={{ width: width }} />
      </div>
    </React.Fragment>
  )
}

export const AssetEdit = () => {
  const { assetID } = useParams()

  const [assetFormActions, assetFormState] = useAssetForm()

  useEffect(
    () => {
      assetFormActions.get(assetID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetID]
  )

  const handleSubmit = async data => await assetFormActions.put(data)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={`${assetFormState.form.name}`}>
        <Card title="Asset">
          <Form state={assetFormState.form} submitting={assetFormState.submitting} onSubmit={handleSubmit}>
            <FormText label="name" name="name" />
            <FormText label="key" name="key" readOnly />
            <FormNumber label="size" name="length" />
            <FormText label="created" name="createdAt" readOnly />
            <FormText label="updated" name="updatedAt" readOnly />
            <FormNumber label="Version" name="version" readOnly />
            <FormMetadata label="metadata" name="metaData" />
            <FormSubmit text="update asset" />
          </Form>
        </Card>
        <StorageFileChunk storageKey={assetFormState.form.key} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
