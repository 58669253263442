import React, { useEffect, useState } from 'react'
import { useContentNodeList, useContentAssetList } from '../../../../api/state'

export const GridContext = React.createContext()

export const GridProvider = ({ children }) => {
  const [contentNodeListActions, contentNodeListContent] = useContentNodeList()
  const [contentAssetListActions, contentAssetListContent] = useContentAssetList()

  const [parentID, updateParentID] = useState(null)
  const [filter, updateFilter] = useState('')

  useEffect(() => {
    loadContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadThumbnails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadContents = async () => await contentNodeListActions.get()
  const loadThumbnails = async () => await contentAssetListActions.get()

  const setParentID = parentID => updateParentID(parentID)
  const setFilter = value => updateFilter(value)
  const clearFilter = () => updateFilter('')

  return (
    <GridContext.Provider
      value={{
        parentID,
        filter,
        contents: contentNodeListContent.items,
        thumbnails: contentAssetListContent.items,
        actions: { loadContents, loadThumbnails, setParentID, setFilter, clearFilter }
      }}>
      {children}
    </GridContext.Provider>
  )
}
