import React, { useEffect } from 'react'
import { Card } from '../../shared/card/card'
import { SelectTag } from '../../shared/select-tag/select-tag'
import { useApiListRoleSyncUserSys } from '../../api-new/state/content'

export const RoleSelectUserSys = ({ userID }) => {
  const apiListRoleSyncUserSys = useApiListRoleSyncUserSys()

  useEffect(
    () => {
      apiListRoleSyncUserSys.get(userID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  )

  const handleAdd = async item => {
    await apiListRoleSyncUserSys.post(item)
    await apiListRoleSyncUserSys.get(userID)
  }

  const handleRemove = async item => {
    await apiListRoleSyncUserSys.delete(`${item.roleID}/${item.userID}`)
    await apiListRoleSyncUserSys.get(userID)
  }

  return (
    <React.Fragment>
      <Card title="User Roles">
        <SelectTag
          data={apiListRoleSyncUserSys.items}
          fnLabel={item => item.role}
          fnActive={item => item.active}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </Card>
    </React.Fragment>
  )
}
