import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Menu, MenuAdd, MenuRemove } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { IconUsers } from '../../../../../../shared/icon/icon'

export const GroupListUser = () => {
  const { groupID } = useParamsInt()

  const apiListGroupUser = useApiListGroupUser()

  const handleReady = async () => await apiListGroupUser.get(`${groupID}/group`)

  const handleDelete = async item => {
    apiListGroupUser.remove(item)
    await apiListGroupUser.delete(`${item.groupID}/${item.userID}`)
    await apiListGroupUser.get(`${groupID}/group`)
  }

  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/org/group/list/edit/${groupID}/user/add`} show={roleGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="users">
        <CardFullHeightScrollY title="group users" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListGroupUser} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRemove text="remove" onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
