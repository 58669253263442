export const getMap = items => {
  const map = {}
  items.forEach(item => {
    map[item.id] = item
  })
  return map
}

export const buildNodeLocation = (nodes, nodeID) => {
  const nodeMap = getMap(nodes)
  return buildNodeLocationMap(nodeMap, nodeID)
}

const buildNodeLocationMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID)
      return `${path}/${node.name}`
    } else {
      return `/${node.name}`
    }
  }

  return recBuild(nodeMap, nodeID, false)
}

export const buildCoursePath = (nodes, nodeID) => {
  const nodeMap = getMap(nodes)
  return buildCoursePathMap(nodeMap, nodeID)
}

export const buildCoursePathMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID, notTarget) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID, true)

      if (notTarget) {
        return `${path}/node/${node.id}`
      } else {
        let datatype = JSON.parse(node.metaData.toLowerCase()).datatype

        if (datatype === 'course') {
          return `${path}/course/${node.id}`
        } else if (datatype === 'streaming_video') {
          return `${path}/course/${node.id}`
        } else if (datatype === 'category') {
          return `${path}/node/${node.id}`
        } else {
          return path
        }
      }
    } else {
      return `/org/node-content/node/${node.id}`
    }
  }

  return recBuild(nodeMap, nodeID, false)
}
export const buildTemplatePath = (nodes, nodeID) => {
  const nodeMap = getMap(nodes)
  return buildTemplatePathMap(nodeMap, nodeID)
}

export const buildTemplatePathMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID, notTarget) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID, true)

      if (notTarget) {
        return `${path}/node/${node.id}`
      } else {
        let datatype = JSON.parse(node.metaData.toLowerCase()).datatype

        if (datatype === 'course_template') {
          return `${path}/template/${node.id}`
        } else {
          return path
        }
      }
    } else {
      return `/org/node-template/template/${node.id}/edit`
    }
  }

  return recBuild(nodeMap, nodeID, false)
}
