import React, { useContext, useEffect, useMemo } from 'react'
import { useRoleAccessUserList, useRoleAccessLoginList } from '../api/state'
import { AuthContext } from '../context/auth.context'

export const RoleContext = React.createContext()

export const RoleProvider = ({ children }) => {
  const authContext = useContext(AuthContext)

  const [roleAccessUserListActions, roleAccessUserListState] = useRoleAccessUserList()
  const [roleAccessLoginListActions, roleAccessLoginListState] = useRoleAccessLoginList()

  useEffect(
    () => {
      authContext.state.userID && roleAccessUserListActions.get(authContext.state.userID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.userID]
  )

  useEffect(
    () => {
      authContext.state.loginID && roleAccessLoginListActions.get(authContext.state.loginID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.loginID]
  )

  const orgRoles = useMemo(
    () => {
      let roles = {}
      roleAccessUserListState.items.forEach(role => (roles[role.identifier] = role))
      return roles
    },
    [roleAccessUserListState.items]
  )

  const sysRoles = useMemo(
    () => {
      let roles = {}
      roleAccessLoginListState.items.forEach(role => (roles[role.identifier] = role))
      return roles
    },
    [roleAccessLoginListState.items]
  )

  const test = () => {
    debugger
  }

  return (
    <RoleContext.Provider value={{ orgRoles, sysRoles, test: test }}>
      {children}
    </RoleContext.Provider>
  )
}
