import React, { useCallback, useMemo } from 'react'
import { MetadataNode } from '../metadata/metadata-node'
import { SearchList } from './search-list'
import { IconContent } from '../../icon/icon'

const DisplayItemSelected = ({ selectText, item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <MetadataNode node={item.node} tag="datatype" value="category">
            <IconContent />
          </MetadataNode>
          {item.node.name}
        </span>}
      {!item &&
        <span className="text-capitalize">
          {selectText || 'select'}
        </span>}
    </React.Fragment>
  )
}

const DisplayItemList = ({ item }) => {
  return (
    <React.Fragment>
      <span style={{ marginLeft: `${item && item.$level ? item.$level * 20 : 0}px` }}>
        <MetadataNode node={item.node} tag="datatype" value="category">
          <IconContent />
        </MetadataNode>
        {item && item.node.name}
      </span>
    </React.Fragment>
  )
}

const formatTree = nodes => {
  let roots = []
  let items = {}
  let tree = []

  for (let node of nodes) {
    if (!node.parentID) {
      roots.push(node.id)
    }

    if (node.parentID) {
      if (!items[node.parentID]) {
        items[node.parentID] = { node: undefined, children: [] }
      }

      items[node.parentID].children.push(node.id)
    }

    if (!items[node.id]) {
      items[node.id] = { node: node, children: [] }
    } else {
      items[node.id].node = node
    }
  }

  const buildTree = (nodeID, items, level, results, parentIDs) => {
    let item = items[nodeID]
    item.$level = level
    results.push(item)

    item.$parentIDs = [...parentIDs]

    parentIDs.push(item.node.id)

    if (item.children) {
      for (let childNodeID of item.children) {
        buildTree(childNodeID, items, level + 1, results, [...parentIDs])
      }
    }
  }

  for (let rootID of roots) {
    let results = []
    buildTree(rootID, items, 0, results, [])
    tree = [...tree, ...results]
  }

  return tree
}

const handleFilter = (items, value) => {
  if (!value) {
    return items
  }

  value = value.toLowerCase()

  let selectedItems = items.filter(item => item.node.name.toLowerCase().includes(value))

  let filteredItems = []

  for (let selectedItem of selectedItems) {
    let parentItems = items.filter(i => selectedItem.$parentIDs.includes(i.node.id))
    filteredItems = [...filteredItems, ...parentItems, selectedItem]
  }

  return filteredItems
}

export const SearchListNode = ({ dropLeft, selectText, actionText, items, selectedItem, disabled, onAdd, onSelect }) => {
  const formatedItem = useMemo(() => formatTree(items), [items])

  const handleSelectedItem = useCallback(
    item => {
      if (item && selectedItem) {
        return item.node.id === selectedItem.node.id
      }

      return false
    },
    [selectedItem]
  )

  return (
    <React.Fragment>
      <SearchList
        dropLeft={dropLeft}
        items={formatedItem}
        selectedItem={selectedItem}
        DisplayItemSelected={DisplayItemSelected}
        DisplayItemList={DisplayItemList}
        selectText={selectText}
        actionText={actionText}
        fnFilter={handleFilter}
        fnSelectedItem={handleSelectedItem}
        disabled={disabled}
        onAdd={onAdd}
        onSelect={onSelect}
      />
    </React.Fragment>
  )
}
