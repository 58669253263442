import React, { useEffect, useState } from 'react'
import { useNodeUserList } from '../state'
import { IconLinkEdit } from '../../../shared/icon/icon-link'
import { useRouteMatch } from 'react-router'
import { Card } from '../../../shared/card/card'

export const WidgetUser = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const [nodeUserListActions, nodeUserListState] = useNodeUserList()

  const [userCount, updateUserCount] = useState(0)
  const [users, updateUsers] = useState([])

  useEffect(
    () => {
      nodeUserListActions.get(`${nodeID}/node`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useEffect(
    () => {
      let length = nodeUserListState.items.length
      updateUserCount(length)
      updateUsers(nodeUserListState.items.map((e, i) => `${e.email}${i === length - 1 ? '' : ', '}`))
    },
    [nodeUserListState.items]
  )

  const HeaderMenu = () => <IconLinkEdit to={`${url}/user`} />

  return (
    <React.Fragment>
      <Card title={`Users - ${userCount || 'none'}`} HeaderMenu={HeaderMenu}>
        {users}
      </Card>
    </React.Fragment>
  )
}
