import React, { useContext } from 'react'
import { GridItems } from './grid-items'
import { Route, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useFindNode, useNodeTitle } from '../../hooks/hooks-node'
import { TemplateEdit } from '../template/template-edit'
import { GridContext } from './grid-context'
import { InputFilter } from '../../shared/input-filter'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Menu, MenuRecycle } from '../../../../shared/menu/menu'
import { Card } from '../../../../shared/card/card'
import { IconTemplate } from '../../../../shared/icon/icon'

export const GridFrame = () => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const gridContext = useContext(GridContext)

  const currentNode = useFindNode(parentID, gridContext.templates)
  const title = useNodeTitle('templates', parentID, currentNode)

  const Header = () =>
    <Menu>
      <MenuRecycle to="/template/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text={title}>
        <Card title="templates" HeaderMenu={Header}>
          <div className="py-2">
            <InputFilter
              value={gridContext.filter}
              placeholder="filter"
              onChange={gridContext.actions.setFilter}
              onClear={gridContext.actions.clearFilter}
            />
          </div>

          <GridItems url={url} />
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <GridFrame />
      </Route>

      <Route path={`${url}/edit/:templateID`}>
        <TemplateEdit />
      </Route>
    </React.Fragment>
  )
}
