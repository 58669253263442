import React, { useEffect, useState } from 'react'
import { useNodeGroupList } from '../state'
import { IconLinkEdit } from '../../../shared/icon/icon-link'
import { useRouteMatch } from 'react-router'
import { Card } from '../../../shared/card/card'

export const WidgetGroup = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const [nodeGroupListActions, nodeGroupListState] = useNodeGroupList()

  const [groupCount, updateGroupCount] = useState(0)
  const [groups, updateGroups] = useState([])

  useEffect(
    () => {
      nodeGroupListActions.get(`${nodeID}/node`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useEffect(
    () => {
      let length = nodeGroupListState.items.length
      updateGroupCount(length)
      updateGroups(nodeGroupListState.items.map((e, i) => `${e.groupName}${i === length - 1 ? '' : ', '}`))
    },
    [nodeGroupListState.items]
  )

  const HeaderMenu = () => <IconLinkEdit to={`${url}/group`} />

  return (
    <React.Fragment>
      <Card title={`Groups - ${groupCount || 'none'}`} HeaderMenu={true && HeaderMenu}>
        {groups}
      </Card>
    </React.Fragment>
  )
}
