import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { ROLE_SYS } from '../../../../../role/role.const'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnDateTime, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListAuthProviderLogin } from '../../../../../api-new/state/auth'
import { IconView, IconList } from '../../../../../shared/icon/icon'

export const ProviderAuthList = () => {
  const { providerID } = useParamsInt()

  const apiListAuthProviderLogin = useApiListAuthProviderLogin()

  const roleProvider = useRoleAccessSystem(ROLE_SYS.PROVIDER)

  const handleReady = async () => await apiListAuthProviderLogin.get(`${providerID}/provider`)

  const handleDelete = async data => {
    await apiListAuthProviderLogin.delete(data.id)
    await apiListAuthProviderLogin.get(`${providerID}/provider`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="auth provider logins">
        <CardFullHeightScrollY title="auth provider logins">
          <TableBuilder apiList={apiListAuthProviderLogin} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />

            <ColumnDateTime field="authorized" name="authorized on" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconView}
                    text="view"
                    to={`/admin/provider/list/edit/${row.providerID}/auth/${row.providerID}/view/${row.id}`}
                    show
                    pin
                  />
                  <MenuDelete onClick={() => handleDelete(row)} show={roleProvider.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
