import React, { useEffect } from 'react'
import { NodeDetails } from './node-edit/node-details'
import { AssetList } from './node-edit/asset-list'
import { WidgetUser } from './node-edit/widget-user'
import { WidgetGroup } from './node-edit/widget-group'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import { useNodeForm } from './state'
import { AssetEdit } from './node-edit/asset-edit'
import { AssetAdd } from './node-edit/asset-add'
import { IconEdit } from '../../shared/icon/icon'

export function NodeEdit({ parentUrl, onUpdate }) {
  const history = useHistory()
  const location = useLocation()
  const { url } = useRouteMatch()
  const { nodeID } = useParams()

  const [nodeFormAction, nodeFormState] = useNodeForm()

  useEffect(
    () => {
      nodeFormAction.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  const handleSubmit = async data => {
    await nodeFormAction.put(data)
    onUpdate && onUpdate()
    history.push(parentUrl)
  }

  const handleAssetAdd = assetID => history.push(`${url}/asset/edit/${assetID}`)
  const handleCancel = () => history.push(parentUrl)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={`${nodeFormState.form.name}`}>
        <NodeDetails nodeID={nodeID} state={nodeFormState} onSubmit={handleSubmit} onCancel={handleCancel} />
        <AssetList nodeID={nodeID} />
        <WidgetUser nodeID={nodeID} />
        <WidgetGroup nodeID={nodeID} />
      </BreadcrumbView>

      <Route path={`${url}/asset/add/:nodeID`}>
        <AssetAdd onAdd={handleAssetAdd} />
      </Route>

      <Route path={`${url}/asset/edit/:assetID`}>
        <AssetEdit parentUrl={url} />
      </Route>
    </React.Fragment>
  )
}
