import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { DisplayAuth } from '../../context/display-auth'

import { LayoutNode } from '../../view/node/layout'

import { ContentRoute } from '../../view/node-new/view-content/route'

import { LayoutTemplate } from '../../view/node-new/view-template/layout'

import { RedirectDashboard } from '../redirect-dashboard'
import { DisplaySysAdmin } from '../../role/role.component'
import { Timeout } from '../../shared/component/timeout'
import { LayoutRoot } from '../../layout/authorized/root/layout-root'
import { LayoutAdmin } from '../../layout/authorized/admin/layout-admin'
import { LayoutOrg } from '../../layout/authorized/org/layout-org'

export const RouteAdmin = () => {
  return (
    <React.Fragment>
      <RedirectDashboard>
        <Timeout />
        <Switch>
          <DisplayAuth>
            <LayoutRoot />
            <LayoutAdmin />
            <LayoutOrg />

            <DisplaySysAdmin>
              <Route path="/node" component={LayoutNode} />
            </DisplaySysAdmin>

            <Route path="/content" component={ContentRoute} />
            <Route path="/template" component={LayoutTemplate} />
          </DisplayAuth>
        </Switch>
      </RedirectDashboard>
    </React.Fragment>
  )
}
