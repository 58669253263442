import { useCallback } from 'react'
import { useAssetFileGet } from '../../../api/state'

export const useAssetDownload = () => {
  const [assetFileGetActions] = useAssetFileGet()

  const download = useCallback(async assetKey => {
    try {
      let blob = await assetFileGetActions.blob(assetKey)
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = assetKey
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(`failed to get assets: ${assetKey}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return download
}
