import React, { useContext, useMemo } from 'react'
import { useActiveNodes } from '../../hooks/hooks-node'
import { MetaDataType } from '../../shared/meta-data-type'
import { ThumbnailImage } from '../../shared/thumbnail-image'
import { GridContext } from './grid-context'
import { Link } from 'react-router-dom'
import { useParamsInt } from '../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { Menu, MenuAdd, MenuEdit, MenuItem } from '../../../../shared/menu/menu'
import { IconCopy, IconDelete, IconTemplate } from '../../../../shared/icon/icon'

export const GridItems = ({ url }) => {
  const { parentID } = useParamsInt()

  const gridContext = useContext(GridContext)

  const activeNodes = useActiveNodes(parentID, gridContext.filter, gridContext.templates)

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const searchNodes = useMemo(
    () => {
      if (!gridContext.search) {
        return activeNodes
      }

      return activeNodes.filter(i => {
        let value = gridContext.search.toLowerCase()
        return i.name.toLowerCase().includes(value)
      })
    },
    [activeNodes, gridContext.search]
  )

  const { accessUpdate } = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  return (
    <React.Fragment>
      <div className="row">
        {searchNodes.map((node, i) =>
          <div key={i} className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card">
              <div className="card-body m-0 p-0">
                <div className="p-1">
                  <MetaDataType item={node} type="datatype" value="course_template">
                    {accessUpdate
                      ? <Link to={accessUpdate ? `${url}/edit/${node.id}` : ''}>
                          <ThumbnailImage nodeID={node.id} assets={gridContext.thumbnails} />
                        </Link>
                      : <ThumbnailImage nodeID={node.id} assets={gridContext.thumbnails} />}
                  </MetaDataType>
                  <MetaDataType item={node} type="datatype" value="category">
                    {accessUpdate
                      ? <Link to={accessUpdate ? `${url}/node/${node.id}` : ''}>
                          <ThumbnailImage nodeID={node.id} assets={gridContext.thumbnails} />
                        </Link>
                      : <ThumbnailImage nodeID={node.id} assets={gridContext.thumbnails} />}
                  </MetaDataType>
                </div>
                <div className="border-top d-flex p-2">
                  <IconTemplate />
                  <div className="flex-fill text-nowrap text-truncate">
                    {node.name}
                  </div>
                  <Menu small>
                    <MenuAdd
                      text="new course"
                      to={`${url}/edit/${node.id}/generate/${node.id}`}
                      show={roleContent.accessCreate}
                      pin={false}
                    />

                    <MenuItem Icon={IconCopy} text="copy" to={`${url}/edit/${node.id}/copy/${node.id}`} show={roleContent.accessCreate} />
                    <MenuEdit to={`${url}/edit/${node.id}`} show={roleContent.accessUpdate} pin={false} />

                    <MenuItem
                      Icon={IconDelete}
                      text="delete"
                      to={`${url}/edit/${node.id}/delete/${node.id}`}
                      show={roleContent.accessDelete}
                    />
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
