import React from 'react'
import { Link } from 'react-router-dom'
import { IconMenu } from '../../../shared/icon/icon'

export const Menu = ({ children }) => {
  return (
    <React.Fragment>
      <div className="dropdown">
        <span data-toggle="dropdown">
          <IconMenu />
        </span>
        <div className="dropdown-menu text-capitalize">
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

export const MenuLink = ({ Icon, text, to, disabled }) => {
  const Content = ({ text }) =>
    <React.Fragment>
      {Icon && <Icon className="pr-1" />}
      {text}
    </React.Fragment>

  return (
    <React.Fragment>
      {disabled
        ? <div className="dropdown-item text-muted" style={{ cursor: 'not-allowed' }}>
            <Content text={text} />
          </div>
        : <Link className="dropdown-item" style={{ cursor: 'pointer' }} to={to}>
            <Content text={text} />
          </Link>}
    </React.Fragment>
  )
}
