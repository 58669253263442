import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'

export const Table = ({ columns, data, disabled, sortFunction, onRowClicked }) => {
  const [defaultSortField, updateDefaultSortField] = useState(undefined)

  useEffect(
    () => {
      if (columns.length) {
        updateDefaultSortField(columns[0].selector)
      }
    },
    [columns]
  )

  const rowsPerPage = useMemo(() => parseInt(localStorage.getItem('table-filter-rows-per-page')), [])

  const handleRowChange = value => localStorage.setItem('table-filter-rows-per-page', value)

  const defaultSortFunction = (arr, key, direction) => {
    const result = arr.sort((first, second) => {
      const firstValue = first[key] ? (first[key].toLowerCase ? first[key].toLowerCase() : first[key]) : ''
      const secondValue = second[key] ? (second[key].toLowerCase ? second[key].toLowerCase() : second[key]) : ''

      if (direction === 'asc') {
        return firstValue < secondValue ? -1 : 1
      } else {
        return firstValue < secondValue ? 1 : -1
      }
    })

    return [...result]
  }

  return (
    <div style={{ position: 'relative', zIndex: '50' }}>
      <DataTable
        columns={columns}
        data={data}
        disabled={disabled}
        striped
        highlightOnHover
        noHeader
        pagination
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        paginationPerPage={rowsPerPage || 30}
        onChangeRowsPerPage={handleRowChange}
        sortFunction={sortFunction || defaultSortFunction}
        defaultSortField={defaultSortField}
        onRowClicked={onRowClicked}
        conditionalRowStyles={[
          {
            when: () => onRowClicked,
            style: { cursor: 'pointer' }
          }
        ]}
      />
    </div>
  )
}
