import React, { useEffect, useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { SearchListOrganization } from '../../../../../shared/legacy/dropdown-search/search-list-organization'
import { useOrganizationList } from '../../../../../api/state'
import { Menu, MenuDelete } from '../../../../../shared/menu/menu'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListSysAdminOrg } from '../../../../../api-new/state/content'

import { ColumnFormat } from '../../../../../shared/table-builder/table-builder'
import { IconEdit } from '../../../../../shared/icon/icon'

export function SysAdminOrgs() {
  const { loginID } = useParamsInt()

  const apiListSysAdminOrg = useApiListSysAdminOrg()

  const handleReady = () => apiListSysAdminOrg.get(`${loginID}/login`)

  const handleAdd = async organization => {
    await apiListSysAdminOrg.post({ organizationID: organization.id, loginID: loginID })
    await apiListSysAdminOrg.get(`${loginID}/login`)
  }

  const handleDelete = async id => {
    await apiListSysAdminOrg.delete(id)
    await apiListSysAdminOrg.get(`${loginID}/login`)
  }

  const HeaderComponent = () => <Header onAdd={handleAdd} />

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="organizations">
        <CardFullHeightScrollY title="sys admins" HeaderComponent={HeaderComponent}>
          <TableBuilder apiList={apiListSysAdminOrg} hideFloatingFilter onReady={handleReady}>
            <Column field="organizationName" sortable filter />
            <ColumnFormat field="isCompany" header="type" selector={row => (row.isCompany ? 'Company' : 'User ')} sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row.id)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const Header = ({ onAdd }) => {
  const [selectedOrganization, updateSelectedOrganization] = useState()

  const [organizationActions, organizationState] = useOrganizationList()

  useEffect(() => {
    organizationActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = node => updateSelectedOrganization(node)

  const handleAdd = async item => {
    updateSelectedOrganization(null)
    onAdd && onAdd(item)
  }

  return (
    <SearchListOrganization
      selectText="select Organization"
      actionText="add"
      items={organizationState.items}
      selectedItem={selectedOrganization}
      onSelect={handleSelect}
      onAdd={handleAdd}
    />
  )
}
