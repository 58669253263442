import React, { useContext, useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmit, FormText } from '../../../../shared/form/form'
import { GridContext } from '../grid/grid-context'
import { useGetByDataType } from '../../hooks/hook-asset'
import { useFindNode, useMetadataType } from '../../hooks/hooks-node'
import { ThumbnailImageEdit } from '../../shared/thumbnail-image-edit'
import { useAssetList, useNodeTemplateForm } from '../../../../api/state'
import { TemplateCopy } from './template-copy'
import { TemplateGenerate } from './template-generate'
import { NodeAssetList } from '../../view-asset/node-asset-list'
import { useParamsInt } from '../../../../shared/hooks/location'
import { ROLE_ORG, ROLE_SYS } from '../../../../role/role.const'
import { useRoleAccessOrganization, useRoleAccessSystem } from '../../../../role/role.hook'
import { Menu, MenuAdd, MenuDelete, MenuItem } from '../../../../shared/menu/menu'
import { Card } from '../../../../shared/card/card'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormTemplate } from '../../../../api-new/state/content'
import { IconEdit, IconCopy, IconFile } from '../../../../shared/icon/icon'

export const TemplateEdit = () => {
  const { url } = useRouteMatch()

  const { templateID } = useParamsInt()

  const [nodeTemplateFormActions, nodeTemplateFormState] = useNodeTemplateForm()
  const [assetListActions, assetListState] = useAssetList()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNode = useRoleAccessSystem(ROLE_SYS.NODE)

  const gridContext = useContext(GridContext)

  const title = useMemo(() => nodeTemplateFormState.form.name, [nodeTemplateFormState.form])
  const isCaffeine = useMetadataType(nodeTemplateFormState.form, 'CourseAssemblyName', 'caffeine')

  const handlePath = async () => {
    nodeTemplateFormActions.get(templateID)
    assetListActions.get(`${templateID}/node`)
  }

  const handleSubmit = async node => await nodeTemplateFormActions.put(node)

  const template = useFindNode(templateID, gridContext.templates)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="new course" to={`${url}/generate/${templateID}`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy/${templateID}`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${url}/delete/${templateID}`} show={roleContent.accessDelete} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/assets/${templateID}`} show={roleNode.accessRead} />
    </Menu>

  const thumbnail = useGetByDataType('thumbnail', assetListState.items)

  const handleImageChange = () => gridContext.actions.loadThumbnails()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title={title} HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={nodeTemplateFormState.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" required />
              <FormSubmit text="update" />
            </Form>
          </Card>
          <ThumbnailImageEdit nodeID={template.id} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/generate/:templateID`}>
        <TemplateGenerate parentUrl={url} />
      </Route>

      <Route path={`${url}/copy/:templateID`}>
        <TemplateCopy parentUrl={url} />
      </Route>

      <Route path={`${url}/delete/:deleteID`}>
        <Delete useApiForm={useApiFormTemplate} titleParam="name" successPath="/template" />
      </Route>

      <Route path={`${url}/assets/:nodeID`}>
        <NodeAssetList parentUrl={url} />
      </Route>
    </React.Fragment>
  )
}
