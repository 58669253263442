import React, { useContext, useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../shared/form/form'
import { IconAdd } from '../../../../shared/icon/icon'
import { NodePathButton } from '../../shared/node-path-button'
import { AuthContext } from '../../../../context/auth.context'
import { pathCourse } from '../../shared/node-path'
import { useMetadataType } from '../../hooks/hooks-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { FormSelectCategory } from '../../../../shared/form/form-control/form-select-category'
import { useLoad } from '../../../../shared/hooks/load'
import { useApiFormTemplate } from '../../../../api-new/state/content'
import { useApiListNodeQuery } from '../../../../api-new/state/content'
import { useApiFormNodeCourse } from '../../../../api-new/state/content'
import { AssetCopyProgress, useAssetCopy } from '../../../../shared/component/asset-copy'
import { Card } from '../../../../shared/card/card'

export const TemplateGenerate = () => {
  const { templateID } = useParamsInt()

  const authContext = useContext(AuthContext)

  const [copiedPath, updateCopiedPath] = useState(undefined)

  const apiFormNodeCourseMove = useApiFormNodeCourse(`/temp`)
  const apiListNodeQuery = useApiListNodeQuery()
  const apiFormTemplate = useApiFormTemplate()

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])
  const isCaffeine = useMetadataType(apiFormTemplate.form, 'CourseAssemblyName', 'caffeine')

  const handleMatch = async () => await apiFormTemplate.get(templateID)

  const onCopyComplete = copiedPath => updateCopiedPath(copiedPath)

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let courseNode = await apiFormNodeCourseMove.post(data)

    if (authContext.state.jwtClaims.organizationID === courseNode.organizationID) {
      let nodes = await apiListNodeQuery.get(`datatype:category,course`)
      let copiedPath = pathCourse(nodes, courseNode.id)
      await init(copiedPath)
    } else {
      await init(null)
    }

    send({
      srcNodeID: templateID,
      destNodeID: courseNode.id
    })
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="generate course" onMatch={handleMatch}>
        <Card title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="templateID" value={templateID} required shouldDirty />

                  {categoryOrganizationID &&
                    <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />}

                  <FormText label="name" name="name" defaultValue={apiFormTemplate.form.name} shouldDirty required />
                  <FormSubmitBack text="generate course" />
                </Form>
                <AssetCopyProgress content={content} />
                <NodePathButton path={copiedPath} text="go to course" />
              </React.Fragment>
            : <div>Action not allowed for template</div>}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
