import React from 'react'
import { RoleSelectUserOrg } from '../../../../../../role/components/role-select-user-org'

export const UserRoles = ({ userID }) => {
  return (
    <React.Fragment>
      <RoleSelectUserOrg userID={userID} />
    </React.Fragment>
  )
}
