import React from 'react'
import { useApiListGroup, useApiListNodeGroup } from '../../../../../../api-new/state/content'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'

export const NodeGroupPermissionAdd = ({ node }) => {
  const apiListNodeGroup = useApiListNodeGroup()

  const apiListGroup = useApiListGroup()

  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)

  const handleMatch = async () => {
    await apiListNodeGroup.get(`${node.id}/node`)
    await apiListGroup.get()
  }

  const handleAdd = async item => {
    await apiListNodeGroup.post({ nodeID: node.id, groupID: item.id })
    await apiListNodeGroup.get(`${node.id}/node`)
  }

  const initialFilter = items => {
    return items.filter(group => !apiListNodeGroup.items.some(nodeGroup => nodeGroup.groupID === group.id))
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onMatch={handleMatch}>
        <CardFullHeightScrollY title="groups">
          <TableBuilder apiList={apiListGroup} initialFilterFn={initialFilter}>
            <Column field="name" header="Name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd onClick={() => handleAdd(row)} show={roleNodeGroup.accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
