import React, { useEffect } from 'react'
import { useNodeForm } from '../state'
import { Form, FormText, FormSubmit } from '../../../shared/form/form'
import { FormCourseAccessID } from '../../../shared/form/form-control/form-course-access-id'
import { FormMetadata } from '../../../shared/form/form-control/form-metadata'
import { Card } from '../../../shared/card/card'

export const NodeDetails = ({ nodeID }) => {
  const [nodeFormAction, nodeFormState] = useNodeForm()

  useEffect(
    () => {
      nodeFormAction.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const handleSubmit = async data => await nodeFormAction.put(data)
  const handleRefresh = async () => nodeFormAction.get(nodeID)

  return (
    <React.Fragment>
      <Card title="Node Edit">
        <Form state={nodeFormState.form} submitting={nodeFormState.submitting} onSubmit={handleSubmit} onRefresh={handleRefresh}>
          <FormText label="name" name="name" />
          <FormCourseAccessID label="access" name="accessID" />
          <FormMetadata label="metadata" name="metaData" />
          <FormSubmit />
        </Form>
      </Card>
    </React.Fragment>
  )
}
