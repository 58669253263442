import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAssetList, useNodeCourseForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmit, FormText, FormHiddenNumber, FormHidden } from '../../../../shared/form/form'
import { GridContext } from '../grid/grid-context'
import { ThumbnailImageEdit } from '../../shared/thumbnail-image-edit'
import { useGetByDataType } from '../../hooks/hook-asset'
import { SelectCourseAssess } from '../../shared/select-course-access'
import { SelectCourseMetadata } from '../../shared/select-course-metadata'
import { useMetadataType } from '../../hooks/hooks-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Route, useLocation, useRouteMatch } from 'react-router-dom'
import { ContentListUser } from '../permission/content-list-user'
import { ContentListUserAdd } from '../permission/content-list-user-add'
import { ContentListGroup } from '../permission/content-list-group'
import { ContentListGroupAdd } from '../permission/content-list-group-add'
import { ROLE_ORG } from '../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { Menu, MenuDelete, MenuDetails, MenuItem } from '../../../../shared/menu/menu'
import { Card } from '../../../../shared/card/card'
import { IconUsers, IconMove, IconCopy, IconPlus, IconGroup, IconEdit } from '../../../../shared/icon/icon'

export const CourseEdit = ({ parentUrl }) => {
  const { nodeID } = useParamsInt()

  const { pathname } = useLocation()
  const { url } = useRouteMatch()

  const gridContext = useContext(GridContext)

  const [nodeCourseFormActions, nodeCourseFormState] = useNodeCourseForm()
  const [assetListActions, assetListState] = useAssetList()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)
  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)
  const roleTemplate = useRoleAccessOrganization(ROLE_ORG.TEMPLATE)

  const handlePath = async () => {
    await nodeCourseFormActions.get(nodeID)
    assetListActions.get(`${nodeID}/node`)
  }

  const title = useMemo(() => nodeCourseFormState.form.name, [nodeCourseFormState.form])
  const isCaffeine = useMetadataType(nodeCourseFormState.form, 'CourseAssemblyName', 'caffeine')
  const thumbnail = useGetByDataType('thumbnail', assetListState.items)

  const handleSubmit = async node => await nodeCourseFormActions.put(node)

  const handleImageChange = () => gridContext.actions.loadThumbnails()

  const Header = () =>
    <Menu>
      <MenuDetails to={`${parentUrl}`} show={roleContent.accessRead} />
      <MenuItem Icon={IconUsers} text="user permissions" to={`${pathname}/content-permission/${nodeID}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${pathname}/group-permission/${nodeID}`} show={roleNodeGroup.accessRead} />

      <MenuItem
        Icon={IconPlus}
        text="new template"
        to={`${parentUrl}/template/${nodeID}`}
        disabled={!isCaffeine}
        show={roleTemplate.accessCreate}
      />

      <MenuItem Icon={IconMove} text="move" to={`${parentUrl}/move/${nodeID}`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${parentUrl}/copy/${nodeID}`} disabled={!isCaffeine} show={roleContent.accessUpdate} />

      <MenuDelete to={`${parentUrl}/delete/${nodeID}`} show={roleContent.accessDelete} />
    </Menu>

  const [courseAccessID, updateCourseAccessID] = useState(undefined)
  const [couserMetadata, updateCourseMetadata] = useState(undefined)

  useEffect(
    () => {
      updateCourseAccessID(nodeCourseFormState.form.accessID || '')
      updateCourseMetadata(nodeCourseFormState.form.metaData)
    },
    [nodeCourseFormState.form]
  )

  const handleCourseAccess = value => updateCourseAccessID(value || '')
  const handleCourseMetadata = value => updateCourseMetadata(value)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit" onPath={handlePath}>
        <Card title={title} HeaderMenu={Header}>
          <Card>
            <Form state={nodeCourseFormState.form} onSubmit={handleSubmit}>
              <FormHiddenNumber name="accessID" value={courseAccessID} shouldDirty />
              <FormHidden name="metaData" value={couserMetadata} shouldDirty />
              <FormText label="name" name="name" />

              <SelectCourseAssess label="access" value={courseAccessID} onChange={handleCourseAccess} />
              <SelectCourseMetadata value={couserMetadata} onChange={handleCourseMetadata} />

              <FormSubmit text="update" />
            </Form>
          </Card>

          <ThumbnailImageEdit nodeID={nodeID} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/content-permission/:nodeID`}>
        <ContentListUser />
      </Route>

      <Route path={`${url}/content-permission/:nodeID/add`}>
        <ContentListUserAdd />
      </Route>

      <Route path={`${url}/group-permission/:nodeID`}>
        <ContentListGroup />
      </Route>

      <Route path={`${url}/group-permission/:nodeID/add`}>
        <ContentListGroupAdd />
      </Route>
    </React.Fragment>
  )
}
