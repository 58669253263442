import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconCopy, IconEdit, IconFile } from '../../../../../../shared/icon/icon'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useMetadataType } from '../../common/hooks/hooks'
import { Menu, MenuDelete, MenuEdit, MenuItem } from '../../../../../../shared/menu/menu'
import { useApiFormTemplate, useApiListAsset } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useRoleAccessOrganization, useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_ORG, ROLE_SYS } from '../../../../../../role/role.const'
import { useDataContext } from '../../data-context'
import { FormRawJson } from '../../../../../../shared/form/form-control/form-raw-json'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const NodeTemplateEditSys = ({ parentURL, nodeID }) => {
  const apiFormTemplate = useApiFormTemplate()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormTemplate.get(nodeID)
      apiListAsset.get(`${nodeID}/node`)
    },
    [nodeID]
  )

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNode = useRoleAccessSystem(ROLE_SYS.NODE)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])
  const isCaffeine = useMetadataType(apiFormTemplate.form, 'CourseAssemblyName', 'caffeine')

  const handleSubmit = async data => {
    let node = await apiFormTemplate.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={roleNode.accessRead} pin />
      <MenuEdit to={`${parentURL}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem Icon={IconCopy} text="copy" to={`${parentURL}/copy`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="sys admin edit">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form state={apiFormTemplate.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required />
            <FormRawJson label="metadata" name="metaData" />
            <FormSubmit text="update" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
