import React, { useEffect, useState } from 'react'
import { SearchListGroup } from '../../../../../../shared/legacy/dropdown-search/search-list-group'
import { useGroupList } from '../../../../../../api/state'
import { Card } from '../../../../../../shared/card/card'
import { IconClickDelete } from '../../../../../../shared/icon/icon-click'

export function GroupList({ groups, onUpdate, disabled }) {
  const [groupListActions, grouplistState] = useGroupList()

  const [selectedGroup, updateSelectedGroup] = useState()

  useEffect(() => {
    groupListActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = group => updateSelectedGroup(group)

  const handleAdd = group => {
    if (groups.some(i => i.id === group.id)) return
    onUpdate && onUpdate([...groups, group])
    updateSelectedGroup(null)
  }

  const handleDelete = i => {
    let list = [...groups]
    list.splice(i, 1)
    onUpdate && onUpdate(list)
  }

  return (
    <React.Fragment>
      <Card title="Groups">
        <SearchListGroup
          selectText="select group"
          actionText="add"
          items={grouplistState.items}
          selectedItem={selectedGroup}
          disabled={disabled}
          onSelect={handleSelect}
          onAdd={handleAdd}
        />
        <table className="table table-borderless">
          <tbody>
            {groups &&
              groups.map((group, i) =>
                <tr key={i}>
                  <td>
                    {group.name}
                  </td>
                  <td>
                    <IconClickDelete className="float-right" disabled={disabled} onClick={() => handleDelete(i)} />
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </Card>
    </React.Fragment>
  )
}
