import React, { useEffect, useState } from 'react'
import { useAssetMetaQueryList, useNodeTemplateList } from '../../../../api/state'

export const GridContext = React.createContext()

export const GridProvider = ({ children }) => {
  // const [search, updateSearch] = useState('')
  const [filter, updateFilter] = useState('')

  // const handleSearchUpdate = value => updateSearch(value)
  // const handleSearchClear = () => updateSearch('')

  const [nodeTemplateListActions] = useNodeTemplateList()
  const [assetMetaQueryListActions] = useAssetMetaQueryList()

  const [templates, updateTemplates] = useState([])
  const [thumbnails, updateThumbnails] = useState([])

  useEffect(() => {
    loadTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadThumbnails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadTemplates = async () => updateTemplates(await nodeTemplateListActions.get())

  const loadThumbnails = async () => {
    updateThumbnails(await assetMetaQueryListActions.get('datatype:thumbnail'))
  }

  const setFilter = value => updateFilter(value)
  const clearFilter = () => updateFilter('')

  return (
    <GridContext.Provider
      value={{
        filter,
        templates,
        thumbnails,
        actions: { loadTemplates, loadThumbnails, setFilter, clearFilter }
      }}>
      {children}
    </GridContext.Provider>
  )
}
