import React from 'react'
import { Route } from 'react-router-dom'
import { SysAdminList } from './content/sys-admin-list'
import { SysAdminPromote } from './content/sys-admin-promote'
import { SysAdminOrgs } from './content/sys-admin-orgs'
import { SysAdminEdit } from './content/sys-admin-edit'

export function LayoutSysAdmin() {
  return (
    <React.Fragment>
      <Route path="/root/sys-admin/list" component={SysAdminList} />
      <Route path="/root/sys-admin/list/edit/:loginID" component={SysAdminEdit} />
      <Route path="/root/sys-admin/list/promote" component={SysAdminPromote} />
      <Route path="/root/sys-admin/list/edit/:loginID/orgs" component={SysAdminOrgs} />
    </React.Fragment>
  )
}
