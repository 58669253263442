import React, {  useMemo, useState } from 'react'
import { Progress } from '../progress'
import { FileSelect } from '../file/file-select'
import { useFileChunkForm } from './state'
import { Card } from '../../card/card'
import { useLoad } from '../../hooks/load'
import { ButtonPrimary } from '../../component/button'
import { Menu, MenuItem } from '../../menu/menu'
import { useAssetDownload } from './hooks'

let chunkSize = 1024 * 1024 * 5

export const StorageFileChunk = ({ storageKey, onComplete }) => {
  const [file, updateFile] = useState(null)
  const [fileProgress, updateFileProgress] = useState(0)
  const [uploadState, updateUploadState] = useState(null)

  const [errMsg, updateErrMsg] = useState('')

  const [fileChunkFormActions, fileChunkFormState] = useFileChunkForm()

  const fileSelected = useMemo(() => !!file, [file])
  const fileSize = useMemo(() => (file ? file.size : 0), [file])
  const fileName = useMemo(() => (file ? file.name : ''), [file])

  useLoad(
    async () => {

      if (storageKey) {
        try {
          await fileChunkFormActions.get(`${storageKey}/details`)
          updateErrMsg('')
        } catch {
          updateErrMsg('asset file not found')
        } 
      }      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageKey]
  )

  const handleSelect = async file => {
    updateFile(file)
    updateUploadState('pending')
  }

  const handleUpload = async () => {
    updateUploadState('uploading')

    let result = await fileChunkFormActions.postArgs({ path: storageKey, toast: null })

    let chunks = Math.floor(file.size / chunkSize)
    let remainder = file.size % chunkSize

    for (let i = 0; i < chunks; i++) {
      let start = i * chunkSize
      let end = start + chunkSize
      let blob = file.slice(start, end)
      await fileChunkFormActions.putArgs({ path: `${result.identifier}/${i + 1}`, data: blob, toast: null })
      updateFileProgress(end)
    }

    let start = chunks * chunkSize
    let end = start + remainder

    let blob = file.slice(start, end)

    await fileChunkFormActions.putArgs({ path: `${result.identifier}/${chunks + 1}`, data: blob, toast: null })
    updateFileProgress(end)
    await fileChunkFormActions.putArgs({ path: result.identifier, toast: 'upload complete' })
    await fileChunkFormActions.get(`${storageKey}/details`)
    updateUploadState('completed')
    onComplete && onComplete(file)
  }

  const handleCancel = async () => {
    updateFile(null)
    updateFileProgress(0)
    updateUploadState(null)
    await fileChunkFormActions.delete(`${storageKey}`)
  }

  const handleClose = () => {
    updateFile(null)
    updateFileProgress(0)
    updateUploadState(null)
  }


  const assetDownload = useAssetDownload()

  const handleDownload = () => assetDownload(fileChunkFormState.form.key)

  const HeaderMenu = () => 
    <Menu small>
      <MenuItem text='download' show={fileChunkFormState.form.key} onClick={handleDownload} />
    </Menu>

  return (
    <React.Fragment>
      <Card title="file storage" HeaderMenu={HeaderMenu}>
        <table className="table table-responsive-sm">
          <tbody>
          {errMsg && <tr>
              <td>Error</td>
              <td>
                {errMsg}
              </td>
            </tr>}
            <tr>
              <td>Key</td>
              <td>
                {fileChunkFormState.form.key}
              </td>
            </tr>
            <tr>
              <td>Content Length</td>
              <td>
                {fileChunkFormState.form.contentLength}
              </td>
            </tr>
            <tr>
              <td>Content Type</td>
              <td>
                {fileChunkFormState.form.contentType}
              </td>
            </tr>
            <tr>
              <td>Last Modified</td>
              <td>
                {fileChunkFormState.form.lastModified}
              </td>
            </tr>
          </tbody>
        </table>

        {fileSelected &&
          <table className="table table-borderless table-responsive-sm border">
            <tbody>
              <tr>
                <td>File Name</td>
                <td className="w-75">
                  {fileName}
                </td>
              </tr>
              <tr>
                <td>Progress</td>
                <td>
                  <Progress current={fileProgress} length={fileSize} />
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td className="text-capitalize">
                  {uploadState}
                </td>
              </tr>
              {uploadState === 'pending' &&
                <tr>
                  <td />
                  <td>
                    <ButtonPrimary text="upload" onClick={handleUpload} />
                  </td>
                </tr>}
              {uploadState === 'uploading' &&
                <tr>
                  <td />
                  <td>
                    <ButtonPrimary text="cancel" onClick={handleCancel} />
                  </td>
                </tr>}
              {uploadState === 'completed' &&
                <tr>
                  <td />
                  <td>
                    <ButtonPrimary text="close" onClick={handleClose} />
                  </td>
                </tr>}
            </tbody>
          </table>}

        {!fileSelected && <FileSelect onSelect={handleSelect} />}
      </Card>
    </React.Fragment>
  )
}