import { useMemo } from 'react'

export const useActiveNodes = (parentID, filter, nodes) => {
  return useMemo(
    () => {
      if (!nodes) {
        return []
      }

      let result = parentID ? nodes.filter(i => i.parentID === parentID) : nodes.filter(i => i.parentID === null)

      if (filter) {
        let lowerFilter = filter.toLocaleLowerCase()
        result = result.filter(i => i.name.toLowerCase().includes(lowerFilter))
      }

      return result
    },
    [parentID, filter, nodes]
  )
}

// export const useActiveNodes = (parentID, filter, nodes) => {
//   return useMemo(
//     () => {
//       if (!nodes) {
//         return []
//       } else if (!parentID && filter) {
//         //root level with filter
//         let lowerFilter = filter.toLocaleLowerCase()
//         return nodes.filter(i => i.name.toLowerCase().includes(lowerFilter))
//       } else if (parentID && filter) {
//         //child level with filter
//         let result = nodes.filter(i => i.parentID === parentID)
//         let lowerFilter = filter.toLocaleLowerCase()
//         return result.filter(i => i.name.toLowerCase().includes(lowerFilter))
//       } else if (!parentID) {
//         //root level no filter
//         return nodes.filter(i => i.parentID === null)
//       } else if (parentID) {
//         //child level no filter
//         return nodes.filter(i => i.parentID === parentID)
//       } else {
//         return []
//       }
//     },
//     [parentID, filter, nodes]
//   )
// }

export const useFindNode = (nodeID, nodes) => {
  return useMemo(() => nodes.find(node => node.id === nodeID) || {}, [nodeID, nodes])
}

export const useNodeTitle = (root, parentID, node) => {
  return useMemo(
    () => {
      if (!parentID) {
        return root
      }

      if (node) {
        return node.name
      }

      return null
    },
    [root, parentID, node]
  )
}

export const useMetadataType = (item, type, value) => {
  return useMemo(
    () => {
      if (item && item.metaData) {
        let metaData = item.metaData.toLowerCase()
        metaData = JSON.parse(metaData)
        let lowerType = type.toLowerCase()
        if (metaData[lowerType]) {
          return metaData[lowerType].includes(value.toLowerCase())
        }

        return false
      }
    },
    [item, type, value]
  )
}

export const useGetMetadataValue = (item, key) => {
  return useMemo(
    () => {
      if (item && item.metaData) {
        try {
          let metaData = item.metaData.toLowerCase()
        metaData = JSON.parse(metaData)
        return metaData[key]
        } catch {
          return null
        }
      }
    },
    [item, key]
  )
}
