import React from 'react'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiListNodeUser } from '../../../../../../api-new/state/content'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { IconUser } from '../../../../../../shared/icon/icon'

export const NodeUserPermission = ({ node }) => {
  const { url } = useRouteMatch()

  const apiListNodeUser = useApiListNodeUser()

  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)

  const handleReady = () => apiListNodeUser.get(`${node.id}/node`)

  const handleDelete = async item => {
    await apiListNodeUser.delete(`${item.nodeID}/${item.userID}`)
    await apiListNodeUser.get(`${node.id}/node`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`${url}/add`} show={roleNodeUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUser} text="user permissions">
        <CardFullHeightScrollY title="groups" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListNodeUser} onReady={handleReady}>
            <Column field="email" header="Email" />
            <Column field="firstName" header="First Name" />
            <Column field="lastName" header="Last Name" />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleNodeUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
