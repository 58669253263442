import React, { useContext, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { useMetadataType } from '../../common/hooks/hooks'
import { useLoad } from '../../../../../../shared/hooks/load'
import { AssetCopyProgress, useAssetCopy } from '../../../../../../shared/component/asset-copy'
import { buildCoursePath, buildNodeLocation } from '../../common/shared/node-path'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { IconCopy } from '../../../../../../shared/icon/icon'
import { DisplaySysAdmin } from '../../../../../../role/role.component'
import { FormSelectOrgaization } from '../../../../../../shared/form/form-control/form-select-organization'
import { FormSelectCategory } from '../../../../../../shared/form/form-control/form-select-category'
import { useDataContext } from '../../data-context'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { Modal } from '../../../../../../shared/component/modal'

export const NodeCourseCopy = ({ nodeID, parentURL }) => {
  const history = useHistory()

  const refModal = useRef()

  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormNodeCourseCopyOrg = useApiFormNodeCourse('/copy-org')
  const apiFormNodeCourseCopy = useApiFormNodeCourse('/copy')

  const [organizationID, updateOrganizationID] = useState()
  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  const title = useMemo(() => `copy ${apiFormNodeCourse.form.name}`, [apiFormNodeCourse.form.name])
  const isCaffeine = useMetadataType(apiFormNodeCourse.form, 'CourseAssemblyName', 'caffeine')

  useLoad(
    () => {
      authContext.state.jwtClaims.organizationID && updateOrganizationID(authContext.state.jwtClaims.organizationID)
      authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID)
    },
    [authContext.state.jwtClaims.organizationID]
  )

  const handleMatch = () => apiFormNodeCourse.get(nodeID)

  const handleOrganization = organizationID => updateCategoryOrganizationID(organizationID)

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState()

  const onCopyComplete = nodeID => {
    if (nodeID) {
      updateCopiedNodeID(nodeID)
      const destLocation = buildNodeLocation(dataContext.nodes, nodeID)
      updateDestLocation(destLocation)
    }

    refModal.current.show()
  }

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let node = authContext.state.isSysAdmin ? await apiFormNodeCourseCopyOrg.post(data) : await apiFormNodeCourseCopy.post(data)

    if (authContext.state.jwtClaims.organizationID === node.organizationID) {
      await init(node.id)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })

      dataContext.nodeInsert(node)
    } else {
      await init(null)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })
    }
  }

  const handleConfirm = () => {
    refModal.current.hide()

    if (copiedNodeID) {
      let path = buildCoursePath(dataContext.nodes, copiedNodeID)
      history.push(path)
    } else {
      history.push(parentURL)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconCopy} text="copy course" onMatch={handleMatch}>
        <Modal ref={refModal} title={title} confirmText="ok" backdropStatic onConfirm={handleConfirm}>
          <p>
            {apiFormNodeCourse.form.name} has successfully been copied {destLocation && `to ${destLocation}`}
          </p>
        </Modal>

        <Card title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

                  <DisplaySysAdmin>
                    <FormSelectOrgaization
                      lable="organization"
                      name="organizationID"
                      organizationID={organizationID}
                      required
                      onChange={handleOrganization}
                    />
                  </DisplaySysAdmin>

                  <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />

                  <FormText label="name" name="name" defaultValue={apiFormNodeCourse.form.name} required />

                  <FormSubmitBack text="copy course" />
                </Form>

                <AssetCopyProgress content={content} />
              </React.Fragment>
            : <div>Action not allowed for course</div>}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
