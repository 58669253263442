import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { Menu, MenuAdd, MenuDelete } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { IconGroup } from '../../../../../../shared/icon/icon'

export const UserGroupList = () => {
  const { userID } = useParamsInt()

  const apiListGroupUser = useApiListGroupUser()

  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const handleReady = async () => await apiListGroupUser.get(`${userID}/user`)

  const handleDelete = async item => {
    await apiListGroupUser.delete(`${item.groupID}/${item.userID}`)
    await apiListGroupUser.get(`${userID}/user`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add group" to={`/org/user/list/edit/${userID}/group/add`} show={roleGroupUser.accessCreate} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconGroup} text="groups">
        <CardFullHeightScrollY title="user groups" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListGroupUser} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDelete onClick={() => handleDelete(row)} show={roleGroupUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
