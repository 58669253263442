import React from 'react'
import { useAssetForm } from '../state'
import { useParams } from 'react-router'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconAdd } from '../../../shared/icon/icon'
import { Form, FormText, FormSubmitBack, FormHiddenNumber } from '../../../shared/form/form'
import { Card } from '../../../shared/card/card'

export const AssetAdd = ({ onAdd }) => {
  const { nodeID } = useParams()

  const [assetFormActions, assetFormState] = useAssetForm()

  const handleSubmit = async data => {
    try {
      let asset = await assetFormActions.post(data)
      onAdd && onAdd(asset.id)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="New Asset">
        <Card title="Asset">
          <Form state={assetFormState.form} submitting={assetFormState.submitting} onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} parseInt />
            <FormText label="name" name="name" autoFocus />
            <FormSubmitBack text="add asset" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
