import React, { useMemo } from 'react'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Route, useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { NodeCourseDetails } from './pages/node-course-details'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuItem,
  MenuItemHeader,
  MenuRecycle
} from '../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../role/role.const'
import { IconCategory, IconContent, IconFile, IconGroup, IconMove, IconUsers } from '../../../../../shared/icon/icon'
import { NodeCategoryGridItem } from '../common/category/node-category-grid-item'
import { NodeCourseGridItem } from './node-course-grid-item'
import { NodeCategoryAdd } from '../common/category/pages/node-category-add'
import { Purge } from '../../../../../shared/page/page-purge'
import { CourseFilterList } from './course-filter-list'
import { useDataContext } from '../data-context'
import { FilterInput, useFilterList } from '../common/filter/filter-input'
import { NodeRecycle } from '../common/recycle/node-recycle'
import { NodeCategoryEdit } from '../common/category/pages/node-category-edit'
import { NodeCategoryDelete } from '../common/category/pages/node-category-delete'
import { NodeUserPermission } from '../common/permission/node-user-permission'
import { NodeUserPermissionAdd } from '../common/permission/node-user-permission-add'
import { NodeGroupPermission } from '../common/permission/node-group-permission'
import { NodeGroupPermissionAdd } from '../common/permission/node-group-permission-add'
import { LogEventOrgSubjectID } from '../../../../../shared/page/log-event/log-event-org'
import { isDatatypeCategory, isDatatypeCourse, isDatatypeStreamingVideo } from '../common/shared/metadata'
import { DisplaySysAdmin, useDisplaySysAdmin } from '../../../../../role/role.component'
import { NodeAssetList } from '../common/assets/node-asset-list'
import { NodeCategoryEditSys } from '../common/category/pages/node-category-edit-sys'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'
import { NodeCategoryMove } from '../common/category/pages/node-category-move'

export const CourseGrid = () => {
  return (
    <React.Fragment>
      <div className="container-fluid p-0 h-100">
        <GridBranch />
      </div>
    </React.Fragment>
  )
}

const GridBranch = ({ grandParentURL }) => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.content.find(i => i.id === parentID), [dataContext.content, parentID])

  const children = useMemo(() => dataContext.content.filter(i => i.parentID === (parentID || null)), [dataContext.content, parentID])

  const nodeCategories = useMemo(() => children.filter(item => isDatatypeCategory(item)), [children])
  const nodeCourses = useMemo(() => children.filter(item => isDatatypeCourse(item) || isDatatypeStreamingVideo(item)), [children])

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  // const roleAssessmentManage = useRoleAccessOrganization(ROLE_ORG.ASSESSMENT_MANAGE)
  // const roleAnalytic = useRoleAccessOrganization(ROLE_ORG.ANALYTIC)

  const displaySysAdmin = useDisplaySysAdmin()

  const HeaderMenu = () =>
    <Menu>
      {parentID && <MenuItemHeader text="Sys Admin" show={displaySysAdmin} />}
      {parentID && <MenuEdit text="edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />}
      {parentID && <MenuItem Icon={IconFile} text="assets" to={`${url}/assets`} show={displaySysAdmin} />}

      {parentID && <MenuItemHeader text="Org" show={displaySysAdmin} />}
      <MenuAdd text="add category" to={`${url}/add`} show={roleContent.accessCreate} />
      {parentID && <MenuEdit text="edit" to={`${url}/edit`} show={true} pin={false} />}
      {parentID && <MenuItem Icon={IconUsers} text="user permissions" to={`${url}/user-permission`} show={roleContent.accessRead} />}
      {parentID && <MenuItem Icon={IconGroup} text="group permissions" to={`${url}/group-permission`} show={roleContent.accessRead} />}
      {parentID && <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />}
      {parentID && <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />}
      <MenuRecycle to={`${url}/recycle`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show />
    </Menu>

  const title = useMemo(
    () => {
      if (parentID) return itemCurrent ? itemCurrent.name : ''
      else return 'CONTENT'
    },
    [parentID, itemCurrent]
  )

  const Icon = useMemo(
    () => {
      if (parentID) return IconCategory
      else return IconContent
    },
    [parentID]
  )

  const [filter, updateFilter, showFilter, selectFilter] = useFilterList()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={Icon} text={title}>
        <CardFullHeight title={title} HeaderMenu={HeaderMenu}>
          <div className="d-flex flex-column h-100">
            <div className="m-2">
              <FilterInput value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill" style={{ minHeight: 0 }}>
              <div className="overflow-scroll h-100">
                {showFilter
                  ? <div>
                      <CourseFilterList nodes={dataContext.content} filter={filter} onSelect={selectFilter} />
                    </div>
                  : <div className="container-fluid">
                      <div className="row g-2">
                        {nodeCategories
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeCategoryGridItem key={i} node={node} />)}
                        {nodeCourses
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeCourseGridItem key={i} node={node} />)}
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </CardFullHeight>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <GridBranch grandParentURL={url} />
      </Route>

      <Route path={`${url}/course/:nodeID`}>
        <NodeCourseDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add`}>
        <NodeCategoryAdd parentURL={url} node={itemCurrent} />
      </Route>

      <Route path={`${url}/recycle/:purgeID/purge`}>
        <Purge useApiForm={useApiFormNode} titleParam="name" successPath={`${url}/recycle`} />
      </Route>

      {itemCurrent &&
        <React.Fragment>
          <Route path={`${url}/edit`}>
            <NodeCategoryEdit parentURL={url} node={itemCurrent} />
          </Route>

          <Route path={`${url}/move`}>
            <NodeCategoryMove node={itemCurrent} />
          </Route>

          <DisplaySysAdmin>
            <Route path={`${url}/edit-sys`}>
              <NodeCategoryEditSys parentURL={url} node={itemCurrent} />
            </Route>
          </DisplaySysAdmin>

          <Route path={`${url}/delete`}>
            <NodeCategoryDelete grandParentURL={grandParentURL} node={itemCurrent} />
          </Route>

          <Route path={`${url}/user-permission`}>
            <NodeUserPermission node={itemCurrent} />
          </Route>

          <Route path={`${url}/user-permission/add`}>
            <NodeUserPermissionAdd node={itemCurrent} />
          </Route>

          <Route path={`${url}/group-permission`}>
            <NodeGroupPermission node={itemCurrent} />
          </Route>

          <Route path={`${url}/group-permission/add`}>
            <NodeGroupPermissionAdd node={itemCurrent} />
          </Route>

          <DisplaySysAdmin>
            <Route path={`${url}/assets`}>
              <NodeAssetList parentURL={url} nodeID={parentID} />
            </Route>
          </DisplaySysAdmin>
        </React.Fragment>}

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={parentID} />
      </Route>

      <Route path={`${url}/recycle`}>
        <NodeRecycle nodeID={parentID} types={['category', 'course', 'streaming_video']} />
      </Route>
    </React.Fragment>
  )
}
