import React, { useContext, useMemo } from 'react'
import { AuthContext } from '../../context/auth.context'
import { RoleContext } from '../role.context'

export const RoleAccessDisplaySys = ({ children, type, accessCreate, accessRead, accessUpdate, accessDelete, accessPurge }) => {
  const authContent = useContext(AuthContext)
  const roleContext = useContext(RoleContext)

  let display = useMemo(
    () => {
      if (authContent.state.isSysRoot) {
        return true
      }

      if (!roleContext.sysRoles[type]) {
        return false
      }

      if (accessCreate) return roleContext.sysRoles[type].accessCreate
      else if (accessRead) return roleContext.sysRoles[type].accessRead
      else if (accessUpdate) return roleContext.sysRoles[type].accessUpdate
      else if (accessDelete) return roleContext.sysRoles[type].accessDelete
      else if (accessPurge) return roleContext.sysRoles[type].accessPurge
      else return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContent.state, roleContext.sysRoles]
  )

  return (
    <React.Fragment>
      {display && children}
    </React.Fragment>
  )
}

export const RoleAccessDisplayOrg = ({ children, type, accessCreate, accessRead, accessUpdate, accessDelete, accessPurge }) => {
  const authContent = useContext(AuthContext)
  const roleContext = useContext(RoleContext)

  let display = useMemo(
    () => {
      if (authContent.state.isSysRoot) {
        return true
      }

      if (authContent.state.isSysAdmin) {
        return true
      }

      if (!roleContext.orgRoles[type]) {
        return false
      }

      if (accessCreate) return roleContext.orgRoles[type].accessCreate
      else if (accessRead) return roleContext.orgRoles[type].accessRead
      else if (accessUpdate) return roleContext.orgRoles[type].accessUpdate
      else if (accessDelete) return roleContext.orgRoles[type].accessDelete
      else if (accessPurge) return roleContext.orgRoles[type].accessPurge
      else return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContent.state, roleContext.orgRoles]
  )

  return (
    <React.Fragment>
      {display && children}
    </React.Fragment>
  )
}
