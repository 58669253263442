import React from 'react'
import { Route } from 'react-router-dom'
import { LoginList } from './content/login-list'
import { LoginEdit } from './content/login-edit'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { LoginRecycle } from './content/login-recycle'
import { LoginAdd } from './content/login-add'
import { OrganizationUserEdit } from './content/organization/organization-user-edit'
import { LoginAuthDetails } from './content/login-auth/login-auth-details'
import { OrganizationUserAdd } from './content/organization/organization-user-add'
import { useApiFormLogin } from '../../../../api-new/state/content'
import { LoginOrganizationList } from './content/organization/login-organization-list'
import { LoginAuthList } from './content/login-auth/login-auth-list'

export const LayoutLogin = () => {
  return (
    <React.Fragment>
      <Route path="/admin/login/list" component={LoginList} />
      <Route path="/admin/login/list/add" component={LoginAdd} />
      <Route path="/admin/login/list/edit/:loginID" component={LoginEdit} />
      <Route path="/admin/login/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormLogin} titleParam="email" successPath="/admin/login/list" />
      </Route>
      <Route path="/admin/login/list/recycle" component={LoginRecycle} />
      <Route path="/admin/login/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormLogin} titleParam="email" successPath="/admin/login/list/recycle" />
      </Route>

      <Route path="/admin/login/list/edit/:loginID/organizations" component={LoginOrganizationList} />
      <Route path="/admin/login/list/edit/:loginID/organizations/user/:userID" component={OrganizationUserEdit} />
      <Route path="/admin/login/list/edit/:loginID/organizations/add" component={OrganizationUserAdd} />

      <Route path="/admin/login/list/edit/:loginID/auths" component={LoginAuthList} />
      <Route path="/admin/login/list/edit/:loginID/auths/details/:refreshToken" component={LoginAuthDetails} />
    </React.Fragment>
  )
}
