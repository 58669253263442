export const useBuildRoutePath = () => {
  return (path, args) => {
    let hold = new URL(path, process.env.REACT_APP_SITE)

    if (args.email) {
      hold.searchParams.append('email', args.email)
    }

    if (args.redirect) {
      hold.searchParams.append('redirect', args.redirect)
    }

    if (args.redirectUrl) {
      hold.searchParams.append('redirect-url', args.redirectUrl)
    }

    return `${hold.pathname}${hold.search}`
  }
}
