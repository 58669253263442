import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { IdentifierDetails, RoleAccessDetails, RoleAccessOption } from './common/components'
import { useIdentifier } from './common/hook'
import { Card } from '../../../../../shared/card/card'
import { useApiFormRoleAccess, useApiListRole, useApiListRoleScope } from '../../../../../api-new/state/content'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

export const RoleAccessAdd = () => {
  const history = useHistory()

  const formRoleAccess = useApiFormRoleAccess()
  const apiListRole = useApiListRole()
  const apiListRoleScope = useApiListRoleScope()

  const handlePath = async () => await apiListRole.get()

  const handleRole = async id => {
    let role = apiListRole.items.find(i => i.id === id)
    if (role) {
      await apiListRoleScope.get(role.scopeType)
    }
  }

  const handleSubmit = async data => {
    let roleAccess = await formRoleAccess.post(data)
    history.push(`/root/role-access/list/edit/${roleAccess.id}`)
  }

  const [identifier, updateIdentifier] = useIdentifier(apiListRoleScope.items)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onPath={handlePath}>
        <Card title="add role permission">
          <Form onSubmit={handleSubmit}>
            <FormSelectObj
              label="Role"
              name="roleID"
              state={apiListRole}
              itemName="role"
              itemValue="id"
              valueAsNumber={true}
              required
              onChange={handleRole}
            />

            <FormSelectObj
              label="Endpoint"
              name="identifier"
              state={apiListRoleScope}
              itemName="display"
              itemValue="identifier"
              required
              onChange={updateIdentifier}
            />

            <IdentifierDetails identifier={identifier} />
            <RoleAccessDetails identifier={identifier} />
            <RoleAccessOption identifier={identifier} />

            <FormSubmitBack text="add role access" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
