import { useCallback, useMemo } from 'react'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { ThumbnailImage } from '../common/shared/thumbnail-image'
import { IconCopy, IconFile, IconTemplate } from '../../../../../shared/icon/icon'
import { GridItem } from '../common/shared/grid-item'
import { Menu, MenuActivityLog, MenuAdd, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../role/role.const'
import { useDataContext } from '../data-context'
import { useDisplaySysAdmin } from '../../../../../role/role.component'

export const TemplateGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const dataContext = useDataContext()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const type = useMemo(() => (node ? JSON.parse(node.metaData.toLowerCase()).datatype : null), [node])

  const to = useMemo(
    () => {
      if (type === 'course_template') {
        return `${url}/template/${node.id}/edit`
      }
    },
    [url, type, node]
  )

  const Thumbnail = useCallback(() => <ThumbnailImage nodeID={node.id} assets={dataContext.assets} cache={dataContext.thumbnailCache} />, [
    node,
    dataContext.assets,
    dataContext.thumbnailCache
  ])

  const displaySysAdmin = useDisplaySysAdmin()

  const FooterMenu = () =>
    <div>
      <Menu small>
        <MenuItemHeader text="sys admin" show={displaySysAdmin} />
        <MenuEdit text="sys edit" to={`${url}/template/${node.id}/edit-sys`} show={displaySysAdmin} pin={false} />
        <MenuItem Icon={IconFile} text="assets" to={`${url}/template/${node.id}/assets`} show={displaySysAdmin} />

        <MenuItemHeader text="org" show={displaySysAdmin} />
        <MenuEdit to={`${url}/template/${node.id}/edit`} show={roleContent.accessUpdate} pin={false} />
        <MenuAdd text="new from template" to={`${url}/template/${node.id}/generate`} show={roleContent.accessCreate} pin={false} />
        <MenuItem Icon={IconCopy} text="copy" to={`${url}/template/${node.id}/copy`} show={roleContent.accessCreate} />
        <MenuDelete to={`${url}/template/${node.id}/delete`} show={roleContent.accessDelete} />
        <MenuActivityLog to={`${url}/template/${node.id}/activity`} show={true} />
      </Menu>
    </div>

  return <GridItem Thumbnail={Thumbnail} title={node.name} Icon={IconTemplate} FooterMenu={FooterMenu} to={to} />
}
