import React, { useEffect } from 'react'
import { useRoleSyncUserOrgList } from '../../api/state'
import { Card } from '../../shared/card/card'
import { SelectTag } from '../../shared/select-tag/select-tag'

export const RoleSelectUserOrg = ({ userID }) => {
  const [roleSyncUserOrgActions, roleSyncUserOrgState] = useRoleSyncUserOrgList()

  useEffect(
    () => {
      roleSyncUserOrgActions.get(userID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  )

  const handleAdd = async item => {
    await roleSyncUserOrgActions.post(item)
    await roleSyncUserOrgActions.get(userID)
  }

  const handleRemove = async item => {
    await roleSyncUserOrgActions.delete(`${item.roleID}/${item.userID}`)
    await roleSyncUserOrgActions.get(userID)
  }

  return (
    <React.Fragment>
      <Card title="User Roles">
        <SelectTag
          data={roleSyncUserOrgState.items}
          fnLabel={item => item.role}
          fnActive={item => item.active}
          fnReadOnly={item => item.isReadOnly}
          fnHidden={item => item.isReadOnly}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </Card>
    </React.Fragment>
  )
}
