import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useApiFormAsset, useApiListAsset } from '../../../../../../api-new/state/content'
import { Menu } from '../../../../../../shared/menu/menu'
import { MenuLink } from '../../../../../../view/node-new/shared/menu'
import { IconAdd, IconList, IconRecycle } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { FormatDate, FormatSize } from '../../../../../../shared/legacy/format'
import { IconLinkDelete, IconLinkEdit } from '../../../../../../shared/icon/icon-link'
import { NodeAssetDetails } from './node-asset-details'
import { NodeAssetAdd } from './node-asset-add'
import { AssetRecycle } from './asset-recycle'
import { Purge } from '../../../../../../shared/page/page-purge'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconClickDownload } from '../../../../../../shared/icon/icon-click'
import { useAssetDownload } from '../../../../../../shared/legacy/storage/hooks'

export const NodeAssetList = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiListAsset = useApiListAsset()

  const handleMatch = async () => apiListAsset.get(`${nodeID}/node`)

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconAdd} text="new asset" to={`${url}/add`} pin show={true} />
      <MenuLink Icon={IconRecycle} text="recycle" to={`${url}/recycle`} show={true} />
    </Menu>

  const assetDownload = useAssetDownload()

  const handleDownload = assetKey => assetDownload(assetKey)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="assets" onMatch={handleMatch}>
        <CardFullHeightScrollY title="assets" HeaderMenu={HeaderMenu}>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Length</th>
                <th>Created</th>
                <th>Updated</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {apiListAsset.items.map((item, i) =>
                <tr key={i}>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    <FormatSize value={item.length} />
                  </td>
                  <td>
                    <FormatDate date={item.createdAt} />
                  </td>
                  <td>
                    <FormatDate date={item.updatedAt} />
                  </td>
                  <td className="text-end text-nowrap">
                    <IconClickDownload onClick={() => handleDownload(item.key)} />
                    <IconLinkEdit to={`${url}/details/${item.id}/edit`} />
                    <IconLinkDelete to={`${url}/details/${item.id}/delete`} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/details/:assetID`}>
        <NodeAssetDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add`}>
        <NodeAssetAdd parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/recycle`}>
        <AssetRecycle parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/recycle/:purgeID/purge`}>
        <Purge useApiForm={useApiFormAsset} titleParam="name" successPath={`${url}/recycle`} />
      </Route>
    </React.Fragment>
  )
}
