import React, { useMemo } from 'react'
import { useAssetForm } from '../../../api/state'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { Form, FormText, FormSubmit } from '../../../shared/form/form'
import { useParamsInt } from '../../../shared/hooks/location'
import { StorageFileChunk } from '../../../shared/legacy/storage/storage-file-chunk'
import { Menu, MenuLink } from '../shared/menu'
import { Card } from '../../../shared/card/card'
import { IconDelete, IconEdit, IconPlus } from '../../../shared/icon/icon'

export const NodeAssetEdit = ({ parentUrl }) => {
  const { assetID } = useParamsInt()

  const [assetFormActions, assetFormState] = useAssetForm()

  const title = useMemo(() => `edit ${assetFormState.form.name}`, [assetFormState.form.name])

  const handleMatch = () => assetFormActions.get(assetID)

  const handleSubmit = async data => await assetFormActions.put(data)

  const handleComplete = async file => {
    let data = assetFormState.form
    data.length = file.size
    await assetFormActions.put(data)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconPlus} text="new asset" to={`${parentUrl}/asset-add`} />
      <MenuLink Icon={IconDelete} text="delete asset" to={`${parentUrl}/asset-delete/${assetID}`} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onMatch={handleMatch}>
        <Card title={title} HeaderMenu={HeaderMenu}>
          <Form state={assetFormState.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required autoFocus />
            <FormSubmit text="edit asset" />
          </Form>
        </Card>
        <StorageFileChunk storageKey={assetFormState.form.key} onComplete={handleComplete} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
