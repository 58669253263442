import React from 'react'

export const ExampleUserImportCSV = () => {
  return (
    <React.Fragment>
      <table className="table table-borderless">
        <thead>
          <tr className="border-bottom">
            <th>email</th>
            <th>first</th>
            <th>last</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-bottom">
            <td>email1@example.com</td>
            <td>first1</td>
            <td>last1</td>
          </tr>
          <tr className="border-bottom">
            <td>email2@example.com</td>
            <td>first2</td>
            <td>last2</td>
          </tr>
          <tr className="border-bottom">
            <td>email3@example.com</td>
            <td>first3</td>
            <td>last3</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}
