import React from 'react'
import { IconTimes } from '../../../shared/icon/icon'

export const InputFilter = ({ placeholder, value, onChange, onEnter, onClear }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handleKeyUp = e => e.code === 'Enter' && onEnter && onEnter(e.target.value)
  const handlerClear = () => onClear && onClear()

  return (
    <React.Fragment>
      <div className="input-group">
        <input className="form-control" value={value} onChange={handleChange} onKeyUp={handleKeyUp} placeholder={placeholder} />
        <span className="input-group-text edxr-pointer" onClick={handlerClear}>
          <IconTimes />
        </span>
      </div>
    </React.Fragment>
  )
}
