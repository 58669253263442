import { createContext, useContext, useMemo } from 'react'
import { useApiListContent, useApiListContentAsset } from '../../../../api-new/state/content'
import { useLoad } from '../../../../shared/hooks/load'
import { Cache } from './common/shared/cache'
import { useItemState } from './common/hooks/item-state'
import { getDatatype } from './common/shared/metadata'

const DataContext = createContext()

export const useDataContext = () => useContext(DataContext)

export const DataProvider = ({ children }) => {
  const apiListContent = useApiListContent()
  const apiListContentAsset = useApiListContentAsset()

  const { state, insert, update, remove, removeID, subscribe, unsubscribe } = useItemState(apiListContent.items)

  useLoad(() => {
    apiListContent.get()
    apiListContentAsset.get()
  }, [])

  const content = useMemo(
    () => {
      return state.filter(node => {
        let datatype = getDatatype(node)
        return datatype === 'course' || datatype === 'category' || datatype === 'streaming_video'
      })
    },
    [state]
  )

  const template = useMemo(
    () => {
      return state.filter(node => {
        let datatype = getDatatype(node)
        return datatype === 'course_template'
      })
    },
    [state]
  )

  const thumbnailCache = useMemo(() => new Cache(), [])

  return (
    <DataContext.Provider
      value={{
        nodes: state,
        content: content,
        template: template,
        assets: apiListContentAsset.items,
        thumbnailCache: thumbnailCache,

        subscribe: listener => subscribe(listener),
        unsubscribe: listener => unsubscribe(listener),

        nodeInsert: node => insert(node),
        nodeUpdate: node => update(node),
        nodeRemove: node => remove(node),
        nodeRemoveID: id => removeID(id)
      }}>
      {children}
    </DataContext.Provider>
  )
}
