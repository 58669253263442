import React from 'react'

export const CardHeader = ({ title, subtitle, HeaderComponent, HeaderMenu }) => {
  return (
    <React.Fragment>
      {(title || HeaderComponent || HeaderMenu) &&
        <div className="border-bottom">
          <div className="d-flex flex-row flex-wrap">
            {title &&
              <div className="card-title flex-fill">
                <h4 className="text-capitalize">
                  {title}
                </h4>
              </div>}
            {HeaderComponent &&
              <div className="card-title">
                <HeaderComponent />
              </div>}
            {HeaderMenu &&
              <div className="card-title">
                <HeaderMenu />
              </div>}
          </div>

          {subtitle &&
            <h6 className="card-subtitle mb-2 text-body-secondary">
              {subtitle}
            </h6>}
        </div>}
    </React.Fragment>
  )
}
