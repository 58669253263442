import React, { useMemo, useState } from 'react'
import { Draggable, DragHandle, Droppable } from '../../../shared/legacy/drag-drop/drag-drop'
import { useFormDisable } from '../../../shared/form/form'
import { FormatLabel } from '../../../shared/form/format-label'
import { IconGrip } from '../../../shared/icon/icon'
import { IconClickAdd, IconClickDelete } from '../../../shared/icon/icon-click'

const ListItems = ({ items, disable, onChange }) => {
  const [newItem, updateNewItem] = useState('')

  let itemArray = useMemo(() => items || [], [items])

  const handleDropList = (dragIndex, dropIndex) => {
    let hold = itemArray[dragIndex]
    itemArray.splice(dragIndex, 1)
    itemArray.splice(dropIndex, 0, hold)
    onChange(itemArray)
  }

  const handleChangeList = (value, i) => {
    itemArray[i] = value
    onChange(itemArray)
  }

  const handleDeleteList = i => {
    itemArray.splice(i, 1)
    onChange(itemArray)
  }

  const handleChangeNewItem = e => updateNewItem(e.target.value)

  const handleKeyUpNewItem = e => {
    e.preventDefault()
    if (e.code === 'Enter' && newItem) {
      itemArray.push(newItem)
      updateNewItem('')
      onChange(itemArray)
    }
  }

  const handleClickNewItem = () => {
    if (newItem) {
      itemArray.push(newItem)
      updateNewItem('')
      onChange(itemArray)
    }
  }

  const handleKeyDown = e => {
    if (e.code === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <React.Fragment>
      {itemArray.map((item, i) =>
        <Draggable key={i} draggable dragID={i}>
          <div className="input-group">
            <div className="input-group-text">
              <DragHandle>
                <Droppable onDrop={dragIndex => handleDropList(dragIndex, i)}>
                  <IconGrip />
                </Droppable>
              </DragHandle>
            </div>
            <div className="input-group-text" style={{ width: '40px' }}>
              {i + 1}
            </div>
            <input className="form-control" value={item} disabled={disable} onChange={e => handleChangeList(e.target.value, i)} />
            <div className="input-group-text">
              <IconClickDelete onClick={() => handleDeleteList(i)} />
            </div>
          </div>
        </Draggable>
      )}

      <div className="input-group">
        <input
          className="form-control"
          placeholder="New Objective"
          value={newItem}
          disabled={disable}
          onChange={handleChangeNewItem}
          onKeyUp={handleKeyUpNewItem}
          onKeyDown={handleKeyDown}
        />

        <div className="input-group-text">
          <IconClickAdd onClick={handleClickNewItem} />
        </div>
      </div>
    </React.Fragment>
  )
}

export const SelectCourseMetadata = ({ value, onChange }) => {
  const metadata = useMemo(() => (value ? JSON.parse(value) : {}), [value])

  const formDisabled = useFormDisable()

  const handlePublic = e => {
    metadata.IsPublic = e.target.value === 'true'
    let metadataValue = JSON.stringify(metadata)
    onChange && onChange(metadataValue)
  }

  const handleOverview = e => {
    metadata.OverviewString = e.target.value
    let metadataValue = JSON.stringify(metadata)
    onChange && onChange(metadataValue)
  }

  const handleChangeList = value => {
    metadata.ObjectivesStringList = value
    let metadataValue = JSON.stringify(metadata)
    onChange && onChange(metadataValue)
  }

  return (
    <React.Fragment>
      <FormatLabel label="is public">
        <select className="form-control" value={metadata.IsPublic} onChange={handlePublic} disabled={formDisabled}>
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </FormatLabel>
      <FormatLabel label="overview">
        <div>
          <textarea className="form-control" value={metadata.OverviewString} onChange={handleOverview} disabled={formDisabled} />
        </div>
      </FormatLabel>
      <FormatLabel label="objectives">
        <ListItems items={metadata.ObjectivesStringList} onChange={handleChangeList} disable={formDisabled} />
      </FormatLabel>
    </React.Fragment>
  )
}
