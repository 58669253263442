import React from 'react'
import { IconCsv, IconDelete, IconDetails } from './icon'

export const IconButtonClick = ({ children, text, small, disabled, onClick }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick && onClick()
    }
  }

  return (
    <div className={`btn btn-primary mx-1 ${small && 'btn-sm'}`} onClick={handleClick}>
      <div className="d-flex text-nowrap text-capitalize">
        <span>
          {children}
        </span>
        <span>
          {text}
        </span>
      </div>
    </div>
  )
}

export const IconButtonClickExportDetails = ({ className, text, disabled, small, size, onClick }) => {
  return (
    <IconButtonClick text={text || 'Export Details'} small={small} disabled={disabled} onClick={onClick}>
      <IconDetails className={className} size={size} />
    </IconButtonClick>
  )
}

export const IconButtonClickRemove = ({ className, text, disabled, small, size, onClick }) => {
  return (
    <IconButtonClick text={text || 'Remove'} small={small} disabled={disabled} onClick={onClick}>
      <IconDelete className={className} size={size} />
    </IconButtonClick>
  )
}

export const IconButtonClickImportCSV = ({ className, text, disabled, small, size, onClick }) => {
  return (
    <IconButtonClick text={text || 'ImportCsv'} small={small} disabled={disabled} onClick={onClick}>
      <IconCsv className={className} size={size} />
    </IconButtonClick>
  )
}

export const IconButtonClickDelete = ({ className, text, disabled, small, size, onClick }) => {
  return (
    <IconButtonClick text={text || 'Delete'} small={small} disabled={disabled} onClick={onClick}>
      <IconDelete className={className} size={size} />
    </IconButtonClick>
  )
}
