import React, { useContext } from 'react'
import { useNodeCategoryForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../shared/form/form'
import { useHistory } from 'react-router-dom'
import { GridContext } from '../grid/grid-context'
import { IconAdd } from '../../../../shared/icon/icon'
import { Card } from '../../../../shared/card/card'

export const CategoryAdd = ({ parentUrl, parentID }) => {
  const history = useHistory()

  const gridContext = useContext(GridContext)

  const [nodeCategoryActions] = useNodeCategoryForm()

  const handleSubmit = async data => {
    const node = await nodeCategoryActions.post(data)
    await gridContext.actions.loadContents()
    await gridContext.actions.loadThumbnails()
    history.push(`${parentUrl}/category-edit/${node.id}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="category add">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="parentID" value={parentID} />
            <FormText label="name" name="name" autoFocus />
            <FormSubmitBack text="create category" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
