import React from 'react'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconAdd } from '../../../shared/icon/icon'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../shared/form/form'
import { useAssetForm } from '../../../api/state'
import { useHistory } from 'react-router-dom'
import { Card } from '../../../shared/card/card'

export const NodeAssetAdd = ({ parentUrl, nodeID }) => {
  const history = useHistory()

  const [assetFormActions] = useAssetForm()

  const handleSubmit = async data => {
    let asset = await assetFormActions.post(data)
    history.push(`${parentUrl}/asset-edit/${asset.id}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add asset">
        <Card title="asset add">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} />
            <FormText label="name" name="name" required autoFocus />
            <FormSubmitBack text="create asset" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
