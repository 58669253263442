import React, { useContext } from 'react'
import { GridItems } from './grid-items'
import { Route, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { GridContext } from './grid-context'
import { CourseDetails } from '../course/course-details'
import { CategoryEdit } from '../category/category-edit'
import { CategoryAdd } from '../category/category-add'
import { CategoryDelete } from '../category/category-delete'
import { InputFilter } from '../../shared/input-filter'
import { useFindNode, useNodeTitle } from '../../hooks/hooks-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { Menu, MenuAdd, MenuRecycle } from '../../../../shared/menu/menu'
import { Card } from '../../../../shared/card/card'
import { IconGrid } from '../../../../shared/icon/icon'

export const GridFrame = () => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const gridContext = useContext(GridContext)

  const currentNode = useFindNode(parentID, gridContext.contents)
  const title = useNodeTitle('categories', parentID, currentNode)

  const handleMatch = () => gridContext.actions.clearFilter()

  const Header = () =>
    <Menu>
      <MenuAdd text="add category" to={`${url}/category-add`} show={roleContent.accessCreate} />
      <MenuRecycle to="/content/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconGrid} text={title} onMatch={handleMatch}>
        <Card title={title} HeaderMenu={Header}>
          <div className="py-2">
            <InputFilter
              value={gridContext.filter}
              placeholder="filter"
              onChange={gridContext.actions.setFilter}
              onClear={gridContext.actions.clearFilter}
            />
          </div>

          <GridItems url={url} />
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <GridFrame />
      </Route>

      <Route path={`${url}/category-add`}>
        <CategoryAdd parentUrl={url} parentID={parentID} />
      </Route>

      <Route path={`${url}/category-edit/:nodeID`}>
        <CategoryEdit parentUrl={url} />
      </Route>

      <Route path={`${url}/category-delete/:nodeID`}>
        <CategoryDelete parentUrl={url} />
      </Route>

      <Route path={`${url}/course-details/:nodeID`}>
        <CourseDetails />
      </Route>
    </React.Fragment>
  )
}
