import React from 'react'
import { Route } from 'react-router-dom'
import { CacheProvider } from '../../../context/cache.context'
import { Root } from './root'
import { GridProvider } from './grid/grid-context'
import { ContentRecycle } from './recycle/content-recycle'
import { Purge } from '../../../shared/page/page-purge'
import { useApiFormNode } from '../../../api-new/state/content'

export function ContentRoute() {
  return (
    <React.Fragment>
      <CacheProvider>
        <GridProvider>
          <Route path="/content" component={Root} />
          <Route path="/content/recycle" component={ContentRecycle} />

          <Route path="/content/recycle/:purgeID/purge">
            <Purge useApiForm={useApiFormNode} titleParam="name" successPath="/content/recycle" />
          </Route>
        </GridProvider>
      </CacheProvider>
    </React.Fragment>
  )
}
