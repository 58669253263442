import React, { useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useNodeCourseForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CourseCopy } from './course-copy'
import { CourseDelete } from './course-delete'
import { CourseEdit } from './course-edit'
import { CourseMove } from './course-move'
import { CourseTemplate } from './course-template'
import { useMetadataType } from '../../hooks/hooks-node'
import { AnalyticLayout } from '../../../common/analytic/page/course/analytic-legacy/layout'
import { HeatmapLayoutNode } from '../../../heatmap/layout-node'
import { useParamsInt } from '../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../role/role.const'
import { RoleAccessDisplayOrg } from '../../../../role/components/role-access-display'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { Menu, MenuDelete, MenuEdit, MenuItem } from '../../../../shared/menu/menu'
import { AssessmentRoute } from '../../../common/assessment/route'
import { Grid, GridItem3 } from '../../../../shared/chart/grid'
import { CourseAssessment } from './component/course-assessment'
import { AnalyticGeneralCourse } from './component/analytic-general-course'
import { CourseHeatmap } from './component/course-heatmap'
import { AnalyticRoute } from '../../../common/analytic/page/course/analytic-course-custom/route'
import { Card } from '../../../../shared/card/card'
import { AnalyticCustomCourse } from './component/analytic-custom-course'
import { IconUser, IconCopy, IconDetails, IconMove, IconPlus, IconGroup } from '../../../../shared/icon/icon'

export const CourseDetails = () => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const [nodeCourseFormActions, nodeCourseFormState] = useNodeCourseForm()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleAssessmentManage = useRoleAccessOrganization(ROLE_ORG.ASSESSMENT_MANAGE)
  const roleAnalytic = useRoleAccessOrganization(ROLE_ORG.ANALYTIC)

  const title = useMemo(() => nodeCourseFormState.form.name, [nodeCourseFormState.form])
  const isCaffeine = useMetadataType(nodeCourseFormState.form, 'CourseAssemblyName', 'caffeine')

  const handlePath = async () => await nodeCourseFormActions.get(nodeID)

  const HeaderComponent = () =>
    <Menu>
      <MenuEdit to={`${url}/edit/${nodeID}`} show={roleContent.accessUpdate} />

      <MenuItem
        Icon={IconUser}
        text="user permissions"
        to={`${url}/edit/${nodeID}/content-permission/${nodeID}`}
        show={roleContent.accessRead}
      />

      <MenuItem
        Icon={IconGroup}
        text="group permissions"
        to={`${url}/edit/${nodeID}/group-permission/${nodeID}`}
        show={roleContent.accessRead}
      />

      <MenuItem
        Icon={IconPlus}
        text="new template"
        to={`${url}/template/${nodeID}`}
        disabled={!isCaffeine}
        show={roleContent.accessCreate}
      />

      <MenuItem Icon={IconMove} text="move" to={`${url}/move/${nodeID}`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy/${nodeID}`} disabled={!isCaffeine} show={roleContent.accessUpdate} />

      <MenuDelete to={`${url}/delete/${nodeID}`} show={roleContent.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text={title} onPath={handlePath}>
        <Card title={nodeCourseFormState.form.name} HeaderMenu={HeaderComponent}>
          <Grid>
            <RoleAccessDisplayOrg type={ROLE_ORG.ASSESSMENT_REPORT} accessRead>
              <GridItem3>
                <CourseAssessment nodeID={nodeID} showConfigButton={roleAssessmentManage.accessRead} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.ANALYTIC} accessRead>
              <GridItem3>
                <AnalyticGeneralCourse nodeID={nodeID} showConfigButton={roleAnalytic.accessUpdate} />
              </GridItem3>
              <GridItem3>
                <AnalyticCustomCourse nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.HEATMAP} accessRead>
              <GridItem3>
                <CourseHeatmap nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
          </Grid>
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/edit/:nodeID`}>
        <CourseEdit parentUrl={url} />
      </Route>

      <Route path={`${url}/template/:nodeID`}>
        <CourseTemplate parentUrl={url} />
      </Route>

      <Route path={`${url}/move/:nodeID`}>
        <CourseMove parentUrl={url} />
      </Route>

      <Route path={`${url}/copy/:nodeID`}>
        <CourseCopy parentUrl={url} />
      </Route>

      <Route path={`${url}/delete/:nodeID`}>
        <CourseDelete parentUrl={url} />
      </Route>

      <AnalyticRoute />
      <AnalyticLayout />
      <HeatmapLayoutNode />
      <AssessmentRoute />
    </React.Fragment>
  )
}
