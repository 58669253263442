import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { ROLE_ORG } from '../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../role/role.hook'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { useApiListGroup } from '../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { IconContent, IconGroup, IconUser } from '../../../../../shared/icon/icon'

export const GroupList = () => {
  const apiListGroup = useApiListGroup()

  const roleGroup = useRoleAccessOrganization(ROLE_ORG.GROUP)
  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)
  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const handleReady = async () => await apiListGroup.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add group" to="/org/group/list/add" show={roleGroup.accessCreate} />
      <MenuRecycle to="/org/group/list/recycle" show={roleGroup.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/group/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconGroup} text="GROUPS">
        <CardFullHeightScrollY title="groups" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListGroup} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/group/list/edit/${row.id}`} show={roleGroup.accessUpdate} />
                  <MenuItem
                    Icon={IconContent}
                    text="contents"
                    to={`/org/group/list/edit/${row.id}/content`}
                    show={roleNodeGroup.accessRead}
                  />
                  <MenuItem Icon={IconUser} text="users" to={`/org/group/list/edit/${row.id}/user`} show={roleGroupUser.accessRead} />
                  <MenuDelete to={`/org/group/list/edit/${row.id}/delete`} show={roleGroup.accessDelete} />
                  <MenuActivityLog to={`/org/group/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
