import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDownloadForm } from '../../api/state'
import moment from 'moment'
import { ScrollY } from '../../shared/component/scroll'

export const DownloadApp = () => {
  const { appStoreKey } = useParams()

  const [appDownloadFormAction, appDownloadFormState] = useAppDownloadForm()

  useEffect(
    () => {
      appStoreKey && appDownloadFormAction.get(`${appStoreKey}/store`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appStoreKey]
  )

  return (
    <ScrollY>
      {/* DISPLAY EXTRA SMALL */}
      <div className="d-sm-none">
        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="d-flex">
                <div className="m-2">
                  <img src="/EDXR-Icon.png" width="100" alt="edxr" />
                </div>
                <div className="m-2">
                  <AppInfo form={appDownloadFormState.form} />
                </div>
              </div>
            </div>

            <div className="border p-3 bg-secondary text-black" style={{ borderRadius: '15px' }}>
              <AppDescription />
            </div>

            <div className="p-3">
              <AppNotes value={appDownloadFormState.form.versionNotesHTML} />
            </div>
          </div>
        </div>
      </div>

      {/* DISPLAY SMALL AND UP */}
      <div className="d-none d-sm-block">
        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="d-flex">
                <div className="m-2">
                  <img src="/EDXR-Icon.png" width="200" alt="edxr" />
                </div>
                <div className="m-2">
                  <AppInfo form={appDownloadFormState.form} />
                </div>
              </div>
            </div>

            <div className="border p-3 mt-3 bg-primary text-white" style={{ borderRadius: '15px' }}>
              <AppDescription />
            </div>

            <div className="p-3 mt-3">
              <AppNotes value={appDownloadFormState.form.versionNotesHTML} />
            </div>
          </div>
        </div>
      </div>
    </ScrollY>
  )
}

const AppInfo = ({ form }) => {
  return (
    <React.Fragment>
      <AppInfoItem className="text-nowrap" style={{ fontSize: '24px' }} value={form.storeName} />
      <AppInfoItem className="text-nowrap" label="Version" value={form.versionName} />
      <AppInfoItem className="text-nowrap" label="Code" value={form.versionCode} />
      <AppInfoItem className="text-nowrap" label="Release Date" value={moment(form.releaseDate).format('MM-DD-YYYY')} />
      <DownloadButton url={form.storageDownloadURL} />
    </React.Fragment>
  )
}

const AppInfoItem = ({ className, style, label, value }) => {
  return (
    <React.Fragment>
      <div className={className} style={style}>
        {label &&
          <span className="mr-1">
            {label}:&nbsp;
          </span>}
        <span>
          {value}
        </span>
      </div>
    </React.Fragment>
  )
}

const DownloadButton = ({ url }) => {
  return (
    <a className="btn btn-primary my-2" href={url} target="_blank" rel="noreferrer">
      Download
    </a>
  )
}

const AppNotes = ({ value }) => {
  function createMarkup() {
    return { __html: value }
  }

  return (
    <React.Fragment>
      <h5>What’s New</h5>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </React.Fragment>
  )
}

const AppDescription = () => {
  return (
    <React.Fragment>
      <p>
        EducationXR is an extended reality (XR) platform used to deliver immersive education content to mobile, tablet, desktop and virtual
        reality applications. This application provides users with the ability to connect together with other users in one singular
        experience regardless of location and hardware.
      </p>

      <p>
        EducationXR has been custom-built from the ground up to deliver quality, real-time 3D education trusted by superior institution and
        industry names including, GE, UNMC, Bracco, and Harvard’s MGH.
      </p>

      <p>
        Contact Us: Any feedback? Email us at{' '}
        <a className="text-white" href="mailto: info@educationxr.com">
          info@educationxr.com
        </a>
      </p>
    </React.Fragment>
  )
}
