import React, { useEffect, useState } from 'react'
import { useAssetFileGet } from '../../../api/state'
import { clearImageCache, getBase64, getImageCache, setImageCache } from '../../../cache/image-cache'
import { FileImageSwap } from '../../../shared/legacy/file/file-image-swap'
import { useAssetForm, useAssetFileForm } from '../../../api/state'

export const ThumbnailImageEdit = ({ nodeID, asset, name, onChange }) => {
  const [assetFormActions] = useAssetForm()
  const [assetFileFormActions] = useAssetFileForm()
  const [assetFileGetActions] = useAssetFileGet()

  const [imageUrl, updateUrlImage] = useState(undefined)

  useEffect(
    () => {
      const getData = async () => {
        try {
          let blob = await assetFileGetActions.blob(asset.key)
          let image = await getBase64(blob)
          setImageCache(asset.key, image)
          updateUrlImage(image)  
        } catch {}
      }

      if (!asset) {
        return
      }

      let image = getImageCache(asset.key)

      if (image) updateUrlImage(image)
      else getData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [asset]
  )

  const handleSave = async file => {
    let saveAsset

    if (asset) {
      saveAsset = asset
      saveAsset.length = file.size
      saveAsset = await assetFormActions.putArgs({ path: saveAsset.id, data: saveAsset })
    } else {
      const metaData = JSON.stringify({ DataType: 'THUMBNAIL' })
      saveAsset = { nodeID: nodeID, name: name || 'thumbnail assest', length: file.size, metaData: metaData }
      saveAsset = await assetFormActions.post(saveAsset)
    }

    await assetFileFormActions.putArgs({ path: saveAsset.key, data: file })
    let image = await getBase64(file)
    setImageCache(saveAsset.key, image)
    updateUrlImage(image)
    onChange && onChange(saveAsset.key, image)
  }

  const handleClear = async () => {
    await assetFormActions.delete(asset.id)
    clearImageCache(asset.key)
    updateUrlImage(undefined)
    onChange && onChange()
  }

  return (
    <React.Fragment>
      <FileImageSwap
        title="thumbnail image"
        rowColWidth="col-12 col-md-6 col-xl-4"
        ratio="2:1"
        accept="image/jpg,image/jpeg,image/png"
        maxSize={1024 * 1024 / 2}
        logoURL={imageUrl}
        onSave={handleSave}
        onClear={handleClear}
      />
    </React.Fragment>
  )
}
