import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListApp } from '../../../../../../api-new/state/config'

export const AppRecycle = () => {
  const apiListApp = useApiListApp()

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const handleReady = async () => await apiListApp.get('deleted')

  const handleRestore = async id => {
    await apiListApp.put(`${id}/restore`)
    await apiListApp.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListApp} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleApp.accessDelete} />
                  <MenuPurge to={`/admin/app/recycle/purge/${row.id}`} show={roleApp.accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
