import React, { useMemo } from 'react'

export const MetaDataType = ({ children, item, type, value, not }) => {
  let show = useMemo(
    () => {
      if (item && item.metaData) {
        let metaData = item.metaData.toLowerCase()
        metaData = JSON.parse(metaData)
        let lowerType = type.toLowerCase()
        if (metaData[lowerType]) {
          if (not) return !metaData[lowerType].includes(value.toLowerCase())
          else return metaData[lowerType].includes(value.toLowerCase())
        } else {
          return not ? true : false
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  )

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}
