import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useApiFormUser, useApiListLoginOrgUser, useApiListRoleSyncUserSys } from '../../../../../../api-new/state/content'
import { useApiListOrganization } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { IconOrganization } from '../../../../../../shared/icon/icon'

export function OrganizationUserAdd() {
  const { loginID } = useParamsInt()

  const apiListLoginOrgUser = useApiListLoginOrgUser()

  const apiListOrganization = useApiListOrganization()
  const apiFormUser = useApiFormUser()
  const apiListRoleSyncUserSys = useApiListRoleSyncUserSys()

  const handleMatch = async () => {
    await apiListLoginOrgUser.get(`${loginID}/login`)
    await apiListOrganization.get()
  }

  const handleCreate = async item => {
    let user = await apiFormUser.post({ organizationID: item.id, loginID: loginID })
    await apiListRoleSyncUserSys.put(`${user.id}/auto`)
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  const { accessCreate } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const handleInitialFilter = items => items.filter(org => !apiListLoginOrgUser.items.some(orgUser => orgUser.organizationID === org.id))

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconOrganization} text="organization" onMatch={handleMatch}>
        <CardFullHeightScrollY title="add login to organization">
          <TableBuilder apiList={apiListOrganization} initialFilterFn={handleInitialFilter}>
            <Column field="name" header="Organization" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd text="add" onClick={() => handleCreate(row)} show={accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
