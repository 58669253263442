import React, { useContext, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse, useApiFormTemplate } from '../../../../../../api-new/state/content'
import { useMetadataType } from '../../common/hooks/hooks'
import { useLoad } from '../../../../../../shared/hooks/load'
import { AssetCopyProgress, useAssetCopy } from '../../../../../../shared/component/asset-copy'
import { buildNodeLocation, buildTemplatePath } from '../../common/shared/node-path'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconTemplate } from '../../../../../../shared/icon/icon'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { DisplaySysAdmin } from '../../../../../../role/role.component'
import { FormSelectOrgaization } from '../../../../../../shared/form/form-control/form-select-organization'
import { useDataContext } from '../../data-context'
import { Modal } from '../../../../../../shared/component/modal'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const NodeCourseTemplate = ({ nodeID, parentURL }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const refModal = useRef()

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormTemplateCourse = useApiFormTemplate('/course')
  const apiFormTemplateCourseOrg = useApiFormTemplate('/course-org')

  const [organizationID, updateOrganizationID] = useState(null)

  const dataContext = useDataContext()

  const title = useMemo(() => apiFormNodeCourse.form.name, [apiFormNodeCourse.form])
  const isCaffeine = useMetadataType(apiFormNodeCourse.form, 'CourseAssemblyName', 'caffeine')

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  useLoad(
    () => {
      authContext.state.jwtClaims.organizationID && updateOrganizationID(authContext.state.jwtClaims.organizationID)
    },
    [authContext.state.jwtClaims.organizationID]
  )

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState()

  const onCopyComplete = nodeID => {
    if (nodeID) {
      updateCopiedNodeID(nodeID)
      const destLocation = buildNodeLocation(dataContext.nodes, nodeID)
      updateDestLocation(destLocation)
    }

    refModal.current.show()
  }

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const handleSubmit = async data => {
    let node = authContext.state.isSysAdmin ? await apiFormTemplateCourseOrg.post(data) : await apiFormTemplateCourse.post(data)

    if (authContext.state.jwtClaims.organizationID === node.organizationID) {
      await init(node.id)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })

      dataContext.nodeInsert(node)
    } else {
      await init(null)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })
    }
  }

  const handleConfirm = () => {
    refModal.current.hide()

    if (copiedNodeID) {
      let path = buildTemplatePath(dataContext.nodes, copiedNodeID)
      history.push(path)
    } else {
      history.push(parentURL)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text="create template" onMatch={handleMatch}>
        <Modal ref={refModal} backdropStatic title="create complete" confirmText="ok" onConfirm={handleConfirm}>
          <p>
            {/* {apiFormNodeCourse.form.name} has successfully been created at {destLocation} */}
            {apiFormNodeCourse.form.name} has successfully been created {destLocation && `at ${destLocation}`}
          </p>
        </Modal>

        <Card title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

                  <DisplaySysAdmin>
                    <FormSelectOrgaization lable="organization" name="organizationID" organizationID={organizationID} required />
                  </DisplaySysAdmin>

                  <FormText label="name" name="name" defaultValue={apiFormNodeCourse.form.name} required shouldDirty />

                  <FormSubmitBack text="create template" />
                </Form>

                <AssetCopyProgress content={content} />
              </React.Fragment>
            : <div>Action not allowed for course</div>}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
