import React, { useMemo } from 'react'
import { FilterList } from '../common/filter/filter-list'
import { buildCoursePathMap, getMap } from '../common/shared/node-path'
import { IconContent } from '../../../../../shared/icon/icon'

export const CourseFilterList = ({ nodes, filter, onSelect }) => {
  let nodeMap = useMemo(() => getMap(nodes), [nodes])

  const handleClick = nodeID => {
    let path = buildCoursePathMap(nodeMap, nodeID)
    onSelect(path)
  }

  return <FilterList RootIcon={IconContent} nodes={nodes} nodeMap={nodeMap} filter={filter} onClick={handleClick} />
}
