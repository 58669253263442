import React from 'react'
import { ScrollY } from '../component/scroll'

const AppLinkItem = ({ src, href, qr }) => {
  return (
    <React.Fragment>
      <div>
        <a className="" href={href} target="_blank" rel="noreferrer">
          <img height="60px" src={src} alt="link" />
        </a>
      </div>
      {qr &&
        <div className="ps-3">
          <img src={qr} width={120} height={120} alt="link" />
        </div>}
    </React.Fragment>
  )
}

const Platform = ({ title, details, links }) => {
  return (
    <React.Fragment>
      <div className="py-3">
        <h2>
          {title}
        </h2>

        <div className="py-2">
          {details}
        </div>

        <div className="container-fluid p-0 m-0 bg-danger1">
          <div className="row g-2">
            {links &&
              links.map((link, i) =>
                <div key={i} className="col-12 col-sm-4 col-xl-3 col-xxl-2 bg-info1">
                  {link}
                </div>
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export const AppLinks = () => {
  return (
    <ScrollY>
      <Platform
        title="Virtual Reality"
        details="Use VR headsets (Oculus, HTC, Samsung, Index etc) to explore learning content at the fullest level of immersion"
        links={[
          <AppLinkItem
            src="/store-app-steam.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/QLXmpwuykoSNZyplanMW/store`}
          />,
          <AppLinkItem
            src="/store-app-meta.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/tlGkPVAjdnRlPVVSrPWf/store`}
          />,
          <AppLinkItem
            src="/store-app-pico.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/HTGbpSAIaqlYhdxvcnqH/store`}
          />
        ]}
      />

      <Platform
        title="Mobile Devices"
        details="3D immersive content at your fingertips, from any mobile device, anywhere"
        links={[
          <AppLinkItem
            src="/store-app-ios.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/CMPDoBOvFLSdtiaIFOwW/store`}
            qr="/qr-code-app-store.png"
          />,
          <AppLinkItem
            src="/store-app-google.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/OrtEUNWbVMWzrrbZrhsq/store`}
            qr="/qr-code-google-play.png"
          />
        ]}
      />

      <Platform
        title="Mac & PC"
        details="Use Mac or PC computers to explore immersive content"
        links={[
          <AppLinkItem
            src="/store-app-mac.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/MbJldvViKuwXBmwddeny/store`}
          />,
          <AppLinkItem
            src="/store-app-microsoft.png"
            href={`${process.env.REACT_APP_API_CONFIG}/api/v2/app-store-proxy/VUiBiRnHbEhSGvddsmlK/store`}
          />
        ]}
      />
    </ScrollY>
  )
}
