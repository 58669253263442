import { Link } from 'react-router-dom'
import { IconVideo } from '../../../../../../shared/icon/icon'

export const GridItem = ({ Thumbnail, Icon, FooterMenu, title, to, video }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
      <div className="card" style={{ height1: 300 }}>
        <div className="card-body p-1">
          <div className="d-flex flex-column h-100">
            <div className="flex-fill">
              <Link to={to}>
                <div>
                  {video &&
                    <div
                      className="text-primary"
                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -135%)', opacity: '60%' }}>
                      <IconVideo className="" size="" transform="grow-50" />
                    </div>}
                  <div>
                    {Thumbnail && <Thumbnail />}
                  </div>
                </div>
              </Link>
            </div>
            <div className="d-flex border-top">
              <div className="mt-2">
                {Icon && <Icon />}
              </div>
              <div className="flex-fill ms-2 mt-2 text-truncate">
                {title}
              </div>
              <div>
                {FooterMenu && <FooterMenu />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
