import React, { useContext, useMemo } from 'react'
import { useAssetList, useNodeCategoryForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmit, FormText } from '../../../../shared/form/form'
import { GridContext } from '../grid/grid-context'
import { ThumbnailImageEdit } from '../../shared/thumbnail-image-edit'
import { useGetByDataType } from '../../hooks/hook-asset'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Route, useRouteMatch } from 'react-router-dom'
import { ContentListUser } from '../permission/content-list-user'
import { ContentListUserAdd } from '../permission/content-list-user-add'
import { ContentListGroup } from '../permission/content-list-group'
import { ContentListGroupAdd } from '../permission/content-list-group-add'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { ROLE_ORG } from '../../../../role/role.const'
import { Menu, MenuDelete, MenuItem } from '../../../../shared/menu/menu'
import { Card } from '../../../../shared/card/card'
import { IconUsers, IconEdit, IconGroup } from '../../../../shared/icon/icon'

export const CategoryEdit = ({ parentUrl }) => {
  const { nodeID } = useParamsInt()

  const { url } = useRouteMatch()

  const gridContext = useContext(GridContext)

  const [nodeCategoryFormActions, nodeCategoryFormState] = useNodeCategoryForm()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)
  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)

  const [assetListActions, assetListState] = useAssetList()

  const handlePath = async () => {
    await nodeCategoryFormActions.get(nodeID)
    assetListActions.get(`${nodeID}/node`)
  }

  const handleSubmit = async node => await nodeCategoryFormActions.put(node)

  const title = useMemo(() => `edit ${nodeCategoryFormState.form.name}`, [nodeCategoryFormState.form.name])

  const thumbnail = useGetByDataType('thumbnail', assetListState.items)

  const handleImageChange = () => gridContext.actions.loadThumbnails()

  const Header = () =>
    <Menu>
      <MenuItem Icon={IconUsers} text="user permissions" to={`${url}/content-permission/${nodeID}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${url}/group-permission/${nodeID}`} show={roleNodeGroup.accessRead} />
      <MenuDelete to={`${parentUrl}/category-delete/${nodeID}`} show={roleContent.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title={title} HeaderComponent={Header}>
          <Card>
            <Form state={nodeCategoryFormState.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormSubmit text="update" />
            </Form>
          </Card>

          <ThumbnailImageEdit nodeID={nodeID} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/content-permission/:nodeID`}>
        <ContentListUser />
      </Route>

      <Route path={`${url}/content-permission/:nodeID/add`}>
        <ContentListUserAdd />
      </Route>

      <Route path={`${url}/group-permission/:nodeID`}>
        <ContentListGroup />
      </Route>

      <Route path={`${url}/group-permission/:nodeID/add`}>
        <ContentListGroupAdd />
      </Route>
    </React.Fragment>
  )
}
