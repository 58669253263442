import React, { useEffect, useMemo, useState } from 'react'
import { useAssetFileGet } from '../../../../../../api/state'

const getBase64 = blob => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

const useGetByDataType = (dataType, assets) => {
  return useMemo(
    () => {
      return assets.find(asset => {
        if (!asset.metaData) {
          return false
        }

        let metaData = JSON.parse(asset.metaData)

        if (!metaData.DataType) {
          return false
        }

        return dataType.toLowerCase() === metaData.DataType.toLowerCase()
      })
    },
    [dataType, assets]
  )
}

export const ThumbnailImage = ({ nodeID, assets, cache }) => {
  const [assetFileGetActions] = useAssetFileGet()
  const [image, updateImage] = useState(undefined)

  let nodeAssets = useMemo(() => (assets ? assets.filter(i => i.nodeID === nodeID) : []), [nodeID, assets])

  const thumbnail = useGetByDataType('thumbnail', nodeAssets)

  useEffect(
    () => {
      thumbnail && loadThumbnail(thumbnail.key)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thumbnail]
  )

  const loadThumbnail = async key => {
    try {
      let image = cache.get(key)

      if (image) {
        updateImage(image)
      } else {
        let blob = await assetFileGetActions.blob(key)
        if (blob.size) {
          let image = await getBase64(blob)
          cache.add(key, image)
          updateImage(image)
        }
      }
    } catch (error) {
      console.log(`failed to get assets: ${key}`)
    }
  }

  return (
    <React.Fragment>
      {image
        ? <img className="course-image" src={image} alt="img" />
        : <img className="course-image" src="/content_placeholder.png" alt="img" />}
    </React.Fragment>
  )
}
