import React from 'react'
import { Route } from 'react-router-dom'
import { RootNode } from './node-tree'
import { NodeRecycle } from './node-recycle'

export function LayoutNode() {
  return (
    <React.Fragment>
      <Route path="/node/root" component={RootNode} />
      <Route path="/node/root/recycle" component={NodeRecycle} />
    </React.Fragment>
  )
}
