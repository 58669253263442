import React, { useEffect, useRef, useState } from 'react'
import { useRoleOrgList } from '../../../../../../api/state'
import { useInputErrors } from '../hook'
import { SelectTag } from '../../../../../../shared/select-tag/select-tag'
import { Card } from '../../../../../../shared/card/card'
import { IconClickAdd, IconClickDelete } from '../../../../../../shared/icon/icon-click'

const Email = ({ i, name, valueKey, value, placeholder, disabled, onChange }) => {
  const [ref, errorState] = useInputErrors(value)

  const handleChange = e => onChange && onChange(i, valueKey, e.target.value)

  let matchEmailPattern =
    '^(([\\w-]+.)+[\\w-]+|([a-zA-Z]{1}|[\\w-]{2,}))@' +
    '((([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9]).([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9]).' +
    '([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9]).([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])){1}|' +
    '([a-zA-Z]+[\\w-]+.)+[a-zA-Z]{2,4})$'

  return (
    <React.Fragment>
      <div>
        <input
          ref={ref}
          className="form-control"
          name={name}
          type="text"
          value={value}
          required
          pattern={matchEmailPattern}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
        />
      </div>
      <div>
        {!errorState.valid && <small className={'p-1 text-danger'}>Required</small>}
      </div>
    </React.Fragment>
  )
}

const Text = ({ i, name, valueKey, value, placeholder, disabled, onChange }) => {
  const [ref, errorState] = useInputErrors(value)

  const handleChange = e => {
    onChange && onChange(i, valueKey, e.target.value)
  }

  return (
    <React.Fragment>
      <div>
        <input
          ref={ref}
          className="form-control"
          name={name}
          type="text"
          value={value}
          required
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
        />
      </div>
      <div>
        {!errorState.required && <small className={'p-1 text-danger'}>Required</small>}
      </div>
    </React.Fragment>
  )
}

const RoleSelect = ({ i, valueKey, items, disabled, onChange }) => {
  const [state, updateState] = useState([])

  useEffect(
    () => {
      let values = items.map(item => ({ value: item.id, role: item.role }))
      updateState(values)
    },
    [items]
  )

  const handleAdd = item => {
    item.active = true
    let newState = [...state]
    updateState(newState)
    let values = state.filter(item => item.active).map(item => item.value)
    onChange && onChange(i, valueKey, values)
  }

  const handleRemove = item => {
    item.active = false
    let newState = [...state]
    updateState(newState)
    let values = state.filter(item => item.active).map(item => item.value)
    onChange && onChange(i, valueKey, values)
  }

  return (
    <React.Fragment>
      <SelectTag data={state} fnLabel={item => item.role} fnActive={item => item.active} onAdd={handleAdd} onRemove={handleRemove} />
    </React.Fragment>
  )
}

export const UserList = ({ invites, disabled, onUpdate, onErrors, onError }) => {
  const [roleOrgListActions, roleOrgListState] = useRoleOrgList({})

  useEffect(() => {
    roleOrgListActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInsert = () => {
    onUpdate && onUpdate([...invites, { email: '', firstName: '', lastName: '', roleID: null }])
  }

  const handleRemove = i => {
    invites.splice(i, 1)
    onUpdate && onUpdate([...invites])
  }

  const handleChange = (i, valueKey, value) => {
    let newInvites = [...invites]
    newInvites[i][valueKey] = value
    onUpdate && onUpdate(newInvites)
  }

  let ref = useRef(null)

  const checkInput = input => {
    return !input.validity.valid || input.validity.valueMissing
  }

  useEffect(
    () => {
      if (ref.current) {
        for (let i = 0; i < ref.current.elements.length; i++) {
          let input = ref.current.elements[i]
          if (checkInput(input)) {
            onError && onError(true)
            return
          }
        }
      }

      onError && onError(false)
    },
    [onError, invites]
  )

  return (
    <React.Fragment>
      <Card>
        <form ref={ref}>
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Roles</th>
                <th className="text-end align-middle">
                  <IconClickAdd disabled={disabled} onClick={handleInsert} />
                </th>
              </tr>
            </thead>
            <tbody>
              {invites &&
                invites.map((invite, i) =>
                  <tr key={i}>
                    <td className="pt-3">
                      {i + 1}
                    </td>
                    <td>
                      <Email
                        i={i}
                        name="email"
                        valueKey="email"
                        value={invite.email}
                        placeholder="email"
                        disabled={disabled}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Text
                        i={i}
                        name="firstName"
                        valueKey="firstName"
                        value={invite.firstName}
                        placeholder="first name"
                        disabled={disabled}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Text
                        i={i}
                        name="lastName"
                        valueKey="lastName"
                        value={invite.lastName}
                        placeholder="last name"
                        disabled={disabled}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <RoleSelect i={i} valueKey="roleIDs" items={roleOrgListState.items} disabled={disabled} onChange={handleChange} />
                    </td>
                    <td className="pt-3">
                      <IconClickDelete disabled={disabled} onClick={() => handleRemove(i)} />
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </form>
      </Card>
    </React.Fragment>
  )
}
