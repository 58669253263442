import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { TableHierarchyNode } from '../../../../../../shared/legacy/table/table-hierarchy-node'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useApiListNodeGroup } from '../../../../../../api-new/state/content'
import { IconContent } from '../../../../../../shared/icon/icon'

export const GroupListContent = () => {
  const { groupID } = useParamsInt()

  const apiListNodeGroup = useApiListNodeGroup()

  const handleMatch = async () => await apiListNodeGroup.get(`${groupID}/group-list`)

  const handleAdd = async nodeID => {
    await apiListNodeGroup.post({ nodeID, groupID })
    await apiListNodeGroup.get(`${groupID}/group-list`)
  }

  const handleDelete = async nodeID => {
    await apiListNodeGroup.delete(`${nodeID}/${groupID}`)
    await apiListNodeGroup.get(`${groupID}/group-list`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconContent} text="content" onMatch={handleMatch}>
        <TableHierarchyNode state={apiListNodeGroup} roleScope={ROLE_ORG.NODE_GROUP} onAdd={handleAdd} onDelete={handleDelete} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
