import React, { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Export } from '../component/export'
import { Filter, useFilterProp } from './filter'
import { Card } from '../card/card'
import { ButtonPrimary } from './button'
import { IconAdd, IconExport, IconRecycle } from '../icon/icon'

export default function TableFilter({
  title,
  exportName,
  HeaderComponent,
  HeaderItem,
  columns,
  data,
  sortFunction,
  onRowClicked,
  onAdd,
  onRecycle
}) {
  const [defaultSortField, updateDefaultSortField] = useState(undefined)

  const rowsPerPage = useMemo(() => parseInt(localStorage.getItem('table-filter-rows-per-page')), [])

  const [filteredItems, filter, updateFilter] = useFilterProp(data, 'name')

  useEffect(
    () => {
      if (columns.length) {
        updateDefaultSortField(columns[0].selector)
      }
    },
    [columns]
  )

  const handleRowChange = value => {
    localStorage.setItem('table-filter-rows-per-page', value)
  }

  const defaultSortFunction = (arr, key, direction) => {
    const result = arr.sort((first, second) => {
      const firstValue = first[key] ? (first[key].toLowerCase ? first[key].toLowerCase() : first[key]) : ''
      const secondValue = second[key] ? (second[key].toLowerCase ? second[key].toLowerCase() : second[key]) : ''

      if (direction === 'asc') {
        return firstValue < secondValue ? -1 : 1
      } else {
        return firstValue < secondValue ? 1 : -1
      }
    })
    return [...result]
  }

  const CardHeaderComponent = () =>
    <React.Fragment>
      {onAdd && <ButtonPrimary Icon={IconAdd} text="Add" onClick={onAdd} />}
      {onRecycle && <ButtonPrimary Icon={IconRecycle} text="Recycle" onClick={onRecycle} />}
      {HeaderComponent && <HeaderComponent />}
    </React.Fragment>

  const handleExportCSV = () => ref.current.export(data)

  const ref = useRef()

  return (
    <React.Fragment>
      <Card title={title} HeaderComponent={CardHeaderComponent} HeaderItem={HeaderItem}>
        <div className="d-flex flex-row">
          <Filter filter={filter} onChange={updateFilter} />
          {exportName &&
            <div className="py-2">
              <Export name={exportName} type="table" fileType="text/csv" ref={ref} />
              <ButtonPrimary Icon={IconExport} text="export" onClick={handleExportCSV} />
            </div>}
        </div>

        {defaultSortField &&
          <DataTable
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            paginationPerPage={rowsPerPage || 30}
            onChangeRowsPerPage={handleRowChange}
            striped
            noHeader
            pagination
            columns={columns}
            data={filteredItems}
            highlightOnHover
            sortFunction={sortFunction || defaultSortFunction}
            defaultSortField={defaultSortField}
            onRowClicked={onRowClicked}
            conditionalRowStyles={[
              {
                when: () => onRowClicked,
                style: { cursor: 'pointer' }
              }
            ]}
          />}
      </Card>
    </React.Fragment>
  )
}
