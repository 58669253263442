import React, { useEffect, useState } from 'react'
import { SearchListNode } from '../../../../../../shared/legacy/dropdown-search/search-list-node'
import { useContentList } from '../../../../../../api/state'
import { Card } from '../../../../../../shared/card/card'
import { IconClickDelete } from '../../../../../../shared/icon/icon-click'

export function ContentList({ nodes, onUpdate, disabled }) {
  const [contentListActions, contentListState] = useContentList()

  const [selectedNode, updateSelectedNode] = useState()

  useEffect(() => {
    contentListActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = item => updateSelectedNode(item)

  const handleAdd = item => {
    if (nodes.some(i => i.id === item.node.id)) return

    if (item.node) {
      onUpdate && onUpdate([...nodes, item.node])
    }

    updateSelectedNode(null)
  }

  const handleDelete = i => {
    let list = [...nodes]
    list.splice(i, 1)
    onUpdate && onUpdate(list)
  }

  return (
    <React.Fragment>
      <Card title="Content">
        <SearchListNode
          selectText="select category/course"
          actionText="add"
          items={contentListState.items}
          selectedItem={selectedNode}
          disabled={disabled}
          onSelect={handleSelect}
          onAdd={handleAdd}
        />
        <table className="table table-borderless">
          <tbody>
            {nodes &&
              nodes.map((node, i) =>
                <tr key={i}>
                  <td>
                    {node.name}
                  </td>
                  <td>
                    <IconClickDelete className="float-right" disabled={disabled} onClick={() => handleDelete(i)} />
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </Card>
    </React.Fragment>
  )
}
