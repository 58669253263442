import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../role/role.const'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListLogin } from '../../../../../api-new/state/content'

export function LoginRecycle() {
  const apiListLogin = useApiListLogin()

  const { accessDelete, accessPurge } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const handleReady = async () => await apiListLogin.get('deleted')

  const handleRestore = async item => {
    await apiListLogin.put(`${item.id}/restore`)
    await apiListLogin.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListLogin} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={accessDelete} />
                  <MenuPurge to={`/admin/login/list/recycle/purge/${row.id}`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
