import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Menu, MenuActivityLog, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeCourseEdit } from './node-course-edit'
import { NodeCourseDelete } from './node-course-delete'
import { Grid, GridItem3 } from '../../../../../../shared/chart/grid'
import { RoleAccessDisplayOrg } from '../../../../../../role/components/role-access-display'
import { CourseAssessment } from '../graphs/course-assessment'
import { AnalyticGeneralCourse } from '../graphs/analytic-general-course'
import { AnalyticCustomCourse } from '../graphs/analytic-custom-course'
import { CourseHeatmap } from '../graphs/course-heatmap'
import { NodeUserPermission } from '../../common/permission/node-user-permission'
import {
  IconCopy,
  IconCourse,
  IconEdit,
  IconFile,
  IconGroup,
  IconLink,
  IconMove,
  IconPlus,
  IconUser
} from '../../../../../../shared/icon/icon'
import { NodeUserPermissionAdd } from '../../common/permission/node-user-permission-add'
import { NodeGroupPermission } from '../../common/permission/node-group-permission'
import { NodeGroupPermissionAdd } from '../../common/permission/node-group-permission-add'
import { NodeCourseMove } from './node-course-move'
import { NodeCourseCopy } from './node-course-copy'
import { useMetadataType } from '../../common/hooks/hooks'
import { NodeCourseTemplate } from './node-course-template'
import { AnalyticLayout } from '../../../../../../view/common/analytic/page/course/analytic-legacy/layout'
import { HeatmapLayoutNode } from '../../../../../../view/heatmap/layout-node'
import { AssessmentRoute } from '../../../../../../view/common/assessment/route'
import { AnalyticRoute } from '../../../../../../view/common/analytic/page/course/analytic-course-custom/route'
import { useDataContext } from '../../data-context'
import { NodeAssetList } from '../../common/assets/node-asset-list'
import { NodeCourseEditSys } from './node-course-edit-sys'
import { LogEventOrgSubjectID } from '../../../../../../shared/page/log-event/log-event-org'
import { DisplaySysAdmin, useDisplaySysAdmin } from '../../../../../../role/role.component'
import { useModalActions } from '../../../../../../shared/component/modal-dialog'
import { CourseDeepLink } from '../../common/model/course-deep-link'

export const NodeCourseDetails = ({ parentURL }) => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.nodes.find(i => i.id === nodeID) || {}, [dataContext.nodes, nodeID])

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleAssessmentManage = useRoleAccessOrganization(ROLE_ORG.ASSESSMENT_MANAGE)
  const roleAnalytic = useRoleAccessOrganization(ROLE_ORG.ANALYTIC)

  const isCaffeine = useMetadataType(itemCurrent, 'CourseAssemblyName', 'caffeine')

  const displaySysAdmin = useDisplaySysAdmin()

  const modalActions = useModalActions('courseLink')
  const handleCourseLink = () => modalActions.show()

  const HeaderMenu = () =>
    <Menu>
      <MenuItemHeader text="Sys Admin" show={displaySysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="Org" show={displaySysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconLink} text="deep link" onClick={() => handleCourseLink()} show />
      <MenuItem Icon={IconUser} text="user permissions" to={`${url}/user-permission`} show={roleContent.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${url}/group-permission`} show={roleContent.accessRead} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={!isCaffeine} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconPlus} text="new template" to={`${url}/template`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show={roleContent.accessRead} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView text={itemCurrent.name} Icon={IconCourse}>
        <CardFullHeightScrollY title={itemCurrent.name} HeaderMenu={HeaderMenu}>
          <Grid>
            <RoleAccessDisplayOrg type={ROLE_ORG.ASSESSMENT_REPORT} accessRead>
              <GridItem3>
                <CourseAssessment nodeID={nodeID} showConfigButton={roleAssessmentManage.accessRead} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.ANALYTIC} accessRead>
              <GridItem3>
                <AnalyticGeneralCourse nodeID={nodeID} showConfigButton={roleAnalytic.accessUpdate} />
              </GridItem3>
              <GridItem3>
                <AnalyticCustomCourse nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
            <RoleAccessDisplayOrg type={ROLE_ORG.HEATMAP} accessRead>
              <GridItem3>
                <CourseHeatmap nodeID={nodeID} />
              </GridItem3>
            </RoleAccessDisplayOrg>
          </Grid>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      {itemCurrent &&
        <React.Fragment>
          <Route path={`${url}/user-permission`}>
            <NodeUserPermission node={itemCurrent} />
          </Route>

          <Route path={`${url}/user-permission/add`}>
            <NodeUserPermissionAdd node={itemCurrent} />
          </Route>

          <Route path={`${url}/group-permission`}>
            <NodeGroupPermission node={itemCurrent} />
          </Route>

          <Route path={`${url}/group-permission/add`}>
            <NodeGroupPermissionAdd node={itemCurrent} />
          </Route>

          <Route path={`${url}/move`}>
            <NodeCourseMove node={itemCurrent} />
          </Route>
        </React.Fragment>}

      <Route path={`${url}/edit`}>
        <NodeCourseEdit parentURL={url} nodeID={nodeID} />
      </Route>

      <DisplaySysAdmin>
        <Route path={`${url}/edit-sys`}>
          <NodeCourseEditSys parentURL={url} nodeID={nodeID} />
        </Route>
      </DisplaySysAdmin>

      <Route path={`${url}/delete`}>
        <NodeCourseDelete parentURL={parentURL} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/copy`}>
        <NodeCourseCopy nodeID={nodeID} parentURL={url} />
      </Route>

      <Route path={`${url}/template`}>
        <NodeCourseTemplate nodeID={nodeID} parentURL={url} />
      </Route>

      <DisplaySysAdmin>
        <Route path={`${url}/assets`}>
          <NodeAssetList parentURL={url} nodeID={nodeID} />
        </Route>
      </DisplaySysAdmin>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={nodeID} />
      </Route>

      <AnalyticRoute />
      <AnalyticLayout />
      <HeatmapLayoutNode />
      <AssessmentRoute />

      <CourseDeepLink targetID="courseLink" nodeID={nodeID} />
    </React.Fragment>
  )
}
