import React from 'react'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNode, useApiListNode } from '../../../../../../api-new/state/content'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { IconList } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { useDataContext } from '../../data-context'

export const NodeRecycle = ({ nodeID, types }) => {
  const { url } = useRouteMatch()

  const apiListNode = useApiListNode()
  const apiFormNode = useApiFormNode()

  const dataContext = useDataContext()

  const { accessDelete, accessPurge } = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const handleRestore = async id => {
    await apiListNode.put(`${id}/restore`)
    let result = await apiFormNode.get(id)
    dataContext.nodeInsert(result)

    if (nodeID) await apiListNode.get(`deleted-descendant/${nodeID}`)
    else await apiListNode.get('deleted-descendant')
  }

  const handleMatch = async () => {
    if (nodeID) {
      await apiListNode.get(`deleted-descendant/${nodeID}`)
    } else {
      await apiListNode.get('deleted-descendant')
    }
  }

  const initialFilterFn = items => {
    if (types) {
      return items.filter(i => types.includes(i.datatype))
    }

    return items
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListNode} initialFilterFn={initialFilterFn}>
            <Column field="name" sortable filter />
            <Column field="path" sortable filter />
            <Column field="datatype" header="Type" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
