import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useAssetList } from '../../../api/state'
import { FormatDate, FormatSize } from '../../../shared/legacy/format'
import { IconLinkDelete } from '../../../shared/icon/icon-link'
import { IconLinkEdit } from '../../../shared/icon/icon-link'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { IconList } from '../../../shared/icon/icon'
import { IconAdd } from '../../../shared/icon/icon'
import { NodeAssetAdd } from './node-asset-add'
import { NodeAssetEdit } from './node-asset-edit'
import { NodeAssetDelete } from './node-asset-delete'
import { Menu, MenuLink } from '../shared/menu'
import { useParamsInt } from '../../../shared/hooks/location'
import { Card } from '../../../shared/card/card'

export const NodeAssetList = () => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const [assetListActions, assetListState] = useAssetList()

  const handleMatch = async () => assetListActions.get(`${nodeID}/node`)

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconAdd} text="new asset" to={`${url}/asset-add`} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="assets" onMatch={handleMatch}>
        <Card title="assets" HeaderMenu={HeaderMenu}>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Length</th>
                <th>Created</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {assetListState.items.map((item, i) =>
                <tr key={i}>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    <FormatSize value={item.length} />
                  </td>
                  <td>
                    <FormatDate date={item.createdAt} />
                  </td>
                  <td>
                    <IconLinkEdit to={`${url}/asset-edit/${item.id}`} />
                    <IconLinkDelete to={`${url}/asset-delete/${item.id}`} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </BreadcrumbView>

      <Route path={`${url}/asset-add`}>
        <NodeAssetAdd parentUrl={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/asset-edit/:assetID`}>
        <NodeAssetEdit parentUrl={url} />
      </Route>

      <Route path={`${url}/asset-delete/:assetID`}>
        <NodeAssetDelete parentUrl={url} />
      </Route>
    </React.Fragment>
  )
}
