import React, { useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import {
  Form,
  FormText,
  FormSubmit,
  FormNumber,
  FormHiddenText,
  FormatLabel,
  FormYesNo,
  FormURL,
  FormHiddenBool
} from '../../../../../../shared/form/form'
import { useAppVersionForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useSelectValue } from '../shared/hooks'
import { RichTextEditor, useEditorValue } from './rich-text-editor/rich-text-editor'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { Menu, MenuItem } from '../../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { IconEdit, IconUpload } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AppVersionEdit = () => {
  const { appID, appStoreID, appVersionID } = useParamsInt()

  const [appVersionFormActions, appVersionFormState] = useAppVersionForm()

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const handlePath = async () => await appVersionFormActions.get(appVersionID)

  const handleSubmit = async data => {
    await appVersionFormActions.put(data)
    await appVersionFormActions.get(appVersionID)
  }

  const [useRemoteURL, updateUseRemoteURL] = useSelectValue(appVersionFormState.form.useRemoteURL)
  const showStorageURL = useMemo(() => !!appVersionFormState.form.storageURL, [appVersionFormState.form])

  const [notesRaw, updateNotesValue] = useState()
  const [notesHTML, updateNodeHTML] = useState()
  const [notesTML, updateNodesTML] = useState()

  let editorValue = useEditorValue(appVersionFormState.form.notesRaw)

  const handleEditorUpdate = (value, valueHTML, valueTMP) => {
    JSON.stringify(value)

    updateNotesValue(value)
    updateNodeHTML(valueHTML)
    updateNodesTML(valueTMP)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem
        Icon={IconUpload}
        text="upload"
        to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${appVersionID}/upload`}
        show={roleApp.accessUpdate}
      />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={appVersionFormState.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={appVersionFormState.form.name} HeaderMenu={HeaderMenu}>
          <Form state={appVersionFormState.form} onSubmit={handleSubmit}>
            <FormHiddenText name="notesRaw" value={notesRaw} shouldDirty />
            <FormHiddenText name="notesHTML" value={notesHTML} />
            <FormHiddenText name="notesTMP" value={notesTML} />

            <FormHiddenBool name="useRemoteURL" value={useRemoteURL} shouldDirty />

            <FormText label="name" name="name" />
            <FormNumber label="code" name="code" />
            <FormYesNo label="required" name="isRequired" />
            <FormYesNo label="active" name="isActive" />

            <FormatLabel label="build download URL">
              <SelectIsRemoteURL value={useRemoteURL} onChange={updateUseRemoteURL} />
            </FormatLabel>

            <FormURL label="remote build URL" name="remoteURL" hidden={!useRemoteURL} />

            <FormURL label="storage URL" name="storageURL" readOnly hidden={!showStorageURL} />

            {/* 
            <FormatLabel label="notes">
              <TextEditor initialValue={editorValue} onChange={handleEditorUpdate} />
              <div className="border p-3">
                {notesSerialized}
              </div>
            </FormatLabel> */}

            <FormatLabel label="notes">
              <RichTextEditor initialValue={editorValue} onChange={handleEditorUpdate} />
            </FormatLabel>

            <FormSubmit text="save version" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const SelectIsRemoteURL = ({ value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.value === 'true')

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value="false">Local URL</option>
      <option value="true">Remote URL</option>
    </select>
  )
}
