import React, { useRef, useState } from 'react'
import { SizeDisplay } from '../size-display'
import { FileDragDrop } from './file-drag-drop'

const fileSizeError = 'File too large'

export const FileSelect = ({ children, accept, maxSize, onSelect }) => {
  const ref = useRef(undefined)

  const [isHover, updateIsHover] = useState(false)
  const [error, updateError] = useState('')

  const handleChange = e => {
    if (e.target.files && e.target.files.length) {
      let file = e.target.files[0]

      if (maxSize !== undefined && file.size > maxSize) {
        updateError(fileSizeError)
        return
      }

      onSelect && onSelect(file)
    }
  }

  const handleClick = () => {
    updateError('')
    ref.current.click()
  }

  const handleHover = isHover => {
    updateIsHover(isHover)
  }

  const handleDrop = file => {
    if (maxSize !== undefined && file.size > maxSize) {
      updateError(fileSizeError)
      return
    }

    onSelect && onSelect(file)
  }

  return (
    <React.Fragment>
      <FileDragDrop onHover={handleHover} onDrop={handleDrop}>
        <div className={`file-select ${isHover && 'file-select-drag'}`} onClick={handleClick}>
          <input type="file" ref={ref} hidden onChange={handleChange} accept={accept} />
          {children && children}
          {!children &&
            <div className="text-center">
              Drop file or click here to select.
              {maxSize &&
                <span className="px-1">
                  Max Size <SizeDisplay size={maxSize} />
                </span>}
              {error &&
                <div className="text-center text-danger">
                  {error}
                </div>}
            </div>}
        </div>
      </FileDragDrop>
    </React.Fragment>
  )
}
