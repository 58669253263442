import React from 'react'
import { useFormDisable } from '../../../shared/form/form'
import { FormatLabel } from '../../../shared/form/format-label'

const accessData = [
  {
    id: 'null',
    name: 'Only assigned users or groups',
    displayOrder: 1
  },
  // {
  //   id: 1,
  //   name: 'Hidden',
  //   displayOrder: 2
  // },
  {
    id: 3,
    name: 'All users',
    displayOrder: 3
  },
  {
    id: 2,
    name: 'All users in this organization',
    displayOrder: 4
  }
]

export const sortDisplayOrder = (a, b) => {
  if (a.displayOrder < b.displayOrder) return -1
  else if (a.displayOrder > b.displayOrder) return 1
  else return 0
}

export const SelectCourseAssess = ({ label, value, readOnly, onChange }) => {
  const handleChange = e => (onChange && e.target.value === 'null' ? onChange(null) : onChange(parseInt(e.target.value)))

  const formDisabled = useFormDisable()

  return (
    <React.Fragment>
      <FormatLabel label={label}>
        <select className="form-control text-capitalize" value={value} onChange={handleChange} readOnly={readOnly} disabled={formDisabled}>
          {accessData &&
            accessData.sort(sortDisplayOrder).map((item, i) =>
              <option key={i} value={item.id}>
                {item.name}
              </option>
            )}
        </select>
      </FormatLabel>
    </React.Fragment>
  )
}
