import React from 'react'

const cache = {}

export const CacheContext = React.createContext()

export const CacheProvider = ({ children }) => {
  const get = key => cache[key]
  const set = (key, data) => (cache[key] = data)

  const getBase64 = blob => {
    return new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }

  return (
    <CacheContext.Provider value={{ get, set, getBase64 }}>
      {children}
    </CacheContext.Provider>
  )
}
