import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListUserLogin } from '../../../../../../api-new/state/content'

export const GroupListUserAdd = () => {
  const { groupID } = useParamsInt()

  const apiListUserLogin = useApiListUserLogin()

  const apiListGroupUser = useApiListGroupUser()

  const handleMatch = async () => {
    await apiListGroupUser.get(`${groupID}/group`)
    await apiListUserLogin.get()
  }

  const handleAdd = async item => {
    await apiListGroupUser.post({ groupID: groupID, userID: item.id })
    await apiListGroupUser.get(`${groupID}/group`)
  }

  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const handleInitialFilter = items =>
    items.filter(userLogin => !apiListGroupUser.items.some(groupUser => groupUser.userID === userLogin.id))

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onMatch={handleMatch}>
        <CardFullHeightScrollY title="users">
          <TableBuilder apiList={apiListUserLogin} initialFilterFn={handleInitialFilter}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd onClick={() => handleAdd(row)} show={roleGroupUser.accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
