import React from 'react'
import { Menu, MenuDelete, MenuDetails, MenuItem } from '../../../../../../shared/menu/menu'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { useApiListLoginAuthStatus } from '../../../../../../api-new/state/auth'
import { TableBuilder, Column, ColumnDateTime, ColumnMenu, ColumnFormat } from '../../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { ModalDialog, useModalActions } from '../../../../../../shared/component/modal-dialog'
import { ButtonPrimary } from '../../../../../../shared/legacy/button'
import { useApiFormLogin } from '../../../../../../api-new/state/content'
import { BadgeDanger, BadgeSuccess } from '../../../../../../shared/component/badge'

export const LoginAuthList = () => {
  const { loginID } = useParamsInt()

  const { url } = useRouteMatch()

  const apiFormLogin = useApiFormLogin()
  const apiListLoginAuthStatus = useApiListLoginAuthStatus()

  const { accessRead, accessDelete } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const handleReady = () => {
    apiFormLogin.get(loginID)
    apiListLoginAuthStatus.get(`${loginID}/login`)
  }

  const handleDelete = async item => {
    await apiListLoginAuthStatus.delete(`${item.refreshToken}`)
    await apiListLoginAuthStatus.get(`${loginID}/login`)
  }

  const deleteAllModal = useModalActions('delete-all')

  const handleDeleteAll = () => deleteAllModal.show()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem text="delete all" onClick={handleDeleteAll} show pin />
    </Menu>

  const handleConfirm = async () => {
    await apiListLoginAuthStatus.delete(`${loginID}/all`)
    await apiListLoginAuthStatus.get(`${loginID}/login`)
    deleteAllModal.hide()
  }

  return (
    <React.Fragment>
      <ModalDialog target="delete-all" header="Confirm">
        <div>
          Delete all auth logins for {apiFormLogin.form.email}
        </div>
        <div className="float-end">
          <ButtonPrimary text="Confirm" onClick={handleConfirm} />
        </div>
      </ModalDialog>

      <BreadcrumbView Icon={IconList} text="auths">
        <CardFullHeightScrollY title="login auths" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListLoginAuthStatus} onReady={handleReady}>
            <ColumnDateTime field="createdOn" sortable filter />
            <ColumnDateTime field="updatedOn" sortable filter />
            <Column field="ipAddress" header="ip address" sortable filter />
            <ColumnFormat
              header="test"
              selector={row => (row.isValid ? <BadgeSuccess value="active" /> : <BadgeDanger value="expired" />)}
            />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails to={`${url}/details/${row.refreshToken}`} show={accessRead} />
                  <MenuDelete onClick={() => handleDelete(row)} show={accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
