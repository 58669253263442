import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../shared/form/form'
import { useApiFormAsset } from '../../../../../api-new/state/content'
import { FormRawJson } from '../../../../../shared/form/form-control/form-raw-json'

export const AssetAdd = ({ onAdd }) => {
  const { nodeID } = useParamsInt()

  const apiFormAsset = useApiFormAsset()

  const handleSubmit = async data => {
    let asset = await apiFormAsset.post(data)
    onAdd(asset)
  }
  return (
    <React.Fragment>
      <BreadcrumbView text="add">
        <CardFullHeightScrollY title="add">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} />
            <FormText label="name" name="name" required />
            <FormRawJson label="metadata" name="metaData" />
            <FormSubmitBack />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
