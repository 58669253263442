import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDetails } from '../../../../../shared/icon/icon'
import {
  DetailsDisplayDateUnix,
  DetailsDisplayTable,
  DetailsDisplayText
} from '../../../../../shared/legacy/details-display/details-display'
import { useApiFormSendgrid } from '../../../../../api-new/state/content'
import { useLoad } from '../../../../../shared/hooks/load'
import { Card } from '../../../../../shared/card/card'

export const SendgridEventDetails = () => {
  const { sendgridEventID } = useParams()

  const apiFormSendgrid = useApiFormSendgrid()

  useLoad(() => apiFormSendgrid.get(sendgridEventID), [sendgridEventID])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text="sendgrid events">
        <Card title="sendgrid event details">
          <DetailsDisplayTable>
            <DetailsDisplayText label="template" value={apiFormSendgrid.form.templateName} />
            <DetailsDisplayText label="email" value={apiFormSendgrid.form.email} />
            <DetailsDisplayDateUnix label="sent at" value={apiFormSendgrid.form.timestamp} />
            <DetailsDisplayText label="event" value={apiFormSendgrid.form.event} />
            <DetailsDisplayText label="ip" value={apiFormSendgrid.form.ip} />
            <DetailsDisplayText label="reason" value={apiFormSendgrid.form.reason} />
            <DetailsDisplayText label="response" value={apiFormSendgrid.form.response} />
            <DetailsDisplayText label="status" value={apiFormSendgrid.form.status} />
            <DetailsDisplayText label="attempt" value={apiFormSendgrid.form.attempt} />
            <DetailsDisplayText label="type" value={apiFormSendgrid.form.type} />
            <DetailsDisplayText label="bounce classification" value={apiFormSendgrid.form.bounceClassification} />
          </DetailsDisplayTable>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
