import React from 'react'
import { useHistory } from 'react-router-dom'

import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'

export const NodeCourseDelete = ({ parentURL, nodeID }) => {
  const history = useHistory()

  const dataContext = useDataContext()

  const apiFormNodeCourse = useApiFormNodeCourse()

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  const handleSubmit = async () => {
    await apiFormNodeCourse.delete(nodeID)

    const moveTo = () => {
      dataContext.unsubscribe(moveTo)
      history.push(parentURL)
    }

    dataContext.subscribe(moveTo)
    dataContext.nodeRemoveID(nodeID)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete course" onMatch={handleMatch}>
        <Card title={apiFormNodeCourse.form.name}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete course" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
