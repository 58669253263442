import React, { useContext, useMemo } from 'react'
import { AuthContext } from '../context/auth.context'
import { useQueryParam } from '../shared/hooks/location'

export const DisplaySysRoot = ({ children }) => {
  const authContent = useContext(AuthContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const show = useMemo(() => authContent.state.isSysRoot, [authContent.state])

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}

export const DisplaySysAdmin = ({ children }) => {
  const authContent = useContext(AuthContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const show = useMemo(() => authContent.state.isSysRoot || authContent.state.isSysAdmin, [authContent.state])

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}

export const useDisplaySysAdmin = () => {
  const authContent = useContext(AuthContext)
  return authContent.state.isSysRoot || authContent.state.isSysAdmin
}

export const DisplayBeta = ({ children }) => {
  let beta = useQueryParam('beta')
  const display = useMemo(() => beta === 'true', [beta])

  return (
    <React.Fragment>
      {display && children}
    </React.Fragment>
  )
}
