import React, { useCallback, useMemo } from 'react'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { ROLE_ORG } from '../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../role/role.hook'
import { useMetadataType } from '../common/hooks/hooks'
import { ThumbnailImage } from '../common/shared/thumbnail-image'
import { Menu, MenuActivityLog, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../shared/menu/menu'
import { IconCopy, IconCourse, IconDelete, IconEdit, IconFile, IconLink, IconMove } from '../../../../../shared/icon/icon'
import { GridItem } from '../common/shared/grid-item'
import { useDataContext } from '../data-context'
import { useDisplaySysAdmin } from '../../../../../role/role.component'
import { useModalActions } from '../../../../../shared/component/modal-dialog'
import { CourseDeepLink } from '../common/model/course-deep-link'

export const NodeCourseGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const dataContext = useDataContext()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const type = useMemo(() => (node ? JSON.parse(node.metaData.toLowerCase()).datatype : null), [node])

  const to = useMemo(
    () => {
      if (type === 'category') {
        return `${url}/node/${node.id}`
      } else if (type === 'course') {
        return `${url}/course/${node.id}`
      } else if (type === 'streaming_video') {
        return `${url}/course/${node.id}`
      }
    },
    [url, type, node]
  )

  const video = useMemo(() => type === 'streaming_video', [type])

  const isCaffeine = useMetadataType(node, 'CourseAssemblyName', 'caffeine')

  const Thumbnail = useCallback(() => <ThumbnailImage nodeID={node.id} assets={dataContext.assets} cache={dataContext.thumbnailCache} />, [
    node,
    dataContext.assets,
    dataContext.thumbnailCache
  ])

  const displaySysAdmin = useDisplaySysAdmin()

  const courseLinkID = useMemo(() => `courseLink-${node.id}`, [node.id])
  const modalActions = useModalActions(courseLinkID)
  const handleCourseLink = () => modalActions.show()

  const FooterMenu = () =>
    <Menu small>
      <MenuItemHeader text="Sys Admin" show={displaySysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/course/${node.id}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/course/${node.id}/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="Org" show={displaySysAdmin} />
      <MenuEdit to={`${url}/course/${node.id}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem Icon={IconLink} text="deep link" onClick={() => handleCourseLink()} show />
      <MenuItem Icon={IconMove} text="move" to={`${url}/course/${node.id}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/course/${node.id}/copy`} disabled={!isCaffeine} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconDelete} text="delete" to={`${url}/course/${node.id}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${url}/course/${node.id}/activity`} show={roleContent.accessRead} />
    </Menu>

  return (
    <React.Fragment>
      <GridItem Thumbnail={Thumbnail} Icon={IconCourse} FooterMenu={FooterMenu} title={node.name} to={to} video={video} />
      <CourseDeepLink targetID={courseLinkID} nodeID={node.id} />
    </React.Fragment>
  )
}
