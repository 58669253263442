import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'
import { useApiFormRole, useApiListRoleScope } from '../../../../../api-new/state/content'

export const RoleAdd = () => {
  const history = useHistory()

  const apiFormRole = useApiFormRole()
  const apiListRoleScope = useApiListRoleScope()

  const handleMatch = async () => await apiListRoleScope.get('type')

  const handleSubmit = async data => {
    await apiFormRole.post(data)
    history.goBack()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onMatch={handleMatch}>
        <Card title="add role">
          <Form onSubmit={handleSubmit}>
            <FormText label="role" name="role" autoFocus />
            <FormSelectObj label="Scope" name="scopeType" state={apiListRoleScope} itemName="typeDisplay" itemValue="type" required />
            <FormTextArea label="description" name="description" />
            <FormCheckbox label="Auto Owner" name="isAutoOwner" />
            <FormCheckbox label="Auto User" name="isAutoUser" />
            <FormCheckbox label="Read Only" name="isReadOnly" />
            <FormSubmitBack text="add role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
