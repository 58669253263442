import React from 'react'
import { useHistory } from '../../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNodeCategory } from '../../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { IconDelete } from '../../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../../shared/form/form'
import { useDataContext } from '../../../data-context'

export const NodeCategoryDelete = ({ grandParentURL, node }) => {
  const history = useHistory()

  const apiFormNodeCategory = useApiFormNodeCategory()

  const dataContext = useDataContext()

  const handleMatch = async () => await apiFormNodeCategory.get(node.id)

  const handleSubmit = async () => {
    await apiFormNodeCategory.delete(node.id)
    dataContext.nodeRemove(node)
    history.push(grandParentURL)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiFormNodeCategory.form.node}>
          <div className="py-2">This is a destructive action</div>
          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete category" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
