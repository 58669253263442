import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconRole } from '../../../../../shared/icon/icon'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { Column, ColumnYesNo, ColumnLink, ColumnMenu, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListRole } from '../../../../../api-new/state/content'

export const RoleList = () => {
  const apiListRole = useApiListRole()

  const handleReady = async () => await apiListRole.get()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role" to={'/root/role/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconRole} text="ROLES">
        <CardFullHeightScrollY title="Roles" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListRole} onReady={handleReady}>
            <ColumnLink field="role" to={row => `/root/role/list/${row['scopeType']}/${row.role}`} sortable filter />
            <Column field="scopeType" sortable filter />
            <ColumnYesNo field="isAutoOwner" header="Auto Owner" sortable filter />
            <ColumnYesNo field="isAutoUser" header="Auto User" sortable />
            <ColumnYesNo field="isReadOnly" header="Read Only" sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role/list/edit/${row.id}/delete`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
