import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useNodeForm } from './state'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconAdd } from '../../shared/icon/icon'
import { Form, FormHiddenNumber, FormText, FormSubmitCancel } from '../../shared/form/form'
import { Card } from '../../shared/card/card'

export function NodeAdd({ parentUrl }) {
  const history = useHistory()
  const { nodeID } = useParams()

  const [nodeFormActions, nodeFormState] = useNodeForm()

  const handleSubmit = async data => {
    let node = await nodeFormActions.post(data)
    history.push(`${parentUrl}/edit/${node.id}`)
  }

  const handleCancel = () => history.push(`${parentUrl}`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add">
          <Form submitting={nodeFormState.submitting} onSubmit={handleSubmit}>
            <FormHiddenNumber name="parentID" value={nodeID} parseInt />
            <FormText label="name" name="name" required />
            <FormSubmitCancel text="add node" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
