import React, { useContext } from 'react'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiListLoginOrgUser } from '../../../../../../api-new/state/content'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import {
  Menu,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuItem,
  MenuItemHeader,
  MenuItemSeperator,
  MenuPurge,
  MenuRestore
} from '../../../../../../shared/menu/menu'
import { Column, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { IconEdit, IconGoto, IconImpersonate } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'

export function LoginOrganizationList() {
  const { loginID } = useParamsInt()

  const { url } = useRouteMatch()

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const apiListLoginOrgUser = useApiListLoginOrgUser()

  const { accessUpdate, accessDelete, accessPurge } = useRoleAccessSystem(ROLE_SYS.LOGIN)
  // const organizationAccess = useRoleAccessSystem(ROLE_SYS.ORGANIZATION)

  const handleReady = () => apiListLoginOrgUser.get(`${loginID}/login`)

  let switchAccount = async item => {
    const authToken = await authContext.actions.impersonateUser(item.organizationID, item.userID)
    window.open(`${process.env.REACT_APP_SITE}/redirect/impersonate/${authToken.refreshToken}`)
  }

  let gotoUserOrg = async item => {
    await authContext.actions.changeOrganization({ organizationID: item.organizationID, userID: item.selfUserID })
    history.push(`/user/list/edit/${item.userID}`)
  }

  let handleRestore = async item => {
    await apiListLoginOrgUser.put(`${item.userID}/restore`)
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  let handlePurge = async item => {
    await apiListLoginOrgUser.delete(`${item.userID}/purge`)
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  const handleDelete = async item => {
    await apiListLoginOrgUser.delete(`${item.userID}/delete`)
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  const addSelfUser = async item => {
    await apiListLoginOrgUser.post({ organizationID: item.organizationID, loginID: authContext.state.loginID })
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  const removeSelfUser = async item => {
    await apiListLoginOrgUser.delete(item.selfUserID)
    await apiListLoginOrgUser.get(`${loginID}/login`)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="Add" to={`${url}/add`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="organizations">
        <CardFullHeightScrollY title="organizations" height={500} HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListLoginOrgUser} hideFloatingFilter onReady={handleReady}>
            <Column field="organizationName" name="organization" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItemHeader text="Me" show />
                  <MenuAdd text="Add User Me" onClick={() => addSelfUser(row)} show={!row.isMember} pin={false} />
                  <MenuDelete text="Remove User Me" onClick={() => removeSelfUser(row)} show={row.isMember} />
                  <MenuItem Icon={IconGoto} text="Goto User/Org" onClick={() => gotoUserOrg(row)} show disabled={!row.isMember} />

                  <MenuItemSeperator show />
                  <MenuItemHeader text="Org User" show />
                  <MenuItem Icon={IconImpersonate} text="Impersonate User" onClick={() => switchAccount(row)} show />

                  <MenuEdit
                    text="Edit User"
                    to={`/admin/login/list/edit/${loginID}/organizations/user/${row.userID}`}
                    show={accessUpdate}
                  />

                  <MenuDelete text="Delete User" onClick={() => handleDelete(row)} show={accessDelete && !row.isDeleted} />
                  <MenuRestore onClick={() => handleRestore(row)} show={accessDelete && row.isDeleted} />
                  <MenuPurge onClick={() => handlePurge(row)} show={accessPurge && row.isDeleted} pin />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
