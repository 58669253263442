import React from 'react'
import { IconButtonLink } from '../../../shared/icon/icon-button-link'

export const NodePathButton = ({ text, path }) => {
  return (
    <React.Fragment>
      {path && <IconButtonLink text={text} to={path} />}
    </React.Fragment>
  )
}
