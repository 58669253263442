import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useAppVersionForm, useChunkBuildForm, useFileBuildForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { StorageChunk } from '../shared/storage-chunk/storage-chunk'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AppVersionUpload = () => {
  const { appVersionID } = useParamsInt()

  const [appVersionFormActions, appVersionFormState] = useAppVersionForm()

  const handlePath = async () => await appVersionFormActions.get(appVersionID)

  const handleComplete = async details => {
    appVersionFormState.form.storageKey = details.storageKey
    await appVersionFormActions.put(appVersionFormState.form)
    await appVersionFormActions.get(appVersionID)
  }

  const handleDelete = async () => {
    if (appVersionFormState.form.storageKey) {
      appVersionFormState.form.storageKey = null
      await appVersionFormActions.put(appVersionFormState.form)
      await appVersionFormActions.get(appVersionID)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="upload" onPath={handlePath}>
        <CardFullHeightScrollY title="Build Upload">
          <StorageChunk
            useChunkApiForm={useChunkBuildForm}
            useFileApiForm={useFileBuildForm}
            storageKey={appVersionFormState.form.storageKey}
            storeKey={appVersionFormState.form.storeKey}
            onComplete={handleComplete}
            onDelete={handleDelete}
          />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
