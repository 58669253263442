import React, { useMemo } from 'react'

const kb = 1024
const mb = kb * 1024
const gb = mb * 1024

export const SizeDisplay = ({ size }) => {
  const display = useMemo(
    () => {
      if (size >= gb) {
        return `${(size / gb).toFixed(0)} GB`
      } else if (size >= mb) {
        return `${(size / mb).toFixed(0)} MB`
      } else {
        return `${(size / kb).toFixed(0)} KB`
      }
    },
    [size]
  )

  return (
    <React.Fragment>
      {display}
    </React.Fragment>
  )
}
