import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useAssetForm } from '../../../api/state'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { Form, FormSubmitCancel, FormTextConfirm } from '../../../shared/form/form'
import { useParamsInt } from '../../../shared/hooks/location'
import { IconAdd } from '../../../shared/icon/icon'
import { IconDelete } from '../../../shared/icon/icon'
import { Menu, MenuLink } from '../shared/menu'
import { Card } from '../../../shared/card/card'

export const NodeAssetDelete = ({ parentUrl }) => {
  const history = useHistory()

  const { assetID } = useParamsInt()

  const [assetFormActions, assetFormState] = useAssetForm()

  const title = useMemo(() => `delete ${assetFormState.form.name}`, [assetFormState.form.name])

  const handleMatch = () => assetFormActions.get(assetID)

  const handleSubmit = async data => {
    await assetFormActions.delete(data.id)
    history.push(parentUrl)
  }

  const handleCancel = () => history.push(parentUrl)

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconAdd} text="new asset" to={`${parentUrl}/asset-add`} />
      <MenuLink Icon={IconDelete} text="edit asset" to={`${parentUrl}/asset-edit/${assetID}`} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text={title} onMatch={handleMatch}>
        <Card title={title} HeaderMenu={HeaderMenu}>
          <Form state={assetFormState.form} onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitCancel text="edit asset" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
