import React from 'react'
import { Route } from 'react-router-dom'
import { OpenAuth } from '../../view-open/open-auth'
import { OpenClient } from '../../view-open/open-client'
import { OpenLink } from '../../view-open/open-link'
import { OpenRegister } from '../../view-open/open-register'

export const RouteOpen = () => {
  return (
    <React.Fragment>
      <Route path="/open/register" component={OpenRegister} />
      <Route path="/open/link" component={OpenLink} />
      <Route path="/open/auth" component={OpenAuth} />
      <Route path="/open/client" component={OpenClient} />
    </React.Fragment>
  )
}
