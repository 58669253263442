import React from 'react'
import { IconTimes } from '../../icon/icon'

export const TableInputText = ({ placeholder, value, onChange, onEnter, onClear }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handleKeyUp = e => e.code === 'Enter' && onEnter && onEnter(e.target.value)
  const handlerClear = () => onClear && onClear()

  return (
    <React.Fragment>
      <div className="input-group py-2">
        <input className="form-control" value={value} onChange={handleChange} onKeyUp={handleKeyUp} placeholder={placeholder} autoFocus />
        <div className="input-group-append" onClick={handlerClear}>
          <div className="input-group-text">
            <IconTimes />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
