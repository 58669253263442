import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListAppVersion } from '../../../../../../api-new/state/config'

export const AppVersionRecycle = () => {
  const { appID, appStoreID } = useParamsInt()

  const apiListAppVersion = useApiListAppVersion()

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const handleReady = async () => await apiListAppVersion.get('deleted')

  const handleRestore = async id => {
    await apiListAppVersion.put(`${id}/restore`)
    await apiListAppVersion.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListAppVersion} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={roleApp.accessDelete} />
                  <MenuPurge
                    to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/recycle/purge/${row.id}`}
                    show={roleApp.accessPurge}
                    pin
                  />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
