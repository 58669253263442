import React from 'react'
import { GridFrame } from './grid/grid-frame'

export const Root = () => {
  return (
    <React.Fragment>
      <GridFrame />
    </React.Fragment>
  )
}
