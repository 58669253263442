import { useMemo } from 'react'

export const useGetByDataType = (dataType, assets) => {
  return useMemo(
    () => {
      return assets.find(asset => {
        if (!asset.metaData) {
          return false
        }

        let metaData = JSON.parse(asset.metaData)

        if (!metaData.DataType) {
          return false
        }

        return dataType.toLowerCase() === metaData.DataType.toLowerCase()
      })
    },
    [dataType, assets]
  )
}
