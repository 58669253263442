import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconDetails } from '../../../../../../shared/icon/icon'
import { Card } from '../../../../../../shared/card/card'
import { Menu, MenuDelete } from '../../../../../../shared/menu/menu'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { useApiFormAuthLogin } from '../../../../../../api-new/state/auth'

export const LoginAuthDetails = () => {
  const history = useHistory()
  const { refreshToken } = useParams()

  const apiFormAuthLogin = useApiFormAuthLogin()

  const { accessDelete } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const handleMatch = async () => await apiFormAuthLogin.get(`${refreshToken}`)

  const handleDelete = async () => {
    await apiFormAuthLogin.delete(`${refreshToken}`)
    history.goBack()
  }

  const HeaderMenu = () =>
    <Menu small>
      <MenuDelete onClick={handleDelete} show={accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text="login auth" onMatch={handleMatch}>
        <Card title="Login Auth" HeaderMenu={HeaderMenu}>
          <div className="mb-2">
            <label className="form-label">Refresh Token</label>
            <div className="form-control">
              {apiFormAuthLogin.form.refreshToken}
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">Login</label>
            <div className="form-control">
              {apiFormAuthLogin.form.login}
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">Created On</label>
            <div className="form-control">
              {apiFormAuthLogin.form.createdOn}
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">Updated On</label>
            <div className="form-control">
              {apiFormAuthLogin.form.updatedOn}
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">IP Address</label>
            <div className="form-control">
              {apiFormAuthLogin.form.ipAddress}
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">IP Location</label>
            <div className="form-control">
              {apiFormAuthLogin.form.ipLocation}
            </div>
          </div>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
