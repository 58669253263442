export const ROLE_ROOT = {
  ROLES: 'ROLES',
  SYS_ADMIN: 'SYS_ADMIN'
}

export const ROLE_SYS = {
  APP: 'APP',
  NODE: 'NODE',
  ORGANIZATION: 'ORGANIZATION',
  AUTH_STATUS: 'AUTH_STATUS',
  LOGIN: 'LOGIN',
  PROVIDER: 'PROVIDER',
  SENDGRID_EVENT: 'SENDGRID_EVENT',
  ERROR_LOG: 'ERROR_LOG'
}

export const ROLE_ORG = {
  DASHBOARD: 'DASHBOARD',
  CONTENT: 'CONTENT',
  TEMPLATE: 'TEMPLATE',
  USER: 'USER',
  GROUP: 'GROUP',
  GROUP_USER: 'GROUP_USER',
  NODE_USER: 'NODE_USER',
  NODE_GROUP: 'NODE_GROUP',
  HEATMAP: 'HEATMAP',
  ANALYTIC: 'ANALYTIC',
  ASSESSMENT_MANAGE: 'ASSESSMENT_MANAGE',
  ASSESSMENT_REPORT: 'ASSESSMENT_REPORT',
  TRADESHOW: 'TRADESHOW',
  DOC: 'DOC',
  EDITOR: 'EDITOR',
  META_QUERY: 'META_QUERY',
  STORAGE_TRANSFER: 'STORAGE_TRANSFER'
}
