import React, { useEffect, useState } from 'react'
import { useGroupList, useNodeGroupList } from '../state'
import TableFilter from '../../../shared/legacy/table-filter'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { useParams } from 'react-router'
import { IconButtonClickRemove } from '../../../shared/icon/icon-button-click'
import { SearchListGroup } from '../../../shared/legacy/dropdown-search/search-list-group'
import { IconEdit } from '../../../shared/icon/icon'

export function NodeListGroup() {
  const { nodeID } = useParams()

  const [nodeGroupListActions, nodeGroupListState] = useNodeGroupList()

  useEffect(
    () => {
      nodeGroupListActions.get(`${nodeID}/node`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const handleAdd = async group => {
    await nodeGroupListActions.post({ nodeID: parseInt(nodeID), groupID: group.id })
    await nodeGroupListActions.get(`${nodeID}/node`)
  }

  const handleDelete = async (nodeID, groupID) => {
    await nodeGroupListActions.delete(`${parseInt(nodeID)}/${parseInt(groupID)}`)
    await nodeGroupListActions.get(`${nodeID}/node`)
  }

  let links = item =>
    <div>
      <IconButtonClickRemove small onClick={() => handleDelete(item.nodeID, item.groupID)} />
    </div>

  let columns = [
    { name: 'Name', selector: 'groupName', grow: 1, sortable: true },
    { name: '', right: true, sortable: false, cell: row => links(row) }
  ]

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="groups">
        <TableFilter
          title="groups"
          HeaderItem={<Header onAdd={handleAdd} />}
          columns={columns}
          data={nodeGroupListState.items}
          loading={nodeGroupListState.submitting}
        />
      </BreadcrumbView>
    </React.Fragment>
  )
}

const Header = ({ onAdd }) => {
  const [selectedGroup, updateSelectedGroup] = useState()

  const [groupListActions, groupListState] = useGroupList()

  useEffect(() => {
    groupListActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = group => updateSelectedGroup(group)

  const handleAdd = async item => {
    updateSelectedGroup(null)
    onAdd && onAdd(item)
  }

  return (
    <SearchListGroup
      dropLeft
      selectText="select group"
      actionText="add"
      items={groupListState.items}
      selectedItem={selectedGroup}
      onSelect={handleSelect}
      onAdd={handleAdd}
    />
  )
}
