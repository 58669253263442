import React from 'react'
import { Route } from 'react-router-dom'
import { Delete } from '../../../../shared/page/page-delete'
import { RoleAdd } from './content/role-add'
import { RoleEdit } from './content/role-edit'
import { RoleList } from './content/role-list'
import { RoleListTypeOrg } from './content/role-list-type-org'
import { RoleListTypeSys } from './content/role-list-type-sys'
import { useApiFormRole } from '../../../../api-new/state/content'

export const LayoutRole = () => {
  return (
    <React.Fragment>
      <Route path="/root/role/list" component={RoleList} />

      <Route path="/root/role/list/org/:role" component={RoleListTypeOrg} />
      <Route path="/root/role/list/sys/:role" component={RoleListTypeSys} />

      <Route path="/root/role/list/add" component={RoleAdd} />
      <Route path="/root/role/list/edit/:roleID" component={RoleEdit} />

      <Route path="/root/role/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormRole} titleParam="role" successPath="/root/role/list" />
      </Route>
    </React.Fragment>
  )
}
