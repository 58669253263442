import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { AppLinks } from '../../shared/legacy/app-links'
import { Card } from '../../shared/card/card'

export const InviteSuccess = () => {
  return (
    <React.Fragment>
      <BreadcrumbView text="register success">
        <Card title="Register Success">
          <AppLinks />
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
