import React, { useEffect } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
// import { useApiFormSendgrid } from '../../../../../api-new/state/content'
import { useApiListSendgrid } from '../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnUnixDateTime } from '../../../../../shared/table-builder/table-builder'
import { Menu, MenuDetails } from '../../../../../shared/menu/menu'
import { IconEmail } from '../../../../../shared/icon/icon'

export const SendgridEventList = () => {
  const apiListSendgrid = useApiListSendgrid()
  // const apiFormSendgrid = useApiFormSendgrid()

  const handleReady = () => apiListSendgrid.get()

  useEffect(
    () => {
      // let hold = apiListSendgrid.items
      // debugger
    },
    [apiListSendgrid.items]
  )

  // const handleSend = id => apiFormSendgrid.get(`${id}/resend`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEmail} text="SENDGRID EVENTS">
        <CardFullHeightScrollY title="events">
          <TableBuilder apiList={apiListSendgrid} onReady={handleReady}>
            <ColumnUnixDateTime field="timestamp" header="Sent At" sortable filter sort="desc" />
            <Column field="email" sortable filter />
            <Column field="templateName" header="Template" sortable filter />
            <Column field="event" sortable filter />
            {/* <Column field="reason" sortable filter /> */}
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails to={`/admin/sendgrid-event/list/details/${row.id}`} show pin />
                  {/* <MenuItem text="resend" Icon={IconEmail} onClick={() => handleSend(row.id)} show /> */}
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
