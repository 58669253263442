import React, { Children, cloneElement } from 'react'

export const Dropdown = ({ children, show, onNoOption, onOver }) => {
  const handleMouseEnter = () => onOver && onOver(true)
  const handleMouseLeave = () => onOver && onOver(false)

  return (
    <ul className={`dropdown-menu ${show && 'show'}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children.length === 0
        ? <li className="dropdown-item" onClick={onNoOption}>
            <span className="text-center">NO OPTIONS</span>
          </li>
        : Children.map(children, (child, i) =>
            <React.Fragment key={i}>
              {cloneElement(child, {})}
            </React.Fragment>
          )}
    </ul>
  )
}

export const DropdownItem = ({ item, label, onClick }) => {
  const handleClick = () => onClick && onClick(item)
  return (
    <li className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleClick}>
      {label(item)}
    </li>
  )
}
