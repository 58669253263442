import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useOrganizationList, useProviderOrganizationList } from '../../../../../api/state'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { SearchListOrganization } from '../../../../../shared/legacy/dropdown-search/search-list-organization'
import { ROLE_SYS } from '../../../../../role/role.const'
import { RoleAccessDisplaySys } from '../../../../../role/components/role-access-display'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListProviderOrganinization } from '../../../../../api-new/state/auth'
import { IconEdit, IconList } from '../../../../../shared/icon/icon'

const Header = ({ onAdd }) => {
  const { providerID } = useParams()

  const [selectedOrganization, updateSelectedOrganization] = useState()

  const [organizationListActions, organizationListState] = useOrganizationList()
  const [providerOrganizationListActions] = useProviderOrganizationList()

  useEffect(() => {
    organizationListActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = node => updateSelectedOrganization(node)

  const handleAdd = async organization => {
    updateSelectedOrganization(null)
    await providerOrganizationListActions.post({ providerID: parseInt(providerID), organizationID: organization.id })
    onAdd && onAdd()
  }

  return (
    <RoleAccessDisplaySys type={ROLE_SYS.PROVIDER} accessUpdate>
      <SearchListOrganization
        searchText="select organization"
        actionText="add"
        items={organizationListState.items}
        selectedItem={selectedOrganization}
        onSelect={handleSelect}
        onAdd={handleAdd}
      />
    </RoleAccessDisplaySys>
  )
}

export const ProviderOrganization = () => {
  const { providerID } = useParams()
  const { url } = useRouteMatch()

  const apiListProviderOrganinization = useApiListProviderOrganinization()

  const roleProvider = useRoleAccessSystem(ROLE_SYS.PROVIDER)

  const handleReady = async () => await apiListProviderOrganinization.get(`${providerID}/provider`)

  const handleAdd = async () => apiListProviderOrganinization.get(`${providerID}/provider`)

  const handleDelete = async data => {
    await apiListProviderOrganinization.delete(`${data.providerID}/${data.organizationID}`)
    await apiListProviderOrganinization.get(`${providerID}/provider`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="organizations">
        <CardFullHeightScrollY title="organizations" HeaderComponent={() => <Header onAdd={handleAdd} />}>
          <TableBuilder apiList={apiListProviderOrganinization} hideFloatingFilter onReady={handleReady}>
            <Column field="organizationName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconEdit}
                    text="edit"
                    to={`${url}/edit/${row.providerID}/${row.organizationID}`}
                    show={roleProvider.accessUpdate}
                    pin
                  />
                  <MenuDelete onClick={() => handleDelete(row)} show={roleProvider.accessUpdate} pin />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
