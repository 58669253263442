import React, { useContext, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse, useApiFormTemplate } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useMetadataType } from '../../common/hooks/hooks'
import { AssetCopyProgress, useAssetCopy } from '../../../../../../shared/component/asset-copy'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { FormSelectCategory } from '../../../../../../shared/form/form-control/form-select-category'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useDataContext } from '../../data-context'
import { buildCoursePath, buildNodeLocation } from '../../common/shared/node-path'
import { Modal } from '../../../../../../shared/component/modal'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const NodeTemplateGenerate = ({ nodeID }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const refModal = useRef()

  const apiFormNodeCourseMove = useApiFormNodeCourse(`/temp`)
  const apiFormTemplate = useApiFormTemplate()

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])
  const isCaffeine = useMetadataType(apiFormTemplate.form, 'CourseAssemblyName', 'caffeine')

  const handleMatch = async () => await apiFormTemplate.get(nodeID)

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState('DEST LOCATION')

  const onCopyComplete = nodeID => {
    if (nodeID) {
      updateCopiedNodeID(nodeID)
      const destLocation = buildNodeLocation(dataContext.nodes, nodeID)
      updateDestLocation(destLocation)
      refModal.current.show()
    }
  }

  const [content, init, send] = useAssetCopy(null, onCopyComplete, null)

  const dataContext = useDataContext()

  const handleSubmit = async data => {
    let node = await apiFormNodeCourseMove.post(data)

    if (authContext.state.jwtClaims.organizationID === node.organizationID) {
      await init(node.id)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })

      dataContext.nodeInsert(node)
    } else {
      await init(null)
      await send({
        srcNodeID: nodeID,
        destNodeID: node.id
      })
    }
  }

  const handleConfirm = () => {
    refModal.current.hide()
    let path = buildCoursePath(dataContext.nodes, copiedNodeID)
    history.push(path)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="new from template" onMatch={handleMatch}>
        <Modal ref={refModal} backdropStatic title="create complete" confirmText="ok" onConfirm={handleConfirm}>
          <p>
            {apiFormTemplate.form.name} has successfully been created at {destLocation}
          </p>
        </Modal>

        <Modal ref={refModal} backdropStatic title="create complete" confirmText="ok" onConfirm={handleConfirm}>
          <p>
            {apiFormTemplate.form.name} has successfully been created at {destLocation}
          </p>
        </Modal>

        <CardFullHeightScrollY title={title}>
          {isCaffeine
            ? <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <FormHiddenNumber name="templateID" value={nodeID} required shouldDirty />

                  {categoryOrganizationID &&
                    <FormSelectCategory lable="category" name="parentID" organizationID={categoryOrganizationID} required />}

                  <FormText label="name" name="name" defaultValue={apiFormTemplate.form.name} shouldDirty required />
                  <FormSubmitBack text="generate" />
                </Form>

                <AssetCopyProgress content={content} />
              </React.Fragment>
            : <div>Action not allowed for template</div>}
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
