import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useNodeCourseForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../shared/form/form'
import { GridContext } from '../grid/grid-context'
import { IconDelete } from '../../../../shared/icon/icon'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Card } from '../../../../shared/card/card'

export const CourseDelete = ({ parentUrl }) => {
  const history = useHistory()
  const { nodeID } = useParamsInt()

  const gridContext = useContext(GridContext)

  const [nodeCourseFormActions, nodeCourseFormState] = useNodeCourseForm()

  const title = useMemo(() => nodeCourseFormState.form.name, [nodeCourseFormState.form])

  const handleMatch = async () => await nodeCourseFormActions.get(nodeID)

  const handleSubmit = async () => {
    await nodeCourseFormActions.delete(nodeID)
    await gridContext.actions.loadContents()
    await gridContext.actions.loadThumbnails()
    history.push('/content')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete course" onMatch={handleMatch}>
        <Card title={title}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete course" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
