import { IconClose } from '../../icon/icon'

const ActiveItem = ({ children, onClick }) => {
  return (
    <div className="rounded bg-primary text-light px-1" style={{ margin: '2px', padding: '1px' }} onClick={onClick}>
      {children}
    </div>
  )
}

export const ActiveItemReadOnly = ({ item, label, onClick }) => {
  return (
    <ActiveItem onClick={onClick}>
      <small className="mx-1">
        {label(item)}
      </small>
    </ActiveItem>
  )
}

export const ActiveItemEditable = ({ item, label, onClick, onRemove }) => {
  const handleRemove = e => {
    e.stopPropagation()
    onRemove && onRemove(item)
  }

  return (
    <ActiveItem onClick={onClick}>
      <small className="mx-1">
        {label(item)}
      </small>
      <small onClick={handleRemove}>
        <IconClose />
      </small>
    </ActiveItem>
  )
}
