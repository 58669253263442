import React, { useCallback, useMemo, useState } from 'react'
import { getAccessToken } from '../../storage/auth'
import { useWsCopy } from '../../api/state'

export const useAssetCopy = (onOpen, onComplete, onClose) => {
  let accessToken = getAccessToken()

  let [content, updateContent] = useState()

  const onMessage = useCallback((event, param) => {
    switch (event.action) {
      case 'start':
        break
      case 'progress':
        updateContent(event.payload)
        break
      case 'complete':
        onComplete && onComplete(param)
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // let [init, send] = useWsContent(`api/v1/asset-file/ws/copy-node?auth=${accessToken}`, onOpen, onMessage, onClose)
  let [init, send] = useWsCopy(`api/v1/asset-file/ws/copy-node?auth=${accessToken}`, onOpen, onMessage, onClose)

  return [content, init, send]
}

export const AssetCopyProgress = ({ content }) => {
  let filename = useMemo(() => (content && content.filename) || '', [content])
  let fileProgress = useMemo(() => `${(content && content.fileProgress && content.fileProgress.toFixed(2)) || 0}%`, [content])
  let totalProgress = useMemo(() => `${(content && content.totalProgress && content.totalProgress.toFixed(2)) || 0}%`, [content])

  return (
    <React.Fragment>
      <div className="my-2">
        <div className="m-1">
          <span>Asset Copy Progress</span>
          <div className="progress my-2 ">
            <div className="progress-bar" style={{ width: totalProgress }}>
              {totalProgress}
            </div>
          </div>
        </div>

        {fileProgress !== '0%' &&
          fileProgress !== '100.00%' &&
          <div className="m-1">
            <span>
              File Progress: {filename}
            </span>
            <div className="progress my-2 ">
              <div className="progress-bar" style={{ width: fileProgress }}>
                {fileProgress}
              </div>
            </div>
          </div>}
      </div>
    </React.Fragment>
  )
}
