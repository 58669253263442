import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useNodeCategoryForm } from '../../../../api/state'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../shared/form/form'
import { GridContext } from '../grid/grid-context'
import { IconDelete } from '../../../../shared/icon/icon'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Card } from '../../../../shared/card/card'

export const CategoryDelete = ({ parentUrl }) => {
  const history = useHistory()

  const { nodeID } = useParamsInt()

  const gridContext = useContext(GridContext)

  const [nodeCategoryFormActions, nodeCategoryFormState] = useNodeCategoryForm()

  const title = useMemo(() => `delete ${nodeCategoryFormState.form.name}`, [nodeCategoryFormState.form.name])

  const handleMatch = async () => await nodeCategoryFormActions.get(nodeID)

  const handleSubmit = async () => {
    await nodeCategoryFormActions.delete(nodeID)
    await gridContext.actions.loadContents()
    await gridContext.actions.loadThumbnails()
    history.push(parentUrl)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text={title} onMatch={handleMatch}>
        <Card title={title}>
          <div className="py-2">This is a destructive action</div>
          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete category" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
