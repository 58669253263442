import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconApp, IconList } from '../../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListApp } from '../../../../../../api-new/state/config'

export const AppList = () => {
  const apiListApp = useApiListApp()

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const handleReady = async () => await apiListApp.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to="/admin/app/add" show={roleApp.accessCreate} />
      <MenuRecycle to="/admin/app/recycle" show={roleApp.accessDelete} />

      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/admin/app/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconApp} text="APPS">
        <CardFullHeightScrollY title="apps" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListApp} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/app/edit/${row.id}`} show={roleApp.accessUpdate} />
                  <MenuItem Icon={IconList} text="stores" to={`/admin/app/edit/${row.id}/store`} show pin />
                  <MenuDelete to={`/admin/app/edit/${row.id}/delete`} show={roleApp.accessDelete} />
                  <MenuActivityLog to={`/admin/app/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
