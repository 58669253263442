import React, { useEffect, useState } from 'react'
import { useUserLoginList, useNodeUserList } from '../state'
import TableFilter from '../../../shared/legacy/table-filter'
import { IconButtonClickRemove } from '../../../shared/icon/icon-button-click'
import { useParams } from 'react-router'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { SearchListUser } from '../../../shared/legacy/dropdown-search/search-list-user'
import { IconEdit } from '../../../shared/icon/icon'

export function NodeListUsers() {
  const { nodeID } = useParams()

  const [nodeUserActions, nodeUserState] = useNodeUserList()

  useEffect(
    () => {
      nodeUserActions.get(`${nodeID}/node`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const handleAdd = async user => {
    await nodeUserActions.post({ nodeID: parseInt(nodeID), userID: user.id })
    await nodeUserActions.get(`${nodeID}/node`)
  }

  const handleDelete = async (nodeID, userID) => {
    await nodeUserActions.delete(`${parseInt(nodeID)}/${parseInt(userID)}`)
    await nodeUserActions.get(`${nodeID}/node`)
  }

  let links = item =>
    <div>
      <IconButtonClickRemove small onClick={() => handleDelete(item.nodeID, item.userID)} />
    </div>

  let columns = [
    { name: 'email', selector: 'email', grow: 1, sortable: true },
    { name: '', right: true, sortable: false, cell: row => links(row) }
  ]

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="users">
        <TableFilter
          title="users"
          HeaderItem={<Header onAdd={handleAdd} />}
          columns={columns}
          data={nodeUserState.items}
          loading={nodeUserState.submitting}
        />
      </BreadcrumbView>
    </React.Fragment>
  )
}

const Header = ({ onAdd }) => {
  const [selectedUser, updateSelectedUser] = useState()

  const [userLoginActions, userLoginState] = useUserLoginList()

  useEffect(() => {
    userLoginActions.get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelect = user => updateSelectedUser(user)

  const handleAdd = async item => {
    updateSelectedUser(null)
    onAdd && onAdd(item)
  }

  return (
    <SearchListUser
      dropLeft
      selectText="select user"
      actionText="add"
      items={userLoginState.items}
      selectedItem={selectedUser}
      onSelect={handleSelect}
      onAdd={handleAdd}
    />
  )
}
