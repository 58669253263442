import React, { useContext } from 'react'

import { MetaDataType } from '../../shared/meta-data-type'
import { ThumbnailImage } from '../../shared/thumbnail-image'
import { GridContext } from './grid-context'
import { useActiveNodes, useMetadataType } from '../../hooks/hooks-node'
import { Link } from 'react-router-dom'
import { useParamsInt } from '../../../../shared/hooks/location'
import { ROLE_ORG } from '../../../../role/role.const'
import { Menu, MenuDelete, MenuDetails, MenuEdit, MenuItem } from '../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../role/role.hook'
import { IconUsers, IconMove, IconCopy, IconPlus, IconGroup, IconDelete } from '../../../../shared/icon/icon'

const Category = ({ url, node, thumbnails }) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  return (
    <React.Fragment>
      <div className="p-1">
        <Link to={`${url}/node/${node.id}`}>
          <ThumbnailImage nodeID={node.id} assets={thumbnails} />
        </Link>
      </div>
      <div className="border-top d-flex p-2">
        <div className="flex-fill text-nowrap text-truncate">
          {node.name}
        </div>
        <Menu small>
          <MenuEdit to={`${url}/category-edit/${node.id}`} show={roleContent.accessUpdate} pin={false} />

          <MenuItem
            Icon={IconUsers}
            text="user permissions"
            to={`${url}/category-edit/${node.id}/content-permission/${node.id}`}
            show={roleContent.accessRead}
          />

          <MenuItem
            Icon={IconGroup}
            text="group permissions"
            to={`${url}/category-edit/${node.id}/group-permission/${node.id}`}
            show={roleContent.accessRead}
          />

          <MenuDelete to={`${url}/category-delete/${node.id}`} show={roleContent.accessDelete} />
        </Menu>
      </div>
    </React.Fragment>
  )
}

const Course = ({ url, node, thumbnails }) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const isCaffeine = useMetadataType(node, 'CourseAssemblyName', 'caffeine')

  return (
    <React.Fragment>
      <div className="p-1">
        <Link to={`${url}/course-details/${node.id}`}>
          <ThumbnailImage nodeID={node.id} assets={thumbnails} />
        </Link>
      </div>
      <div className="border-top d-flex p-2">
        <div className="flex-fill text-nowrap text-truncate">
          {node.name}
        </div>
        <Menu>
          <MenuDetails to={`${url}/course-details/${node.id}`} show pin={false} />

          <MenuEdit to={`${url}/course-details/${node.id}/edit/${node.id}`} show={roleContent.accessUpdate} pin={false} />

          <MenuItem
            Icon={IconUsers}
            text="user permissions"
            to={`${url}/course-details/${node.id}/edit/${node.id}/content-permission/${node.id}`}
            show={roleContent.accessRead}
          />

          <MenuItem
            Icon={IconGroup}
            text="group permissions"
            to={`${url}/course-details/${node.id}/edit/${node.id}/group-permission/${node.id}`}
            show={roleContent.accessRead}
          />

          <MenuItem
            Icon={IconPlus}
            text="new template"
            to={`${url}/course-details/${node.id}/template/${node.id}`}
            disabled={!isCaffeine}
            show={roleContent.accessCreate}
          />

          <MenuItem Icon={IconMove} text="move" to={`${url}/course-details/${node.id}/move/${node.id}`} show={roleContent.accessUpdate} />

          <MenuItem
            Icon={IconCopy}
            text="copy"
            to={`${url}/course-details/${node.id}/copy/${node.id}`}
            disabled={!isCaffeine}
            show={roleContent.accessUpdate}
          />

          <MenuItem
            Icon={IconDelete}
            text="delete"
            to={`${url}/course-details/${node.id}/delete/${node.id}`}
            show={roleContent.accessDelete}
          />
        </Menu>
      </div>
    </React.Fragment>
  )
}

export const GridItems = ({ url }) => {
  const { parentID } = useParamsInt()

  const gridContext = useContext(GridContext)

  const activeNodes = useActiveNodes(parentID, gridContext.filter, gridContext.contents)

  return (
    <React.Fragment>
      <div className="row g-1">
        {activeNodes.map((node, i) =>
          <div key={i} className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card">
              <div className="card-body m-0 p-0">
                <MetaDataType item={node} type="datatype" value="category">
                  <Category url={url} node={node} thumbnails={gridContext.thumbnails} />
                </MetaDataType>
                <MetaDataType item={node} type="datatype" value="course">
                  <Course url={url} node={node} thumbnails={gridContext.thumbnails} />
                </MetaDataType>
                <MetaDataType item={node} type="datatype" value="streaming_video">
                  <Course url={url} node={node} thumbnails={gridContext.thumbnails} />
                </MetaDataType>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
