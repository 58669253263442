import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { useApiListGroupUser } from '../../../../../../api-new/state/content'
import { useApiListGroup } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'

export const UserListGroupAdd = () => {
  const { userID } = useParamsInt()

  const apiListGroupUser = useApiListGroupUser()
  const apiListGroup = useApiListGroup()

  const roleGroupUser = useRoleAccessOrganization(ROLE_ORG.GROUP_USER)

  const handleMatch = async () => {
    await apiListGroupUser.get(`${userID}/user`)
    await apiListGroup.get()
  }

  const handleAdd = async item => {
    await apiListGroupUser.post({ groupID: item.id, userID: userID })
    await apiListGroupUser.get(`${userID}/user`)
  }

  const handleInitialFilter = items => items.filter(group => !apiListGroupUser.items.some(groupUser => groupUser.groupID === group.id))

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onMatch={handleMatch}>
        <CardFullHeightScrollY title="groups">
          <TableBuilder apiList={apiListGroup} initialFilterFn={handleInitialFilter}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd text="add" onClick={() => handleAdd(row)} show={roleGroupUser.accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
