import { useCallback, useMemo } from 'react'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ThumbnailImage } from '../shared/thumbnail-image'
import { Menu, MenuActivityLog, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { IconCategory, IconFile, IconMove } from '../../../../../../shared/icon/icon'
import { GridItem } from '../shared/grid-item'
import { useDataContext } from '../../data-context'
import { useDisplaySysAdmin } from '../../../../../../role/role.component'

export const NodeCategoryGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const dataContext = useDataContext()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const type = useMemo(() => (node ? JSON.parse(node.metaData.toLowerCase()).datatype : null), [node])

  const to = useMemo(
    () => {
      if (type === 'category') {
        return `${url}/node/${node.id}`
      } else if (type === 'course') {
        return `${url}/course/${node.id}`
      }
    },
    [url, type, node]
  )

  const Thumbnail = useCallback(() => <ThumbnailImage nodeID={node.id} assets={dataContext.assets} cache={dataContext.thumbnailCache} />, [
    node,
    dataContext.assets,
    dataContext.thumbnailCache
  ])

  const displaySysAdmin = useDisplaySysAdmin()

  const FooterMenu = () =>
    <div>
      <Menu small>
        <MenuItemHeader text="sys admin" show={displaySysAdmin} />
        <MenuEdit text="sys edit" to={`${url}/node/${node.id}/edit-sys`} show={displaySysAdmin} pin={false} />
        <MenuItem Icon={IconFile} text="assets" to={`${url}/node/${node.id}/assets`} show={displaySysAdmin} />

        <MenuItemHeader text="org" show={displaySysAdmin} />
        <MenuEdit to={`${url}/node/${node.id}/edit`} show={roleContent.accessUpdate} pin={false} />
        <MenuItem Icon={IconMove} text="move" to={`${url}/node/${node.id}/move`} show={roleContent.accessUpdate} />
        <MenuDelete to={`${url}/node/${node.id}/delete`} show={roleContent.accessDelete} />
        <MenuActivityLog to={`${url}/node/${node.id}/activity`} show={roleContent.accessRead} />
      </Menu>
    </div>

  return <GridItem Thumbnail={Thumbnail} Icon={IconCategory} FooterMenu={FooterMenu} title={node.name} to={to} />
}
