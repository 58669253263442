import React from 'react'
import { Route } from 'react-router-dom'
import { SendgridEventDetails } from './content/sendgrid-event-details'
import { SendgridEventList } from './content/sendgrid-event-list'

export const LayoutSendgridEvent = () => {
  return (
    <React.Fragment>
      <Route path="/admin/sendgrid-event/list" component={SendgridEventList} />
      <Route path="/admin/sendgrid-event/list/details/:sendgridEventID" component={SendgridEventDetails} />
    </React.Fragment>
  )
}
