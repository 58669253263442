import { useEffect, useRef, useState } from 'react'

export const TextSearch = ({ value, onChange, onClick, onDelete, onEdit }) => {
  const ref = useRef()

  const [checkDelete, updateCheckDelete] = useState(false)
  const [checkFocus, updateCheckFocus] = useState(false)

  useEffect(
    () => {
      ref.current.textContent = value
    },
    [value]
  )

  const handleChange = e => {
    ref.current.textContent = e.target.textContent
    onChange && onChange(ref.current.textContent)
    onEdit(true)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    } else if (e.key === 'Backspace') {
      if (e.target.textContent === '') {
        updateCheckDelete(true)
      }
    }
  }

  const handleKeyUp = e => {
    if (e.key === 'Backspace') {
      if (checkDelete) {
        updateCheckDelete(false)
        onDelete && onDelete()
      }
    }
  }

  const handleClick = () => {
    if (checkFocus) {
      updateCheckFocus(false)
      return
    }

    if (ref.current.textContent.length === 0) {
      onClick()
    } else {
      onEdit(true)
    }
  }

  const handleFocus = e => {
    updateCheckFocus(true)
    onEdit(true)
  }

  return (
    <div
      ref={ref}
      className="m-1 flex-fill"
      onFocus={handleFocus}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onInput={handleChange}
      contentEditable
      style={{ outline: '0px', minWidth: '0px' }}
    />
  )
}
