import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../../../../context/auth.context'
import { useDataContext } from '../../../data-context'
import { useHistory } from '../../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNodeCategory } from '../../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../../shared/hooks/load'
import { buildCoursePath } from '../../shared/node-path'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { IconMove } from '../../../../../../../shared/icon/icon'
import { Card } from '../../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmitBack } from '../../../../../../../shared/form/form'
import { FormSelectCategory } from '../../../../../../../shared/form/form-control/form-select-category'
import { useNotificationContext } from '../../../../../../../context/notification/context'

export const NodeCategoryMove = ({ node }) => {
  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const notification = useNotificationContext()

  const history = useHistory()

  const apiFormNodeCategory = useApiFormNodeCategory()
  const apiFormNodeCategoryMove = useApiFormNodeCategory(`/move`)

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormNodeCategory.form.name, [apiFormNodeCategory.form])

  const handleMatch = async () => await apiFormNodeCategory.get(node.id)

  const handleSubmit = async data => {
    debugger
    try {
      if (data.parentID === -1) {
        data.parentID = null
      }

      let node = await apiFormNodeCategoryMove.put(data.nodeID, data)

      const moveToPath = nodes => {
        dataContext.unsubscribe(moveToPath)
        let path = buildCoursePath(nodes, node.id)
        history.replace(path)
      }

      dataContext.subscribe(moveToPath)
      dataContext.nodeUpdate(node)
    } catch (exp) {
      notification.alert.error(exp.error)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <Card title={title}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={node.id} required shouldDirty />

            <FormSelectCategory lable="category" name="parentID" includeRoot organizationID={categoryOrganizationID} required />
            <FormSubmitBack text="move category" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
