import React from 'react'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { RoleSelectUserSys } from '../../../../../../role/components/role-select-user-sys'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { Card } from '../../../../../../shared/card/card'
import { useApiFormLoginOrgUser } from '../../../../../../api-new/state/content'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { Menu, MenuDelete, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const OrganizationUserEdit = () => {
  const history = useHistory()

  const { userID, loginID } = useParamsInt()

  const apiFormLoginOrgUser = useApiFormLoginOrgUser()

  const handlePath = () => apiFormLoginOrgUser.get(`${userID}`)

  const handleSubmit = async data => {
    await apiFormLoginOrgUser.put(`${data.userID}/pin`, { userID: data.userID, pin: data.pin || null })
    await apiFormLoginOrgUser.get(`${userID}`)
  }

  const handleDelete = async () => {
    await apiFormLoginOrgUser.delete(`${userID}/delete`)
    await apiFormLoginOrgUser.get(`${userID}`)
  }

  const handleRestore = async () => {
    await apiFormLoginOrgUser.put(`${userID}/restore`)
    await apiFormLoginOrgUser.get(`${userID}`)
  }

  const handlePurge = async () => {
    await apiFormLoginOrgUser.delete(`${userID}/purge`)
    history.push(`/admin/login/list/edit/${loginID}/organizations`)
  }

  const HeaderMenu = () =>
    <Menu>
      {!apiFormLoginOrgUser.form.isDeleted && <MenuDelete onClick={handleDelete} show pin />}
      {apiFormLoginOrgUser.form.isDeleted && <MenuRestore onClick={handleRestore} show pin />}
      {apiFormLoginOrgUser.form.isDeleted && <MenuPurge onClick={handlePurge} show pin />}
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormLoginOrgUser.form.organizationName} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormLoginOrgUser.form.organizationName} HeaderMenu={HeaderMenu}>
          <RoleSelectUserSys userID={userID} />
          <Card title="pin">
            <Form state={apiFormLoginOrgUser.form} onSubmit={handleSubmit}>
              <FormText label="pin" name="pin" />
              <FormSubmit />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
