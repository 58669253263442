import React, { useContext, useState } from 'react'
import { useAuthImpersonateForm } from '../api/state'
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken, setRefreshToken } from '../storage/auth'
import { useApiFormAuthLogout } from '../api-new/state/auth'
import { useApiFormAuthRefresh } from '../api-new/state/auth'
import { useApiListAuthOrganization } from '../api-new/state/auth'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { useApiListAuthProviderLink } from '../api-new/state/auth'
import { useApiFormAuthPin } from '../api-new/state/auth'
import { useApiFormAuthEmail } from '../api-new/state/auth'

export const AuthContext = React.createContext()

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [jwtClaims, updateJwtClaims] = useState({})

  const apiFormAuthEmail = useApiFormAuthEmail()
  const apiFormAuthPin = useApiFormAuthPin()
  const apiListAuthProviderLink = useApiListAuthProviderLink()
  const apiFormAuthProvider = useApiFormAuthProvider()
  const apiListAuthOrganization = useApiListAuthOrganization()
  const apiFormAuthRefresh = useApiFormAuthRefresh()
  const apiFormAuthLogout = useApiFormAuthLogout()

  const [authImpersonateFormActions] = useAuthImpersonateForm()

  const state = {
    get isAuth() {
      return !!this.accessToken
    },

    get accessToken() {
      return getAccessToken()
    },
    get refreshToken() {
      return getRefreshToken()
    },

    jwtClaims: jwtClaims,
    role: jwtClaims.role,//remove
    loginID: jwtClaims.loginID,
    userID: jwtClaims.userID,
    isSysAdmin: jwtClaims.isSysAdmin || false,
    isSysRoot: jwtClaims.isSysRoot || false,
    isSession: jwtClaims.isSession || false,

    organizationName: jwtClaims.organizationName,
    organizationType: jwtClaims.organizationType,

    authEmailFormState: apiFormAuthEmail,
    authPinFormState: apiFormAuthPin,
    providerLinkListState: apiListAuthProviderLink,
    authProviderFormState: apiFormAuthProvider,
    authOrganizationListState: apiListAuthOrganization
  }

  const setAuthToken = authToken => {
    let claimsData = atob(authToken.accessToken.split('.')[1])
    const jwtClaims = JSON.parse(claimsData)
    updateJwtClaims(jwtClaims)

    setAccessToken(authToken.accessToken, jwtClaims.isSession)
    setRefreshToken(authToken.refreshToken, jwtClaims.isSession)
  }

  const actions = {
    getProviders: async () => await apiListAuthProviderLink.get(),
    getProvidersUrl: async url => await apiListAuthProviderLink.get(url),

    emailFormInit: () => apiFormAuthEmail.init(),

    checkEmail: async email => {
      try {
        await apiFormAuthEmail.get(email)
        return true
      } catch {
        return false
      }
    },

    loginEmail: async data => {
      let authToken = await apiFormAuthEmail.post(data)
        setAuthToken(authToken)
    },

    loginPin: async data => {
      let authToken = await apiFormAuthPin.post(data)
      setAuthToken(authToken)
    },

    loginProvider: async token => {
      let authToken = await apiFormAuthProvider.get(`${token}/signin`)
      setAuthToken(authToken)
    },

    setSignoutURL: signoutURL => sessionStorage.setItem('signout-url', signoutURL),
    getSignoutURL:  () => sessionStorage.getItem('signout-url'),
    removeSignoutURL:  () => sessionStorage.removeItem('signout-url'),

    setAuthToken: authToken => setAuthToken(authToken),

    refresh: async () => {
      const authToken = await apiFormAuthRefresh.get(state.refreshToken)
      setAuthToken(authToken)
    },

    refreshRecreate: async refreshToken => {
      const authToken = await apiFormAuthRefresh.get(`${refreshToken}/recreate`)
      setAuthToken(authToken)
    },

    refreshToken: async refreshToken => {
      const authToken = await apiFormAuthRefresh.get(refreshToken)
      setAuthToken(authToken)
    },

    getOrganizations: async () => await apiListAuthOrganization.get(state.refreshToken),

    changeOrganization: async data => {
      const authToken = await apiListAuthOrganization.put(state.refreshToken, data)
      setAuthToken(authToken)
    },

    impersonateUser: async (organizationID, userID) => {
      const authToken = await authImpersonateFormActions.post({ organizationID, userID })
      return authToken
    },

    impersonateRefresh: async (refresh) => {
      const authToken = await apiFormAuthRefresh.get(refresh)
      setAuthToken(authToken)
    },

    logout: async () => {
      await apiFormAuthLogout.delete(state.refreshToken)
      removeTokens(state.isSession)
      updateJwtClaims({})
    },

    checkExpRefresh: async () => {
      let now = Math.floor(Date.now() / 1000)
      if (now > state.jwtClaims.exp) {
        await actions.refresh()
      }
    },

    loginExists: async email => {
      try {
        await apiFormAuthEmail.get(email)
        return true
      } catch {
        return false
      }
    }
  }

  return (
    <AuthContext.Provider value={{ actions: actions, state: state }}>
      {children}
    </AuthContext.Provider>
  )
}
