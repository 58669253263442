import { useEffect, useRef, useState } from 'react'

export const useInputErrors = value => {
  const ref = useRef(null)

  const [state, updateState] = useState({ valid: false, required: false })

  useEffect(
    () => {
      if (ref.current) {
        updateState({ valid: ref.current.validity.valid, required: !ref.current.validity.valueMissing })
      }
    },
    [value]
  )

  return [ref, state]
}
