import React, { useRef } from 'react'
import { IconButtonClickImportCSV } from '../../../../../../shared/icon/icon-button-click'
import { ExampleUserImportCSV } from './example-user-import-csv'
import { IconClickDetails } from '../../../../../../shared/icon/icon-click'
import Papa from 'papaparse'
import { ModalDialog, useModalActions } from '../../../../../../shared/component/modal-dialog'

export const UserImportCSV = ({ disabled, onSelect }) => {
  const ref = useRef(null)

  const handleClick = () => {
    ref.current.click()
  }

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader()

      reader.onload = () => {
        const result = Papa.parse(reader.result, { header: true, delimiter: ',' })
        const invites = result.data.filter(invite => !!invite.email).map(invite => {
          return { email: invite.email, firstName: invite.first, lastName: invite.last, roleID: null }
        })

        onSelect && onSelect(invites)
      }

      reader.readAsText(e.target.files[0])
    }
  }

  return (
    <React.Fragment>
      <input ref={ref} type="file" onChange={handleFileChange} hidden />
      <IconButtonClickImportCSV disabled={disabled} primary onClick={handleClick} />
      <UserImportDetails />
    </React.Fragment>
  )
}

const userImport = 'userImport'

const UserImportDetails = () => {
  const modalActions = useModalActions(userImport)

  const handleClick = () => {
    modalActions.show()
  }

  return (
    <React.Fragment>
      <ModalDialog target={userImport} header="User CSV Import Format">
        <ExampleUserImportCSV />
      </ModalDialog>
      <IconClickDetails size="lg" onClick={handleClick} />
    </React.Fragment>
  )
}
