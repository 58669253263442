import React from 'react'
import { Route } from 'react-router-dom'
import { CourseGrid } from './course/course-grid'
import { TemplateGrid } from './template/template-grid'
import { DataProvider } from './data-context'

export const LayoutContent = () => {
  return (
    <React.Fragment>
      <DataProvider>
        <Route path="/org/node-content" component={CourseGrid} />
        <Route path="/org/node-template" component={TemplateGrid} />
      </DataProvider>
    </React.Fragment>
  )
}
