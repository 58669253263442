import { useContext, useMemo } from 'react'
import { AuthContext } from '../context/auth.context'
import { RoleContext } from './role.context'

const fullAccess = { accessCreate: true, accessRead: true, accessUpdate: true, accessDelete: true, accessPurge: true }
const noAccess = { accessCreate: false, accessRead: false, accessUpdate: false, accessDelete: false, accessPurge: false }

export const useRoleAccessSystem = type => {
  const authContext = useContext(AuthContext)
  const roleContext = useContext(RoleContext)

  return useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return fullAccess
      }

      return roleContext.sysRoles[type] || noAccess
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.sysRoles]
  )
}

export const useRoleAccessOrganization = type => {
  const authContext = useContext(AuthContext)
  const roleContext = useContext(RoleContext)

  return useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return fullAccess
      }

      if (authContext.state.isSysAdmin) {
        return fullAccess
      }

      return roleContext.orgRoles[type] || noAccess
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.orgRoles]
  )
}
