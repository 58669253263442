import React, { useMemo } from 'react'

export const MetadataNode = ({ children, node, tag, value }) => {
  const show = useMemo(
    () => {
      if (node && node.metaData && tag && value) {
        let metaData = node.metaData.toLowerCase()
        metaData = JSON.parse(metaData)
        let lowerTag = tag.toLowerCase()
        let metaItem = metaData[lowerTag]
        return metaItem === value.toLowerCase()
      }

      return false
    },
    [node, tag, value]
  )

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}
