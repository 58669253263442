import React from 'react'
import { Route } from 'react-router-dom'
import { CacheProvider } from '../../../context/cache.context'
import { Root } from './root'
import { GridProvider } from './grid/grid-context'
import { TemplateRecycle } from './recycle/template-recycle'
import { Purge } from '../../../shared/page/page-purge'
import { useApiFormTemplate } from '../../../api-new/state/content'

export function LayoutTemplate() {
  return (
    <React.Fragment>
      <CacheProvider>
        <GridProvider>
          <Route path="/template" component={Root} />
          <Route path="/template/recycle" component={TemplateRecycle} />
          <Route path="/template/recycle/:purgeID/purge">
            <Purge useApiForm={useApiFormTemplate} titleParam="name" successPath="/template/recycle" />
          </Route>
        </GridProvider>
      </CacheProvider>
    </React.Fragment>
  )
}
