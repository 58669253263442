import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { useApiListChunk } from '../api-list-chunk'
import { urlArgs } from '../state'

export const useApiListChunkLogError = args => useApiListChunk(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)
export const useApiListLogError = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)
export const useApiFormLogError = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/log-error${urlArgs(args)}`)

export const useApiListChunkLogEvent = args => useApiListChunk(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)
export const useApiListLogEvent = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)
export const useApiFormLogEvent = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/log-event${urlArgs(args)}`)

export const useApiListUserLogin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/user-login${urlArgs(args)}`)
export const useApiFormUserLogin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user-login${urlArgs(args)}`)

export const useApiListOrganization = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/organization${urlArgs(args)}`)

export const useApiFormOrganization = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/organization${urlArgs(args)}`)

export const useApiListAsset = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/asset${urlArgs(args)}`)
export const useApiFormAsset = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/asset${urlArgs(args)}`)

export const useApiListNodeUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-user${urlArgs(args)}`)
export const useApiListNodeGroup = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-group${urlArgs(args)}`)

export const useApiListUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/user${urlArgs(args)}`)
export const useApiFormUser = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user${urlArgs(args)}`)

export const useApiListRoleSyncUserSys = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-sync-user-sys${urlArgs(args)}`)

export const useApiListTradeshow = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show${urlArgs(args)}`)
export const useApFormTradeshow = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show${urlArgs(args)}`)

export const useApiListLogin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login${urlArgs(args)}`)

export const useApiFormLogin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login${urlArgs(args)}`)
export const useApiListLoginOrgUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login-org-user${urlArgs(args)}`)

export const useApiFormLoginOrgUser = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login-org-user${urlArgs(args)}`)

export const useApiListSysAdmin = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/login-sys-admin${urlArgs(args)}`)
export const useApiFormSysAdmin = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/login-sys-admin${urlArgs(args)}`)
export const useApiListSysAdminOrg = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/org-sys-admin${urlArgs(args)}`)

export const useApiListSendgrid = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event${urlArgs(args)}`)

export const useApiFormSendgrid = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event${urlArgs(args)}`)

export const useApiListGroup = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/group${urlArgs(args)}`)
export const useApiFormGroup = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/group${urlArgs(args)}`)
export const useApiListGroupUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/group-user${urlArgs(args)}`)

export const useApiListNodeCategory = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-category${urlArgs(args)}`)
export const useApiFormNodeCategory = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-category${urlArgs(args)}`)

export const useApiFormNodeCourse = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-course${urlArgs(args)}`)
export const useApiListNodeQuery = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-meta-query${urlArgs(args)}`)

export const useApiListTemplate = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

export const useApiFormTemplate = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node-template${urlArgs(args)}`)

export const useApiListNode = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/node${urlArgs(args)}`)
export const useApiFormNode = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/node${urlArgs(args)}`)

export const useApiFormLoginPurge = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/user-login-purge${urlArgs(args)}`)

export const useApiListContent = () => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/content/node`)
export const useApiFormContent = () => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/content/node`)

export const useApiListContentAsset = () => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/content/asset`)

//////////////////////////////////////////
//ROLE
//////////////////////////////////////////

export const useApiFormRoleAccess = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiListRoleScope = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-scope${urlArgs(args)}`)
export const useApiListRoleAccessAudit = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-audit${urlArgs(args)}`)
export const useApiListRole = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role${urlArgs(args)}`)
export const useApiFormRole = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role${urlArgs(args)}`)
export const useApiListRoleType = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-type${urlArgs(args)}`)
export const useApiListAccess = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiFormAccess = args => useApiForm(process.env.REACT_APP_API_CONTENT, `api/v1/role-access${urlArgs(args)}`)
export const useApiListAccessAudit = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-audit${urlArgs(args)}`)
export const useRoleUser = args => useApiList(process.env.REACT_APP_API_CONTENT, `api/v1/role-user${urlArgs(args)}`)
