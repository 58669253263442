import React, { useEffect } from 'react'
import { useRoleSyncLoginList } from '../../api/state'
import { SelectTag } from '../../shared/select-tag/select-tag'
import { Card } from '../../shared/card/card'

export const RoleSelectLogin = ({ loginID, enforceReadOnly }) => {
  const [roleSyncLoginListActions, roleSyncLoginListState] = useRoleSyncLoginList()

  useEffect(
    () => {
      roleSyncLoginListActions.get(loginID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginID]
  )

  const handleAdd = async item => {
    await roleSyncLoginListActions.post(item)
    await roleSyncLoginListActions.get(loginID)
  }

  const handleRemove = async item => {
    await roleSyncLoginListActions.delete(`${item.roleID}/${item.loginID}`)
    await roleSyncLoginListActions.get(loginID)
  }

  return (
    <React.Fragment>
      <Card title="Login Roles">
        <SelectTag
          data={roleSyncLoginListState.items}
          fnLabel={item => item.role}
          fnActive={item => item.active}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </Card>
    </React.Fragment>
  )
}
