import React from 'react'
import { IconDelete, IconEdit, IconPlus } from '../../../../../../shared/icon/icon'
import { Menu } from '../../../../../../shared/menu/menu'
import { MenuLink } from '../../../../../../view/node-new/shared/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormNumber, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { StorageFileChunk } from '../../../../../../shared/legacy/storage/storage-file-chunk'
import { FormRawJson } from '../../../../../../shared/form/form-control/form-raw-json'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

const setThubnailCustom = json => {
  if (json) {
    let metadata = JSON.parse(json)

    if (metadata['DataType'] === 'THUMBNAIL') {
      metadata['Custom'] = true
      let result = JSON.stringify(metadata)
      return result
    }
  }

  return json
}

export const NodeAssetEdit = ({ parentUrl, assetID, apiForm }) => {
  const handleMatch = () => apiForm.get(assetID)

  const handleSubmit = async data => await apiForm.put(data.id, data)

  const handleComplete = async file => {
    let data = apiForm.form
    data.length = file.size

    data.metaData = setThubnailCustom(data.metaData)

    await apiForm.put(data.id, data)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconPlus} text="new asset" to={`${parentUrl}/asset-add`} />
      <MenuLink Icon={IconDelete} text="delete asset" to={`${parentUrl}/asset-delete/${assetID}`} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiForm.form.name} HeaderMenu={HeaderMenu}>
          <Form state={apiForm.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required autoFocus />
            <FormText label="key" name="key" readOnly />
            <FormText label="version" name="version" readOnly />
            <FormNumber label="size" name="length" />
            <FormRawJson label="metadata" name="metaData" />
            <FormSubmit text="update" />
          </Form>
          <StorageFileChunk storageKey={apiForm.form.key} onComplete={handleComplete} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
