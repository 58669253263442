import { useApiListState } from '../../api/api-list/action'
import { useApiFormState } from '../../api/api-form/action'

export const useNodeForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/node`)
}

export const useAssetList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/asset`)
}

export const useNodeUserList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-user`)
}

export const useNodeGroupList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-group`)
}

export const useUserLoginList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/user-login`)
}

export const useGroupList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/group`)
}

export const useAssetForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/asset`)
}

export const useNodeList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node`)
}

// export const useRecycleNodeList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/recycle-node`)
// }
