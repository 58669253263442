import React from 'react'

export const TableHierarchy = ({ columns, data, showAsList }) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr className="text-capitalize">
            {columns &&
              columns.map(
                (column, i) =>
                  !column.hide &&
                  <th key={i} style={column.style}>
                    {column.name}
                  </th>
              )}
          </tr>
        </thead>

        <tbody>
          {data &&
            data.map((item, i) =>
              <tr key={i}>
                {columns.map((column, j) =>
                  <React.Fragment key={j}>
                    {!column.hide &&
                      <React.Fragment>
                        <td>
                          {column.row
                            ? column.row(item)
                            : <React.Fragment>
                                {showAsList
                                  ? <span>
                                      {item[column.selector]}
                                    </span>
                                  : <span style={{ paddingLeft: item[column.level] ? item.level * 30 : 0 }}>
                                      {item[column.level] > 0 &&
                                        <span className="pr-2" style={{ fontSize: 15 }}>
                                          {'\u2514'}
                                        </span>}
                                      {item[column.selector]}
                                    </span>}
                              </React.Fragment>}
                        </td>
                      </React.Fragment>}
                  </React.Fragment>
                )}
              </tr>
            )}
        </tbody>
      </table>
    </React.Fragment>
  )
}
