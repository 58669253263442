import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useApiFormNodeCourse, useApiListAsset } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { Menu, MenuActivityLog, MenuDelete, MenuDetails, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { ThumbnailImageEdit } from '../../../../../../view/node-new/shared/thumbnail-image-edit'
import { useGetByDataType, useMetadataType } from '../../common/hooks/hooks'
import { IconCopy, IconEdit, IconFile, IconGroup, IconLink, IconMove, IconPlus, IconUsers } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { useDisplaySysAdmin } from '../../../../../../role/role.component'
import { FormCourseAccessID } from '../../../../../../shared/form/form-control/form-course-access-id'
import { FormCourseMetadata } from '../../../../../../shared/form/form-control/form-course-metadata'
import { CourseDeepLink } from '../../common/model/course-deep-link'
import { useModalActions } from '../../../../../../shared/component/modal-dialog'

export const NodeCourseEdit = ({ parentURL, nodeID }) => {
  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormNodeCourse.get(nodeID)
      apiListAsset.get(`${nodeID}/node`)
    },
    [nodeID]
  )

  const handleSubmit = async data => {
    let node = await apiFormNodeCourse.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  // const [courseAccessID, updateCourseAccessID] = useState(undefined)
  // const [couserMetadata, updateCourseMetadata] = useState(undefined)

  // useEffect(
  //   () => {
  //     updateCourseAccessID(apiFormNodeCourse.form.accessID || '')
  //     updateCourseMetadata(apiFormNodeCourse.form.metaData)
  //   },
  //   [apiFormNodeCourse.form]
  // )

  // const handleCourseAccess = value => updateCourseAccessID(value || '')
  // const handleCourseMetadata = value => updateCourseMetadata(value)

  const isCaffeine = useMetadataType(apiFormNodeCourse.form, 'CourseAssemblyName', 'caffeine')
  const thumbnail = useGetByDataType('thumbnail', apiListAsset.items)

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)
  // const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)
  // const roleTemplate = useRoleAccessOrganization(ROLE_ORG.TEMPLATE)

  const displaySysAdmin = useDisplaySysAdmin()

  const modalActions = useModalActions('courseLink')
  const handleCourseLink = () => modalActions.show()

  const HeaderMenu = () =>
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${parentURL}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuDetails to={`${parentURL}`} show={roleContent.accessRead} />
      <MenuItem Icon={IconLink} text="deep link" onClick={() => handleCourseLink()} show />
      <MenuItem Icon={IconUsers} text="user permissions" to={`${parentURL}/user-permission`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${parentURL}/group-permission`} show={roleContent.accessRead} />
      <MenuItem Icon={IconMove} text="move" to={`${parentURL}/move`} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${parentURL}/copy`} disabled={!isCaffeine} show={roleContent.accessUpdate} />
      <MenuItem Icon={IconPlus} text="new template" to={`${parentURL}/template`} disabled={!isCaffeine} show={roleContent.accessCreate} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${parentURL}/activity`} show={roleContent.accessRead} />
    </Menu>

  const handleImageChange = () => {} //gridContext.actions.loadThumbnails()

  return (
    <React.Fragment>
      <BreadcrumbView text="edit" Icon={IconEdit}>
        <CardFullHeightScrollY title="edit" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormNodeCourse.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormCourseAccessID label="access" name="accessID" />
              <FormCourseMetadata name="metaData" />
              <FormSubmit text="update" />
            </Form>
          </Card>

          <ThumbnailImageEdit nodeID={nodeID} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
      <CourseDeepLink targetID="courseLink" nodeID={nodeID} />
    </React.Fragment>
  )
}
