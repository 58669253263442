import React from 'react'
import { DisplayFeatureTradeShow } from '../../../context/display-feature-trade-show'
import { Route } from 'react-router-dom'
import { LayoutTradeshow } from './tradeshow/layout-tradeshow'
import { LayoutGroup } from './group/layout-group'
import { LayoutUserLogin } from './user-login/layout-user-login'
import { LayoutLoginProvider } from './login-provider/layout-login-provider'
import { LayoutLoginSetting } from './login-settings/layout-login-setting'
import { LayoutPasswordUpdate } from './password-update/layout-password-update'
import { LayoutDeleteAccount } from './delete-account/layout-delete-account'
import { LayoutAnalytic } from './analytic/layout-analytic'
import { LayoutDashboard } from './dashboard/layout-dashboard'
import { LayoutContent } from './node-content/layout-content'

export const LayoutOrg = () => {
  return (
    <React.Fragment>
      <LayoutUserLogin />
      <LayoutGroup />
      <LayoutLoginProvider />
      <LayoutLoginSetting />
      <LayoutPasswordUpdate />
      <LayoutDeleteAccount />
      <LayoutAnalytic />
      <LayoutDashboard />

      <LayoutContent />

      <DisplayFeatureTradeShow>
        <Route path="/org/tradeshow" component={LayoutTradeshow} />
      </DisplayFeatureTradeShow>
    </React.Fragment>
  )
}
