import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormRequired } from '../hooks/input-hooks'
import { FormatLabel } from '../format-label'


export const FormRawJson = ({ label, name, required }) => {
  const { watch, setValue, setError, clearErrors } = useFormContext()

  const watchValue = watch(name)

  useFormRequired(name, required)

  useEffect(
    () => {
      try {
        if (watchValue) {
          let value = JSON.stringify(JSON.parse(watchValue), undefined, 4)
          setValue(name, value, { shouldDirty: false })
        }
        clearErrors(name)
      } catch {
        setError(name, {error: "Invalid JSON Format"})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue]
  )

  // const handlePrettify = () => {
  //   try {
  //     let newValue = JSON.stringify(JSON.parse(watchValue), undefined, 4)
  //     setValue(name, newValue, { shouldDirty: false })
  //   } catch {
  //     setValue(name, watchValue, { shouldDirty: false })
  //   }
  // }

  // const handleMinify = () => {
  //   let newValue = watchValue.replaceAll("\n", "").replaceAll(" ", "")
  //   setValue(name, newValue, { shouldDirty: false })
  // }

  const handleChange = e => setValue(name, e.target.value, { shouldDirty: true })

  // const hasError = useMemo(() => !!formState.errors[name], [formState, name])

  return (
    <React.Fragment>
      <FormatLabel label={label} name={name} hasValue={!!watchValue} required={required}>
        <div className="d-flex flex-column">
          <textarea className="form-control" rows={10} value={watchValue} onChange={handleChange} />
          <div className="d-flex my-1">
            <span className="flex-fill" />
            {/* <Button text="prettify" disabled={hasError} onClick={handlePrettify} /> */}
            {/* <Button text="minify" disabled={hasError} onClick={handleMinify} /> */}
          </div>
        </div>
      </FormatLabel>
    </React.Fragment>
  )
}

// const Button = ({ text, disabled, onClick }) => {
//   const handleClick = () => !disabled && onClick && onClick()

//   return (
//     <span className={`btn btn-secondary btn-sm mx-1 ${disabled && 'disabled'}`} onClick={handleClick}>
//       {text}
//     </span>
//   )
// }
