import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'

export const RedirectImpersonate = () => {
  const history = useHistory()
  const { refreshToken } = useParams()

  const authContext = useContext(AuthContext)

  useEffect(
    () => {
      const run = async () => {
        await authContext.actions.impersonateRefresh(refreshToken)
        history.replace('/')
      }

      run(refreshToken)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshToken]
  )

  return (
    <React.Fragment>
      <div>IMPERSONATE REDIRECT</div>
    </React.Fragment>
  )
}
