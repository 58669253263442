import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmit, FormHiddenNumber, FormatLabel, FormURL, FormHiddenText } from '../../../../../../shared/form/form'
import { useAppStoreForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { AppVersionSelect } from '../shared/app-version-select'
import { useSelectValue } from '../shared/hooks'
import { IconEdit, IconList } from '../../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../../role/role.const'
import { useRoleAccessSystem } from '../../../../../../role/role.hook'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const AppStoreEdit = () => {
  const { appID, appStoreID } = useParamsInt()

  const [appStoreFormActions, appStoreFormState] = useAppStoreForm()

  const handlePath = async () => await appStoreFormActions.get(appStoreID)

  const handleSubmit = async data => {
    await appStoreFormActions.put(data)
    await appStoreFormActions.get(appStoreID)
  }

  const [currentVersionID, updateCurrentVersionID] = useSelectValue(appStoreFormState.form.currentVersionID)

  const [downloadType, updateDownloadType] = useSelectValue(appStoreFormState.form.downloadType)

  const roleApp = useRoleAccessSystem(ROLE_SYS.APP)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem
        Icon={IconList}
        text="versions"
        to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version`}
        show={roleApp.accessCreate}
        pin
      />
      <MenuDelete to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/delete`} show={roleApp.accessDelete} />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-all`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={appStoreFormState.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={appStoreFormState.form.name} HeaderMenu={HeaderMenu}>
          <Form state={appStoreFormState.form} onSubmit={handleSubmit}>
            <FormHiddenText name="downloadType" value={downloadType} required shouldDirty />
            <FormHiddenNumber name="currentVersionID" value={currentVersionID} shouldDirty />

            <FormText label="key" name="key" readOnly />

            <FormText label="name" name="name" />

            <FormatLabel label="Version">
              <AppVersionSelect appStoreID={appStoreID} currentVersionID={currentVersionID} onSelect={updateCurrentVersionID} />
            </FormatLabel>

            <FormURL label="download proxy URL" name="appStoreProxyURL" readOnly />

            <FormatLabel label="Download Proxy Behavior">
              <SelectDownloadType value={downloadType} onChange={updateDownloadType} />
            </FormatLabel>

            <FormURL label="store url" name="storeURL" hidden={downloadType !== 'store'} />

            <FormSubmit text="save store" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const SelectDownloadType = ({ value, onChange }) => {
  const handleChange = e => {
    onChange && onChange(e.target.value)
  }

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value="">Select</option>
      <option value="edxr">Edxr Download Page</option>
      <option value="direct">Direct Download</option>
      <option value="store">Store URL</option>
    </select>
  )
}
