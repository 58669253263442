import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { useApiListRoleType } from '../../../../../api-new/state/content'

export const RoleListTypeSys = () => {
  const { role } = useParams()

  const apiListRoleType = useApiListRoleType()

  const title = useMemo(() => `Role ${role}`, [role])

  const handleReady = async () => await apiListRoleType.get(`sys/${role}/role`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={role}>
        <CardFullHeightScrollY title={title}>
          <TableBuilder apiList={apiListRoleType} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
