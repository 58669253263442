import React, { useEffect } from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnFormat } from '../../shared/table-builder/table-builder'
import { Menu, MenuPurge, MenuRestore } from '../../shared/menu/menu'
import { useApiListNode } from '../../api-new/state/content'
import { useRoleAccessOrganization } from '../../role/role.hook'
import { ROLE_ORG } from '../../role/role.const'
import { useRouteMatch } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export function NodeRecycle() {
  const { url } = useRouteMatch()

  const apiListNode = useApiListNode()

  const { accessDelete, accessPurge } = useRoleAccessOrganization(ROLE_ORG.CONTENT)

  const handleRestore = async id => {
    await apiListNode.put(`${id}/restore`)
    await apiListNode.get('deleted-content')
  }

  const handleMatch = async () => await apiListNode.get('deleted-content')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin" onMatch={handleMatch}>
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListNode}>
            <Column field="name" sortable filter />
            <ColumnFormat field="path" selector={row => JSON.parse(row.ancestors).map(v => v.name).join('/')} sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show={accessDelete} />
                  <MenuPurge to={`${url}/${row.id}/purge`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
