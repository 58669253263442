const imageCache = {}

export const getImageCache = key => {
  if (imageCache[key]) return imageCache[key]
  else return null
}

export const setImageCache = (key, data) => {
  imageCache[key] = data
}

export const clearImageCache = key => {
  delete imageCache[key]
}

export const getBase64 = blob => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}
