import { useApiFormNodeCategory, useApiListAsset } from '../../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { useDisplaySysAdmin } from '../../../../../../../role/role.component'
import { ROLE_ORG } from '../../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../../role/role.hook'
import { Card } from '../../../../../../../shared/card/card'
import { CardFullHeightScrollY } from '../../../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormSubmit, FormText } from '../../../../../../../shared/form/form'
import { IconCourse, IconEdit, IconFile, IconGroup, IconMove, IconUsers } from '../../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../../shared/menu/menu'
import { ThumbnailImageEdit } from '../../../../../../../view/node-new/shared/thumbnail-image-edit'
import { useDataContext } from '../../../data-context'
import { useGetByDataType } from '../../hooks/hooks'

export const NodeCategoryEdit = ({ parentURL, node }) => {
  const apiFormNodeCategory = useApiFormNodeCategory()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)
  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)

  const handlePath = async () => {
    await apiFormNodeCategory.get(node.id)
    apiListAsset.get(`${node.id}/node`)
  }

  const thumbnail = useGetByDataType('thumbnail', apiListAsset.items)

  const handleImageChange = (key, image) => dataContext.thumbnailCache.add(key, image)

  const displaySysAdmin = useDisplaySysAdmin()

  const HeaderMenu = () =>
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuEdit text="sys edit" to={`${parentURL}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuItem Icon={IconCourse} text="children" to={`${parentURL}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconUsers} text="user permissions" to={`${parentURL}/content-permission/${node.id}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${parentURL}/group-permission/${node.id}`} show={roleNodeGroup.accessRead} />
      <MenuItem Icon={IconMove} text="move" to={`${parentURL}/move`} show={roleContent.accessUpdate} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${parentURL}/activity`} show={displaySysAdmin} />
    </Menu>

  const handleSubmit = async data => {
    let node = await apiFormNodeCategory.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  return (
    <BreadcrumbView Icon={IconEdit} text="edit" onPath={handlePath}>
      <CardFullHeightScrollY title={apiFormNodeCategory.form.name} HeaderMenu={HeaderMenu}>
        <Card>
          <Form state={apiFormNodeCategory.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" />
            <FormSubmit text="update" />
          </Form>
        </Card>

        <ThumbnailImageEdit nodeID={node.id} asset={thumbnail} name="thumbnail image" onChange={handleImageChange} />
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
