import React from 'react'
import { Link } from 'react-router-dom'
import { IconDelete, IconEdit, IconEnter, IconUsers, IconGroup } from './icon'

export const IconButtonLink = ({ children, text, small, to }) => {
  return (
    <Link className={`btn btn-primary mx-1 ${small && 'btn-sm'}`} to={to || ''}>
      <div className="d-flex text-nowrap text-capitalize">
        <span>
          {children}
        </span>
        <span>
          {text}
        </span>
      </div>
    </Link>
  )
}

export const IconButtonLinkDelete = ({ className, text, disabled, small, size, to }) => {
  return (
    <IconButtonLink text={text || 'Delete'} to={!disabled && to} small={small}>
      <IconDelete className={className} size={size} />
    </IconButtonLink>
  )
}

export const IconButtonLinkGroups = ({ className, text, disabled, small, size, to }) => {
  return (
    <IconButtonLink text={text || 'Groups'} to={!disabled && to} small={small}>
      <IconGroup className={className} size={size} />
    </IconButtonLink>
  )
}

export const IconButtonLinkUsers = ({ className, text, disabled, small, size, to }) => {
  return (
    <IconButtonLink text={text || 'Users'} to={!disabled && to} small={small}>
      <IconUsers className={className} size={size} />
    </IconButtonLink>
  )
}

export const IconButtonLinkEnter = ({ className, text, disabled, small, size, to }) => {
  return (
    <IconButtonLink text={text || 'Enter'} to={!disabled && to} small={small}>
      <IconEnter className={className} size={size} />
    </IconButtonLink>
  )
}

export const IconButtonLinkEdit = ({ className, text, disabled, small, size, to }) => {
  return (
    <IconButtonLink text={text || 'Edit'} to={!disabled && to} small={small}>
      <IconEdit className={className} size={size} />
    </IconButtonLink>
  )
}
