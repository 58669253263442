import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { ROLE_SYS } from '../../../../../role/role.const'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useRoleAccessSystem } from '../../../../../role/role.hook'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListProvider } from '../../../../../api-new/state/auth'

export function ProviderRecycle() {
  const apiListProvider = useApiListProvider()

  const roleProvider = useRoleAccessSystem(ROLE_SYS.PROVIDER)

  const handleReady = async () => await apiListProvider.get('deleted')

  const handleRestore = async item => {
    await apiListProvider.put(`${item.id}/restore`)
    await apiListProvider.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListProvider} onReady={handleReady}>
            <Column field="providerName" sortable filter />
            <Column field="displayName" sortable filter />
            <Column field="url" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleProvider.accessDelete} />
                  <MenuPurge to={`/admin/provider/list/recycle/purge/${row.id}`} show={roleProvider.accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
