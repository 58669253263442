import React, { useCallback } from 'react'
import { SearchList } from './search-list'
import { IconGroup } from '../../icon/icon'

const DisplayItemSelected = ({ selectText, item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconGroup />
          {item.name}
        </span>}

      {!item &&
        <span className="text-capitalize">
          {selectText || 'select'}
        </span>}
    </React.Fragment>
  )
}

const DisplayItemList = ({ item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconGroup />
          {item.name}
        </span>}
    </React.Fragment>
  )
}

const handleFilter = (items, value) => {
  if (!value) {
    return items
  }

  value = value.toLowerCase().trim().split(' ')

  return items.filter(item => value.some(i => item.name.toLowerCase().includes(i)))
}

export const SearchListGroup = ({ dropLeft, selectText, actionText, items, selectedItem, disabled, onAdd, onSelect }) => {
  const handleSelectedItem = useCallback(
    item => {
      if (item && selectedItem) {
        return item.id === selectedItem.id
      }

      return false
    },
    [selectedItem]
  )

  return (
    <React.Fragment>
      <SearchList
        dropLeft={dropLeft}
        items={items}
        selectedItem={selectedItem}
        DisplayItemSelected={DisplayItemSelected}
        DisplayItemList={DisplayItemList}
        selectText={selectText}
        actionText={actionText}
        fnFilter={handleFilter}
        fnSelectedItem={handleSelectedItem}
        onAdd={onAdd}
        onSelect={onSelect}
        disabled={disabled}
      />
    </React.Fragment>
  )
}
