import React from 'react'
import { LayoutRole } from './role/layout-role'
import { LayoutRoleAccess } from './role-access/layout-role-access'
import { LayoutSysAdmin } from './sys-admin/layout-sys-admin'
import { DisplaySysRoot } from '../../../role/role.component'
import { LayoutAnalytic } from './analytic/layout-analytic'

export const LayoutRoot = () => {
  return (
    <DisplaySysRoot>
      <LayoutRole />
      <LayoutRoleAccess />
      <LayoutSysAdmin />
      <LayoutAnalytic />
    </DisplaySysRoot>
  )
}
