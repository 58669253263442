import React from 'react'
import { Route } from 'react-router-dom'
import { Delete } from '../../../../shared/page/page-delete'
import { RoleAccessAdd } from './content/role-access-add'
import { RoleAccessEdit } from './content/role-access-edit'
import { RoleAccessList } from './content/role-access-list'
import { RoleAccessListIdentifier } from './content/role-access-list-identifier'
import { RoleAccessListRole } from './content/role-access-list-role'
import { useApiFormAccess } from '../../../../api-new/state/content'

export const LayoutRoleAccess = () => {
  return (
    <React.Fragment>
      <Route path="/root/role-access/list" component={RoleAccessList} />
      <Route path="/root/role-access/list/add" component={RoleAccessAdd} />
      <Route path="/root/role-access/list/edit/:roleAccessID" component={RoleAccessEdit} />

      <Route path="/root/role-access/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormAccess} titleParam="role" successPath="/root/role-access/list" />
      </Route>

      <Route path="/root/role-access/list/role/:role" component={RoleAccessListRole} />
      <Route path="/root/role-access/list/identifier/:identifier" component={RoleAccessListIdentifier} />
    </React.Fragment>
  )
}
