import React from 'react'

import { DashboardUser } from './component/dashboard-user'
import { DashboardCourse } from './component/dashboard-course'
import { DashboardAnalyticPlatform } from './component/dashboard-analytic-platform'
import { DashboardAnalyticSession } from './component/dashboard-analytic-session'
import { Grid, GridItem3 } from '../../../../../shared/chart/grid'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDashboard } from '../../../../../shared/icon/icon'
import { RoleAccessDisplayOrg } from '../../../../../role/components/role-access-display'
import { ROLE_ORG } from '../../../../../role/role.const'

export const Organization = () => {
  return (
    <BreadcrumbView Icon={IconDashboard} text="DASHBOARD">
      <React.Fragment>
        <Grid>
          <RoleAccessDisplayOrg type={ROLE_ORG.USER} accessRead>
            <GridItem3>
              <DashboardUser />
            </GridItem3>
          </RoleAccessDisplayOrg>

          <RoleAccessDisplayOrg type={ROLE_ORG.CONTENT} accessRead>
            <GridItem3>
              <DashboardCourse />
            </GridItem3>
          </RoleAccessDisplayOrg>

          <RoleAccessDisplayOrg type={ROLE_ORG.ASSESSMENT_REPORT} accessRead>
            <GridItem3>
              <DashboardAnalyticPlatform />
            </GridItem3>
            <GridItem3>
              <DashboardAnalyticSession />
            </GridItem3>
          </RoleAccessDisplayOrg>
        </Grid>
      </React.Fragment>
    </BreadcrumbView>
  )
}
