import React from 'react'
import { useApiFormNodeCategory, useApiListAsset } from '../../../../../../../api-new/state/content'
import { useDataContext } from '../../../data-context'
import { useLoad } from '../../../../../../../shared/hooks/load'
import { useRoleAccessOrganization } from '../../../../../../../role/role.hook'
import { ROLE_ORG } from '../../../../../../../role/role.const'
import { useDisplaySysAdmin } from '../../../../../../../role/role.component'
import { Menu, MenuActivityLog, MenuDelete, MenuEdit, MenuItem } from '../../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../../shared/form/form'
import { IconCourse, IconEdit, IconFile, IconGroup, IconUsers } from '../../../../../../../shared/icon/icon'
import { FormRawJson } from '../../../../../../../shared/form/form-control/form-raw-json'

export const NodeCategoryEditSys = ({ parentURL, node }) => {
  const apiFormNodeCategory = useApiFormNodeCategory()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormNodeCategory.get(node.id)
      apiListAsset.get(`${node.id}/node`)
    },
    [node.id]
  )

  const handleSubmit = async data => {
    let node = await apiFormNodeCategory.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const roleNodeUser = useRoleAccessOrganization(ROLE_ORG.NODE_USER)
  const roleNodeGroup = useRoleAccessOrganization(ROLE_ORG.NODE_GROUP)

  const displaySysAdmin = useDisplaySysAdmin()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconFile} text="assets" to={`${parentURL}/assets`} show={roleNodeUser.accessRead} pin />
      <MenuEdit text="edit" to={`${parentURL}/edit`} show={roleNodeUser.accessUpdate} pin={false} />
      <MenuItem Icon={IconCourse} text="children" to={`${parentURL}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconUsers} text="user permissions" to={`${parentURL}/content-permission/${node.id}`} show={roleNodeUser.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${parentURL}/group-permission/${node.id}`} show={roleNodeGroup.accessRead} />
      <MenuDelete to={`${parentURL}/delete`} show={roleContent.accessDelete} />
      <MenuActivityLog to={`${parentURL}/activity`} show={displaySysAdmin} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView text="sys edit" Icon={IconEdit}>
        <CardFullHeightScrollY title="sys edit" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormNodeCategory.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormRawJson label="metadata" name="metaData" />
              <FormSubmit text="update" />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
