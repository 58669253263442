import React from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnMenu, TableBuilder } from '../../../../shared/table-builder/table-builder'
import { Menu, MenuPurge, MenuRestore } from '../../../../shared/menu/menu'
import { IconList } from '../../../../shared/icon/icon'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useApiListUserAssessment } from '../../../../api-new/state/assessment'
import { useRoleAccessSystem } from '../../../../role/role.hook'
import { ROLE_SYS } from '../../../../role/role.const'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export function AssessmentUserRecycle() {
  const { url } = useRouteMatch()
  const { userID } = useParamsInt()

  const apiListUserAssessment = useApiListUserAssessment()

  const { accessDelete, accessPurge } = useRoleAccessSystem(ROLE_SYS.LOGIN)

  const handleReady = async () => await apiListUserAssessment.get(`deleted/${userID}/user`)

  const handleRestore = async item => {
    await apiListUserAssessment.put(`${item.id}/restore`)
    await apiListUserAssessment.get(`deleted/${userID}/user`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListUserAssessment} onReady={handleReady}>
            <Column field="assessment" sortable filter />
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <ColumnDateTime field="start" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={accessDelete} />
                  <MenuPurge to={`${url}/purge/${row.id}`} show={accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
